import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
import { withState } from "./with-state";
import GridLoading from "../../Loaders/GridLoading";
import { parseDate } from '@telerik/kendo-intl';
import {filterBy,orderBy} from '@progress/kendo-data-query';
import SecurityClassificationSplitPopup from './SecurityClassificationSplitPopup';
const StatefulGrid = withState(Grid);

const cloneProduct = (product) => ({ ...product });

class SecurityClassificationSplit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            securityClassificationSplits: [],
            gridFilter: undefined,
            filterable: props.styleSettings.showFilters,
            sort: [{ field: 'SortOrder', dir: 'asc' }],
            fileDownload: []
        }
    }

    componentDidMount = () => {
        this.getSecurityClassificationSplits();
    }

    getSecurityClassificationSplits = () => {
        this.setState({ refreshText: 'Loading...', reportSelected: false, reportSelect: '', });
        var securityClassificationSplits = [];

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/getSecurityClassificationSplits`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {

                response.data.data.forEach(securityClassificationSplit => {

                    if (securityClassificationSplit.AsOfDate) {
                        securityClassificationSplit.AsOfDate = this.getDate(securityClassificationSplit.AsOfDate, true);
                    }

                    securityClassificationSplits.push(securityClassificationSplit)

                })
                this.setState({ data: securityClassificationSplits.map(cloneProduct), securityClassificationSplits: securityClassificationSplits, refreshText: '' })

            })
            .catch(err => {
                console.error(err);
                this.setState({ refreshText: '' });
            })
    }

    selectionChange(event, gFiles) {
        gFiles.forEach(item => {
            if (item.id !== event.dataItem.id) {
                item.selected = false
            }
        });
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles }, () => { this.forceUpdate(); });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange = (event, gFiles) => {
        const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = checked);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    getDate = (sqlDate, isUTC) => {
        var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
        if (isUTC)
            return utc;
        else
            return d;
    }

    headerCellRender = (thElement, cellProps) => {
        if (thElement !== null) {
            if (cellProps.field === 'selected')
                return (
                    <div />
                );
            else {
                return thElement
            }
        }
        else {
            return thElement;
        }
    }

    refreshSecurityClassificationSplits = () => {
        this.setState({ fileDownload: [], fileSelected: false });
        this.getSecurityClassificationSplits();
    }

    onDelete = (e) => {

        var confirm = window.confirm("Are you sure you want to delete the security classification split?");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                securityClassificationSplits: this.state.fileDownload,
                company: this.props.user.company,
            };
            axios.post('api/deleteSecurityClassificationSplits', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: [], fileSelected: false });
                    this.getSecurityClassificationSplits();
                }
                else {
                    alert("Error: Could not be deleted.");
                    this.setState({ fileDownload: [], fileSelected: false });
                    this.getSecurityClassificationSplits();
                }
            }).catch(e => console.log(e));
        }
    }

    processData = () => {
        const { data, sort, gridFilter } = this.state;
        return filterBy(orderBy(data, sort), gridFilter);
    }

    render() {
        return (
            <div className='securityClassificationSplits'>

                <StatefulGrid data={this.processData(this.state.data)}
                    selectedField='selected'
                    onSelectionChange={(e) => this.selectionChange(e, this.state.securityClassificationSplits)}
                    onRowClick={(e) => this.selectionChange(e, this.state.securityClassificationSplits)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.securityClassificationSplits)}
                    headerCellRender={this.headerCellRender}
                    filterable={this.state.filterable}
                    filter={this.state.gridFilter}
                    onFilterChange={(e)=>{this.setState({gridFilter:e.filter})}}
                    resizable
                >
                    <GridToolbar>
                        <Button icon='refresh' title='Refresh' onClick={this.refreshSecurityClassificationSplits}>{this.state.refreshText}</Button>
                        <Button icon='filter' title='Filter' onClick={(e) => this.setState({ filterable: !this.state.filterable })}>Filter</Button>
                        <SecurityClassificationSplitPopup idToken={this.props.idToken} uploadMethod={this.refreshSecurityClassificationSplits} files={this.state.securityClassificationSplits} company={this.props.user.company} user={this.props.user} uploadUser={this.props.uploadUser} type='reportStaticContent' button={<Button className="docButton" icon="upload">New</Button>} />
                        {this.props.uploadUser.role === 'admin' && (<SecurityClassificationSplitPopup idToken={this.props.idToken} securityClassificationSplitEdit={this.state.fileDownload[0]} uploadMethod={this.refreshSecurityClassificationSplits} files={this.state.securityClassificationSplits} company={this.props.user.company} user={this.props.user} uploadUser={this.props.uploadUser} button={<Button className="docButton" disabled={this.state.fileDownload.length !== 1} icon='edit'>Edit</Button>} />)}
                        {this.props.uploadUser.role === 'admin' && (<Button className="docButton" disabled={!this.state.fileSelected} onClick={this.onDelete} icon='delete'>Delete Security Classification Split</Button>)}
                    </GridToolbar>
                    <GridColumn
                        field="SecurityID"
                        title="Security ID"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.SecurityID}
                            </td>
                        }
                    />
                    <GridColumn
                        width='150px'
                        field="AssetClass"
                        title="Asset Class"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.AssetClass}
                            </td>
                        }
                    />
                    <GridColumn
                        width='200px'
                        field="Sector"
                        title="Sector"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.Sector}
                            </td>
                        }
                    />
                    <GridColumn
                        width='200px'
                        field="IndustryGroup"
                        title="Industry Group"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.IndustryGroup}
                            </td>
                        }
                    />
                    <GridColumn
                        width='150px'
                        field="NewSecurityID"
                        title="New Security ID"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.NewSecurityID}
                            </td>
                        }
                    />
                    <GridColumn
                        width='120px'
                        field="Percentage"
                        title="Percentage"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.Percentage}%
                            </td>
                        }
                    />
                    <GridColumn
                        width='120px'
                        field="AsOfDate"
                        title="As of Date"
                        headerClassName='gridHeader'
                        filter="date"
                        format="{0:d}"
                    />
                </StatefulGrid>
                {this.state.refreshText && <GridLoading gridClass='webRep' />}
            </div>
        );
    }

}

export default SecurityClassificationSplit;