import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import StagedDocuments from './components/StagedDocuments';
import StagedActionRequestsStatus from './components/StagedActionRequestsStatus';
import BulkUploadForm from './components/BulkUploadForm';

class BulkDocumentManagement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tabSelected:0
    }
  }

  handleSelect = (e) => {
    this.setState({ tabSelected: e.selected });
  }

  render() {
    return(
        <div className="bulkDocManagement">
            <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
                <TabStripTab title='Upload Bulk ZIP File'>
                  <BulkUploadForm idToken={this.props.idToken} company={this.props.user.company} user={this.props.user.user} uploadUser={this.props.user} uploadMethod={this.onUploadHouseholdDoc} files={this.state.householdFiles} />
                </TabStripTab>

                <TabStripTab title='Staged Documents'>
                    <StagedDocuments user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title='Staged Document Requests'>
                    <StagedActionRequestsStatus user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
            </TabStrip>
        </div>
    );
  }

}

export default BulkDocumentManagement;