import React, { Component } from 'react';
import UploadForm from './UploadForm';
import { Button } from '@progress/kendo-react-buttons';
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import {filterBy,orderBy} from '@progress/kendo-data-query';
import {parseDate} from '@telerik/kendo-intl';
import axios from 'axios';
import { withState } from './with-state';
// import createFilterDropDownCell from './FilterDropDownCell';
import createFilterDropDownCellPrimitive from './FilterDropDownCellPrimitive';
import GridLoading from '../../Loaders/GridLoading';
import EditWebRepProfile from './EditWebRepProfile'

const StatefulGrid = withState(Grid);

class WebReports extends Component {

    constructor(props) {
      super(props);
      this.state = {
        reports:[],
        categories:[],
        categoriesString:[],
        platforms:[],
        platformsString:[],
        reportsLoading:true,
        fileDownload:'',
        fileSelected:false,
        gridFilter:undefined,
        filterable:false,
        sort:[],
        refreshText:''
      }

      this.download = this.download.bind(this);
      this.onDownloadClick = this.onDownloadClick.bind(this);
      this.refreshReports = this.refreshReports.bind(this);
      this.getReports = this.getReports.bind(this);
    }

    componentDidMount() {
      this.getReports();
    }

    getReports(){
      this.setState({refreshText:'Loading...'});
      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      axios.get("api/webrep?company="+this.props.company, {headers}).then(response=>{      
        this.setState({categories:[]});
        var cats = [];
        var catsString = [];
        var newCat;
        var indexOfCat;
        var plats = [];
        var platsString = [];
        var newPlat;
        var indexOfPlat;

        for(let i=0; i<response.data.length; i++){
          response.data[i].expiration = this.getDate(response.data[i].expiration, true);
          response.data[i].uploadDate = this.getDate(response.data[i].uploadDate);
          if(response.data[i].category!==undefined && response.data[i].category!==''){

            newCat = {text:response.data[i].category, path:response.data[i].category};
            newPlat = {text:response.data[i].platform}

            // eslint-disable-next-line
            indexOfCat = cats.findIndex((cat)=>{
                return cat.text===newCat.text;
            });

            // eslint-disable-next-line
            indexOfPlat = plats.findIndex((plat)=>{
              return plat.text===newPlat.text;
            });

            if(indexOfCat===-1){
              cats.push(newCat);
              catsString.push(newCat.text)
            }
            if(indexOfPlat===-1){
              plats.push(newPlat);
              platsString.push(newPlat.text)
            }
          }
        }
        this.setState({categories:cats, categoriesString: catsString});
        this.setState({platforms: plats, platformsString: platsString});
        this.setState({reports:response.data});
        this.setState({refreshText:''});
      }).catch(err=>console.log(err));
    }

    getDate(sqlDate, isUTC){
      var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
      var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
      if(isUTC)
        return utc;
      else
        return d;
    }

    onLinkClick = (event, rep, idToken) => {      
      const headers = { 'authorization': 'Bearer '.concat(idToken) };
      axios
          .get("api/download?company="+this.props.company+"&path="+rep.samplePath+"&name="+rep.sampleName, {headers})
          .then((response) => {
              console.log(response);
              window.open(response.data, '_blank');
          })
          .catch((e) => {
              console.error("ERROR");
              return false;
          });
      
      console.log(idToken);
      return false;
    }

    selectionChange(event, gFiles){
      event.dataItem.selected = !event.dataItem.selected;
      var selectedFiles = [];
      for(let i=0; i<gFiles.length; i++){
          if(gFiles[i].selected){
              selectedFiles.push(gFiles[i]);
          }
      }
      this.setState({fileDownload:selectedFiles});
      if(selectedFiles.length>0){
          this.setState({fileSelected: true});
      }
      else{
          this.setState({fileSelected: false});
      }
      this.forceUpdate();
    }

    headerSelectionChange(event, gFiles){
      const checked = event.syntheticEvent.target.checked;
      gFiles.forEach(item => item.selected = checked);
      var selectedFiles = [];
      for(let i=0; i<gFiles.length; i++){
          if(gFiles[i].selected){
              selectedFiles.push(gFiles[i]);
          }
      }
      this.setState({fileDownload:selectedFiles});
      if(selectedFiles.length>0){
          this.setState({fileSelected: true});
      }
      else{
          this.setState({fileSelected: false});
      }
      this.forceUpdate();
    }

    getDownButtonText(files){
      if(!files || files.length===0){
        return 'Select Report(s) to Download'
      }
      else if(Array.isArray(files) && files.length>1){
          return 'Download '+files.length+' reports';
      }
      else if(Array.isArray(files) && files.length===1){
          return 'Download '+files[0].title;
      }
      else{
          return 'Download '+files.title;
      }
    }

    download() {  
      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      var payload = {"files":[], "company":this.props.company};
      if(Array.isArray(this.state.fileDownload) && this.state.fileDownload.length>1){
          for(let i=0; i<this.state.fileDownload.length; i++){
              payload.files.push({
                  "path": this.state.fileDownload[i].reportPath,
                  "name":this.state.fileDownload[i].reportName
              });
          }
              axios
                  .post("api/downloadzip", payload, {headers})
                  .then((response) => {
                      console.log(response);
                      window.open("api/downloadzip?user="+this.props.uploadUser.user);
                  })
                  .catch((e) => {
                      console.error("ERROR");
                  });
      }
      else if(Array.isArray(this.state.fileDownload)){
          axios
              .get("api/download?company="+this.props.company+"&path="+this.state.fileDownload[0].reportPath+"&name="+this.state.fileDownload[0].reportName+"&type=download", {headers})
              .then((response) => {
                  window.open(response.data);
              })
              .catch((e) => {
                  console.error("ERROR");
                  return false;
              });
      }
      else{
        axios
              .get("api/download?company="+this.props.company+"&path="+this.state.fileDownload.reportPath+"&name="+this.state.fileDownload.reportName+"&type=download", {headers})
              .then((response) => {
                  window.open(response.data);
              })
              .catch((e) => {
                  console.error("ERROR");
                  return false;
              });
      }

    }

    onDelete = (e)=>{
      var files = [];
      var rep;
      var addFile = {};
      for(let i=0; i<this.state.fileDownload.length; i++){
        rep = this.state.fileDownload[i];
        addFile.path = rep.reportPath;
        addFile.text = rep.reportName;
        files.push(addFile);
        addFile = {};

        if(rep.samplePath!==""){
          addFile.path = rep.samplePath;
          addFile.text = rep.sampleName;
          files.push(addFile);
          addFile = {};
        }
      }

      var confirm = window.confirm("Are you sure you want to delete these file(s)?");

      if(confirm){
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
          files:files,
          company:this.props.company,
          isWebRep:true
        };
        axios.post('api/deleteFile', payload, {headers}).then(response=>{
          if(response.data.code===200){
            this.setState({fileDownload:''});
            this.setState({fileSelected:false});
            this.refreshReports();
          }
          else{
            alert("Error: Report(s) could not be deleted.");
            this.setState({fileDownload:''});
            this.setState({fileSelected:false});
            this.refreshReports();
          }
        }).catch(e=>console.log(e));
      }
    }

    onDownloadClick = (e)=>{
      this.download();
    }

    onFilterChange = (e)=>{
      this.setState({gridFilter:e.filter});
    }

    refreshReports(){
      this.setState({fileDownload:''});
      this.setState({fileSelected:false});
      this.getReports();
    }

    processData = () => {
      const { reports, sort, gridFilter } = this.state;
      return filterBy(orderBy(reports, sort), gridFilter);
    }

    render() {
      return (
        <>
        {this.props.uploadUser.approved!==false && 
          <div className="webrep">
              {<EditWebRepProfile idToken={this.props.idToken} button={<Button className='right'>Edit Profile</Button>} />}
              <a href='/axys_download.html' target="_blank" rel="noopener noreferrer">Axys Download Instructions</a>
              <br/>
              <a href='/apx_download.html' target="_blank" rel="noopener noreferrer">APX Download Instructions</a>
              <br/>
              <br/>   
              {this.props.uploadUser.role==='admin' && (<UploadForm idToken={this.props.idToken} folders={this.state.categories} platforms={this.state.platforms} uploadMethod={this.refreshReports} files={this.state.reports} company={this.props.company} user={this.props.user} uploadUser={this.props.uploadUser} type='webrep' button={<Button className="docButton" icon="upload">Upload Web Report</Button>} />)}
              <Button className="docButton" disabled={!this.state.fileSelected} icon="download" onClick={this.onDownloadClick}>{this.getDownButtonText(this.state.fileDownload)}</Button>                                  
              {this.props.uploadUser.role==='admin' && (<UploadForm idToken={this.props.idToken} repEdit={this.state.fileDownload[0]} folders={this.state.categories} platforms={this.state.platforms} uploadMethod={this.refreshReports} files={this.state.reports} company={this.props.company} user={this.props.user} uploadUser={this.props.uploadUser} type='webrep' button={<Button className="docButton" disabled={this.state.fileDownload.length!==1} icon='edit'>Edit</Button>} />)}           
              {this.props.uploadUser.role==='admin' && (<Button className="docButton" disabled={!this.state.fileSelected} onClick={this.onDelete} icon='delete'>Delete</Button>)}                                             
              <br/>
              <StatefulGrid data={this.processData(this.state.reports)}
                    selectedField='selected'              
                    onSelectionChange={(e) => this.selectionChange(e, this.state.reports)}
                    onRowClick={(e) => this.selectionChange(e, this.state.reports)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.reports)}
                    filterable={this.state.filterable}    
                    filter={this.state.gridFilter}
                    onFilterChange={(e)=>{this.setState({gridFilter:e.filter})}}     
                    resizable
                    >
                <GridToolbar>
                  <Button icon='refresh' title='Refresh' onClick={this.refreshReports}>{this.state.refreshText}</Button>
                  <Button icon='filter' title='Filter' onClick={(e)=>this.setState({filterable:!this.state.filterable})}>Filter</Button>
                </GridToolbar>
                <GridColumn width='50px' field="selected" headerClassName='gridHeader' headerSelectionValue={this.state.reports.findIndex(dataItem => dataItem.selected === false) === -1} />
                <GridColumn 
                  width='280px' 
                  field="title" 
                  title="Report"
                  headerClassName='gridHeader' 
                  cell={(props)=>
                        <td>
                            {props.dataItem.samplePath && <button className='link-button' style={{color:'#656565'}} onClick={(event) => this.onLinkClick(event, props.dataItem, this.props.idToken)}>{props.dataItem.title}</button>}
                            {!props.dataItem.samplePath && props.dataItem.title}
                        </td>
                    } />
                <GridColumn width='165px' field="platform" headerClassName='gridHeader' title="Platform" filterCell={createFilterDropDownCellPrimitive(this.state.platformsString, '')}/>
                <GridColumn width='194px' field="category" headerClassName='gridHeader' title="Category" filterCell={createFilterDropDownCellPrimitive(this.state.categoriesString, '')}/>
                <GridColumn field="description" headerClassName='gridHeader' title="Description"/>
                <GridColumn width='200px' field="reportName" headerClassName='gridHeader' title="File Name"/>
                <GridColumn width='208px' field="expiration" headerClassName='gridHeader' filter="date" format="{0:d}" title="Expiration"/>
                <GridColumn width='208px' field="uploadDate" headerClassName='gridHeader' filter="date" format="{0:g}" title="Last Updated:"/>
              </StatefulGrid>
              {this.state.refreshText && <GridLoading gridClass='webRep'/>}
          </div>
        }
        {this.props.uploadUser.approved!==false && this.props.uploadUser.role!=='admin' && 
          <div className='webRep'>
            <h3>Your account is currently being reviewed by our team. You will have access to this page once your account has been approved.</h3>
          </div>
        }
        </>
      );
    }
  }
  
  export default WebReports;