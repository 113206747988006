import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { Button } from "@progress/kendo-react-buttons";
import axios from 'axios';
import { withState } from "./with-state";
import GridLoading from "../../Loaders/GridLoading";
import { parseDate } from '@telerik/kendo-intl';
import {filterBy,orderBy} from '@progress/kendo-data-query';
import ModelFeesPopup from './ModelFeesPopup';
const StatefulGrid = withState(Grid);

const cloneProduct = (product) => ({ ...product });

class ModelFees extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            modelFees: [],
            gridFilter: undefined,
            filterable: props.styleSettings.showFilters,
            sort: [{ field: 'SortOrder', dir: 'asc' }],
            fileDownload: []
        }
    }

    componentDidMount = () => {
        this.getModelFees();
    }

    getModelFees = () => {
        this.setState({ refreshText: 'Loading...', reportSelected: false, reportSelect: '', });
        var modelFees = [];

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/getModelFees`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {

                response.data.data.forEach(modelFee => {

                    if (modelFee.BeginDate) {
                        modelFee.BeginDate = this.getDate(modelFee.BeginDate, true);
                    }
                    if (modelFee.EndDate) {
                        modelFee.EndDate = this.getDate(modelFee.EndDate, true);
                    }

                    modelFees.push(modelFee)

                })
                this.setState({ data: modelFees.map(cloneProduct), modelFees: modelFees, refreshText: '' })

            })
            .catch(err => {
                console.error(err);
                this.setState({ refreshText: '' });
            })
    }

    selectionChange(event, gFiles) {
        gFiles.forEach(item => {
            if (item.id !== event.dataItem.id) {
                item.selected = false
            }
        });
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles }, () => { this.forceUpdate(); });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange = (event, gFiles) => {
        const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = checked);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    getDate = (sqlDate, isUTC) => {
        var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
        if (isUTC)
            return utc;
        else
            return d;
    }

    headerCellRender = (thElement, cellProps) => {
        if (thElement !== null) {
            if (cellProps.field === 'selected')
                return (
                    <div />
                );
            else {
                return thElement
            }
        }
        else {
            return thElement;
        }
    }

    refreshModelFees = () => {
        this.setState({ fileDownload: [], fileSelected: false });
        this.getModelFees();
    }

    onDelete = (e) => {
        
        var confirm = window.confirm("Are you sure you want to delete the model fee?");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                modelFees: this.state.fileDownload,
                company: this.props.user.company,
            };
            axios.post('api/deleteModelFees', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: [], fileSelected: false });
                    this.getModelFees();
                }
                else {
                    alert("Error: Could not be deleted.");
                    this.setState({ fileDownload: [], fileSelected: false });
                    this.getModelFees();
                }
            }).catch(e => console.log(e));
        }
    }

    processData = () => {
        const { data, sort, gridFilter } = this.state;
        return filterBy(orderBy(data, sort), gridFilter);
    }

    render() {
        return (
            <div className='modelFees'>

                <StatefulGrid data={this.processData(this.state.data)}
                    selectedField='selected'
                    onSelectionChange={(e) => this.selectionChange(e, this.state.modelFees)}
                    onRowClick={(e) => this.selectionChange(e, this.state.modelFees)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.modelFees)}
                    headerCellRender={this.headerCellRender}
                    filterable={this.state.filterable}
                    filter={this.state.gridFilter}
                    onFilterChange={(e)=>{this.setState({gridFilter:e.filter})}}
                    resizable
                >
                    <GridToolbar>
                        <Button icon='refresh' title='Refresh' onClick={this.refreshModelFees}>{this.state.refreshText}</Button>
                        <Button icon='filter' title='Filter' onClick={(e) => this.setState({ filterable: !this.state.filterable })}>Filter</Button>
                        <ModelFeesPopup idToken={this.props.idToken} uploadMethod={this.refreshModelFees} files={this.state.modelFees} company={this.props.user.company} user={this.props.user} uploadUser={this.props.uploadUser} type='reportStaticContent' button={<Button className="docButton" icon="upload">New</Button>} />
                        {this.props.uploadUser.role === 'admin' && (<ModelFeesPopup idToken={this.props.idToken} modelFeeEdit={this.state.fileDownload[0]} uploadMethod={this.refreshModelFees} files={this.state.modelFees} company={this.props.user.company} user={this.props.user} uploadUser={this.props.uploadUser} button={<Button className="docButton" disabled={this.state.fileDownload.length !== 1} icon='edit'>Edit</Button>} />)}
                        {this.props.uploadUser.role === 'admin' && (<Button className="docButton" disabled={!this.state.fileSelected} onClick={this.onDelete} icon='delete'>Delete Model Fee</Button>)}
                    </GridToolbar>
                    <GridColumn
                        width='150px'
                        field="ModelCode"
                        title="Model Code"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.ModelCode}
                            </td>
                        }
                    />
                    <GridColumn
                        field="ModelName"
                        title="Model Name"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.ModelName}
                            </td>
                        }
                    />
                    <GridColumn
                        width='150px'
                        field="BeginDate"
                        title="Begin Date"
                        headerClassName='gridHeader'
                        filter="date"
                        format="{0:d}"
                    />
                    <GridColumn
                        width='150px'
                        field="EndDate"
                        title="End Date"
                        headerClassName='gridHeader'
                        filter="date"
                        format="{0:d}"
                    />
                    <GridColumn
                        width='150px'
                        field="Fee"
                        title="Fee"
                        headerClassName='gridHeader'
                        cell={(props) =>
                            <td>
                                {props.dataItem.Fee}%
                            </td>
                        }
                    />
                </StatefulGrid>
                {this.state.refreshText && <GridLoading gridClass='webRep' />}
            </div>
        );
    }

}

export default ModelFees;