
import React from 'react';
import { Grid, GridColumn, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { /*filterBy, orderBy,*/ process } from '@progress/kendo-data-query';
// import { ComboBox } from '@progress/kendo-react-dropdowns';
import { Button } from "@progress/kendo-react-buttons";
// import { Switch } from '@progress/kendo-react-inputs';
import axios from 'axios';
import createFilterDropDownCell from './components/FilterDropDownCell';
import GridLoading from '../Loaders/GridLoading';


const cloneProduct = (product) => ({ ...product });

class ReportList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //data: sampleProducts.map(cloneProduct),
            data: [],
            editField: undefined,
            reportSelected: false,
            reportSelect: '',
            changes: false,
            reportsLoading: true,
            globalFeeMethod: {value: "0", label: "Gross"},
            globalDisclaimer: "No Disclaimer",
            globalLayout: {value: "l", label: "Landscape"},
            globalFeeMethodChecked: false,
            globalDisclaimerChecked: false,
            globalLayoutChecked: false,

            disclaimersList: [],
            assetClassList: [],

            gridFilter: undefined,
            filterable: false,
            reportStyles: ['Standard', 'Custom'],
            sort: [{ field: 'name', dir: 'asc' }],

            //
            runReportButtonFlag: false,

            layout: null,
            reports: [],
            report: { name: "Select Report", key: null, adjustableLayout: null },
            entities: [],
            loading: false,
            account: { account: "", displayName: "" },
            numPages: null,
            pageNumber: 1,
            pdfFile: null,
            pdfFlag: false,
            pdfScale: 1.25,
            status: "",
            loaderSpinnerFlag: false,
            checkPdfBlobTimerId: null,//used in componentWillUnmount cleanup
            generateExcelButtonFlag: true,
            generateCsvButtonFlag: true,
            reportParameters: [],
            reportParametersFlag: false,
            parameterList: [],
            defaultList: [],
            singleDateGenericDateToggle: false,
            fromDateGenericDateToggle: false,
            toDateGenericDateToggle: false,
            dataState:{skip:0, take:10000, sort:[{ field: 'type', dir: 'asc' }]},
        };

        //this.renderers = new Renderers(this.enterEdit, this.exitEdit, 'inEdit');
        this.getReportList = this.getReportList.bind(this);
        this.getParameterDefaults = this.getParameterDefaults.bind(this);
        this.headerCellRender = this.headerCellRender.bind(this);
        this.getDisclaimers = this.getDisclaimers.bind(this);
        this.createCell = this.createCell.bind(this);
        this.getAssetClassDefaults = this.getAssetClassDefaults.bind(this);
    }

    componentDidMount() {
        this.getReportList();
    }

    selectionChange(event, gFiles) {
        gFiles.forEach(item => item.selected = false);
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ reportSelect: selectedFiles[0] });
        if (selectedFiles.length > 0) {
            this.setState({ reportSelected: true });
        }
        else {
            this.setState({ reportSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange(event, gFiles) {
        // const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = false);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ reportSelect: selectedFiles[0], reportSelected: false });

        this.forceUpdate();
    }



    getDisclaimers() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var disclaimers = []

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetDisclaimers`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {

                response.data.data.forEach(disclaimerInResponse => {
                    disclaimers.push(disclaimerInResponse.DisclaimerCode)
                })
                console.log(disclaimers)
                this.setState({ disclaimersList: disclaimers })
            })
            .catch(err => {
                console.error(err);
            })
    }

    resetAllParameters() {
        let confirm = prompt("Type \"RESET ALL\" to confirm", "");

        if (confirm && confirm.toLowerCase() === "reset all") {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

            var payload = {
              company: this.props.user.company,
            }
        
        
            axios.post('api/ssrsDeleteParameterDefaults', payload, { headers }).then(response => {
                if (response.data.code === 200){
                    alert("Reset Successful")
                    this.getParameterDefaults();
                }
                else{
                    alert("Error deleting parameter defaults")
                }
            }).catch(err => {
              console.log(err);
              alert("Error deleting parameter defaults")
            });
        }
        else if (confirm === ""){
            alert("\"RESET ALL\" was not entered. Parameter defaults were not deleted")
        }
        else if (confirm === null){
            //nothing
        }
        else{
            alert("\"RESET ALL\" was not entered. Parameter defaults were not deleted")
        }

    }

    getParameterDefaults() {
      this.setState({loading: true})
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var parameterList = []
        //parameterList = parameterList.concat(this.state.parameterList)
        //var originalParameterList = JSON.parse(JSON.stringify(parameterList)) 
        axios(`api/ssrsGetParameterDefaults`, {
          method: 'GET',
          params: { company: this.props.user.company },
          headers: headers
        })
          .then(response => {
    
            if (response.data.code === 200) {
                parameterList = response.data.data
                this.setState({ parameterList: parameterList })
                this.getReportList(parameterList);

            }
            else {
              console.log("Error getting report defaults")
            }
          })
          .catch(error => {
            console.error(error);
          });
      }

      getGlobalParameterDefaults() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        // var parameterList = []
        //parameterList = parameterList.concat(this.state.parameterList)
        //var originalParameterList = JSON.parse(JSON.stringify(parameterList)) 
        axios(`api/ssrsGetGlobalParameterDefaults`, {
          method: 'GET',
          params: { company: this.props.user.company },
          headers: headers
        })
          .then(response => {
    
            if (response.data.code === 200) {
                response.data.data.forEach(globalDefault =>{
                    if (globalDefault.paramName && globalDefault.paramName.toLowerCase() === "feemethod"){
                        this.setState({
                            globalFeeMethod: (globalDefault.value === "0" ? {value: "0", label: "Gross"} : {value: "1", label: "Net"}), 
                            globalFeeMethodChecked: globalDefault.active
                        })
                    }
                    else if (globalDefault.paramName && globalDefault.paramName.toLowerCase() === "layout"){
                        this.setState({
                            globalLayout: (globalDefault.value === "l" ? {value: "l", label: "Landscape"} : {value: "p", label: "Portrait"}), 
                            globalLayoutChecked: globalDefault.active
                        })
                    }
                    else if (globalDefault.paramName && globalDefault.paramName.toLowerCase() === "disclaimer"){
                        this.setState({
                            globalDisclaimer: globalDefault.value, 
                            globalDisclaimerChecked: globalDefault.active
                        })
                    }
                })

            }
            else {
              console.log("Error getting report defaults")
            }
          })
          .catch(error => {
            console.error(error);
          });
      }



    getAssetClassDefaults() {
        // var assetClassDefaults = []
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsAssetClassDefaults`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                var assetClasses = []

                response.data.data.forEach(assetClassInResponse => {
                    //assetClasses.push(assetClassInResponse.AssetClassCode)
                    assetClasses.push(assetClassInResponse.label)
                })
                //this.setState({ assetClassList: assetClasses})
                this.setState({ assetClassList: assetClasses })
            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

    }

    getReportList(parameterList) {
        var reports = [];
        // var reportNames = [];
        // var splitName;
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        
        // //testing begin
        // this.setState({loading: true})
        // reports.push({name: "Bloomberg Positions", type: "Standard"})
        // var data = process(reports, this.state.dataState)
        // this.setState({ reports: reports, data: data, loading: false })
        // //testing end
    
        axios(`api/exportsList`, {
          method: 'GET',
          params: { company: this.props.user.company },
          headers: headers,
        })
          .then(response => {
            console.log(response)
            // var folder
            response.data.data.forEach(exportInResponse => {
              reports.push(exportInResponse)
            })
            var data = process(reports, this.state.dataState)
            this.setState({ reports: reports, data: data, loading: false })
    
          })
          .catch(err => {
            console.error(err);
          })
    
      }

      getDefaultParamString(report, parameterList){
        var s = ""
        parameterList.forEach(p => {
            if (p.reportName === report){
                if (s !== ""){ s += "; " }
                s += (p.label + ": " + (p.valueText || p.value))
            }
        })
        return s
      }

    saveChanges = () => {
        this.state.reports.splice(0, this.state.reports.length, ...this.state.data);
        this.setState({
            editField: undefined,
            changes: false
        });
    }

    openPopup = () => {
        this.state.reports.splice(0, this.state.reports.length, ...this.state.data);
        this.setState({
            editField: undefined,
            changes: false
        });
    }

    cancelChanges = () => {
        this.setState({
            data: this.state.reports.map(cloneProduct),
            changes: false
        });
    }

    itemChange = (event) => {
        event.dataItem[event.field] = event.value;
        this.setState({
            changes: true
        });
    }

    onFilterChange = (e) => {
        this.setState({ gridFilter: e.filter });
    }

    headerCellRender(thElement, cellProps) {
        if (thElement !== null) {
          if (cellProps.field === 'selected')
          return (
            <div  />
          );
          else{
              return thElement
          }
        }
        else {
          return thElement;
        }
      }

      createCell = (cell) => {
        return (
          <td>
            <Button onClick={() => this.props.onDataExportRun(cell.dataItem.ExportID)}>Run</Button>
          </td>
        );
      }

      handleGlobalChange(e, switchName){
        var name = e.target.name || switchName //can't get the "name" property to work for switch object. using this workaround
        var value = e.target.value
        var newValue = ""

        if (value.label){//if dropdown with label/value properties
            newValue = value.value
        }
        else{
            newValue = value
        }

        this.setState({
            ["global".concat(name)]: value
        })

        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        // var params = {
        //     company: this.props.user.company,
        //     parameters: paramsToSave,
        //     reportName: this.props.report.name
        // }

        var splitName = name.split('Checked')[0]
    
        var payload = {
          company: this.props.user.company,
          paramName: splitName
        };

        if (name.includes("Checked")){
            payload.active = newValue
            payload.value = (this.state["global".concat(splitName)].label ? this.state["global".concat(splitName)].value : this.state["global".concat(splitName)])
        }
        else{
            payload.active = this.state["global".concat(name).concat("Checked")] || false
            payload.value = newValue
        }

        axios.post('api/ssrsSaveGlobalParameterDefaults', payload, { headers }).then(response => {
          if (response.data.code === 200) {
            //nothing
          }
          else {
            alert("Unable to save global settings")
          }
        })
          .catch(err => {
            console.error(err);
            alert("Unable to save global settings")
          })


      }

      changeDataState = (e)=>{
        var newData = process(this.state.reports, e.data);
            this.setState({dataState:e.data, data:newData});

        
      }


    render() {
        // const feeMethodData = [{label: "Gross", value: "0"}, {label: "Net", value: "1"}]
        // const layoutData = [{label: "Landscape", value: "l"}, {label: "Portrait", value: "p"}]


        return (
            <div className="exportList">
                <Grid
                    //style={{ height: '420px' }}
                    selectedField='selected'
                    onSelectionChange={(e) => this.selectionChange(e, this.state.reports)}
                    onRowClick={(e) => this.selectionChange(e, this.state.reports)}
                    onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.reports)}
                    onDataStateChange={this.changeDataState}
                    data={this.state.data}
                    headerCellRender={this.headerCellRender}
                    rowHeight={50}
                    //onItemChange={this.itemChange}
                    filterable={true}
                    resizable
                    sortable={{
                        mode: 'multiple'
                    }}
                    //cellRender={this.renderers.cellRender}
                    //rowRender={this.renderers.rowRender}

                    editField="inEdit"
                    {...this.state.dataState}
                >
                  <GridNoRecords>
                                    {this.state.loading && ''}
                                    {!this.state.loading && 'No records available'}
                                </GridNoRecords>                    
                    <GridToolbar>
                    </GridToolbar>
                    {/* <GridColumn filterable={false} width='50px' field="selected" headerClassName='gridHeader' headerSelectionValue={false} /> */}
                    <GridColumn field="ExportType" title="Style" width="165px" filterable={true} filterCell={createFilterDropDownCell(this.state.reportStyles, '')} />{/*  */}
                    <GridColumn field="ExportName" title="Name" width="250px" filterable={true} />
                    <GridColumn field="ExportNotes" title="Description" filterable={true} />
                    <GridColumn headerClassName='gridHeader' field="clearDefaults" title=" " width="250px" filterCell={(props) => { return (<></>) }} cell={(props) => this.createCell(props)} />
                </Grid>
                {this.state.loading && <GridLoading gridClass='packageReview' />}
            </div>
        );
    }

}

//ReactDOM.render(
//    <App />,
//    document.querySelector('my-app')
//);

export default ReportList