import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import axios from 'axios';

class CreateTemplate extends Component {

  constructor(props) {
    super(props);
    this.state = {
        name:'',
        address:'',
        city:'',
        state:'',
        zip:'',
        phone:'',
        website:'',
        initialLoad:true
      }
    
    this.onInputChange = this.onInputChange.bind(this);
    this.uploadLocation = this.uploadLocation.bind(this);
    this.clearState = this.clearState.bind(this);
  }

  onInputChange(e, attr){
      this.setState({[attr]:e.target.value});
  }

  getCurrent(attr){
    if(this.props.locEdit && this.state.initialLoad){
      this.setState({[attr]: this.props.locEdit[attr], initialLoad:false});
      return this.props.locEdit[attr];
    }
    else{
      return '';
    }
  }


  clearState(){
    this.setState({
        name:'',
        address:'',
        city:'',
        state:'',
        zip:'',
        phone:'',
        email:'',
        website:'',
        initialLoad:true
    })
  }

  uploadLocation(callback){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload={
        name:this.state.name,
        company:this.props.user.company,
        address:this.state.address,
        city:this.state.city,
        state:this.state.state,
        zip:this.state.zip,
        phone:this.state.phone,
        web:this.state.website,
        email:this.state.email
    }

    //add id field if editing loaction
    if(this.props.locEdit){
      payload.id = this.props.locEdit.id;
    }

    if(this.state.name===undefined || this.state.name===null || this.state.name===''){
      alert("Please enter a name for this location.");
    }
    else if(this.state.state.length>2){
      alert("State cannot be greater than two characters. Please use the abbreviation.");
    }
    else if(!emailRegex.test(this.state.email) && this.state.email){
      alert("Please enter a valid email address.");
    }
    else{
      axios.post("api/location", payload, {headers}).then(response=>{
        console.log(response);
        if(response.data.code===301){
          alert("Error: A location with the same name already exists!");
        }
        else{
          if(response.data.code===200){
            alert("Location created successfully.");
          }
          else if(response.data.code===201){
            alert("Location updated successfully.");
          }
          else{
            alert("An error occured with the Location.");
          }
          this.props.uploadMethod();
          this.clearState();
          callback();
        }         
      }).catch(err=>{alert("An error occured with the Location."); console.log(err); this.props.uploadMethod(); this.clearState(); callback();});
    }
  }


  render() {
      return (
        <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => {this.clearState()}} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={(e)=>{close();}} />


              <div className="header" align="left">
              {this.props.locEdit ? 'Edit Location' : 'New Location'}
              </div>

              <div className="content" style={{width: '350px'}}>
              <label>Location Name: </label><br /><input className='locInput' type="text" value={this.state.name || this.getCurrent('name')} onChange={(e)=>this.onInputChange(e, 'name')} placeholder={"Location Name"}/><br /><br />
              <label>Address: </label><br /><input className='locInput' type="text" value={this.state.address || this.getCurrent('address')} onChange={(e)=>this.onInputChange(e, 'address')} placeholder={"123 Main Street"}/><br /><br />
              <label>City: </label><br /><input className='locInput' type="text" value={this.state.city || this.getCurrent('city')} onChange={(e)=>this.onInputChange(e, 'city')} placeholder={"New York"}/><br /><br />
              <label>State: </label><br /><input className='locInput' type="text" value={this.state.state || this.getCurrent('state')} onChange={(e)=>this.onInputChange(e, 'state')} placeholder={"NY"}/><br /><br />
              <label>Zip Code: </label><br /><input className='locInput' type="text" value={this.state.zip || this.getCurrent('zip')} onChange={(e)=>this.onInputChange(e, 'zip')} placeholder={"12345"}/><br /><br />
              <label>Phone Number: </label><br /><input className='locInput' type="text" value={this.state.phone || this.getCurrent('phone')} onChange={(e)=>this.onInputChange(e, 'phone')} placeholder={"XXX-XXX-XXXX"}/><br /><br />
              <label>Email: </label><br /><input className='locInput' type="text" value={this.state.email || this.getCurrent('email')} onChange={(e)=>this.onInputChange(e, 'email')} placeholder={"email@example.com"}/><br /><br />
              <label>Website: </label><br /><input className='locInput' type="text" value={this.state.website || this.getCurrent('website')} onChange={(e)=>this.onInputChange(e, 'website')} placeholder={"www.example.com"}/><br /><br />
              </div>

              <div className="actions">              
                <Button
                  className="button"
                  onClick={() => {close();}}>
                  Cancel
                </Button>

                <Button 
                  className="upload" 
                  onClick={(event)=>{this.uploadLocation(close)}}>
                      {this.props.locEdit ? 'Update' : 'Create'}
                </Button>
              </div>

            </div>
          )}
        </Popup>

      );
  };
}


const emailRegex= RegExp( process.env.REACT_APP_EMAIL_REGEX );


export default CreateTemplate;