import React, { Component } from 'react';
import './App.scss';
import axios from 'axios';
import DocCenter from './DocCenter/DocCenter';
import HouseholdDocCenter from './DocCenter/HouseholdDocCenter';
import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import UserList from './UserList/UserList';
import PermissionList from './PermissionList/PermissionList';
import RoleList from './RoleList/RoleList';
import UserSettings from './UserSettings';
import CustomProperties from 'react-custom-properties';
import StyleCenter from './StyleCenter/StyleCenter';
import HomePage from './PortalHome/HomePage';
import { Menu, MenuItem } from '@progress/kendo-react-layout';
import LoadIcon from './Callback/loading.svg';
import MailTemplate from './MailTemplates/MailTemplate';
import LocationList from './Contact/LocationList';
import Contact from './Contact/Contact';
import WebRepSignUp from './WebRepSignUp';
import DataMapping from './DataMapping/DataMapping';
import DataFileMapping from './DataFileMapping/DataFileMapping';
import DataManagement from './DataManagement/DataManagement';
import DataUpload from './DataUpload/DataUpload';
import Portfolio from './Portfolio/Portfolio';
import Landing from './Portfolio/Landing';
import UserGroups from './UserGroups/UserGroups';
import WebRepWhiteList from './WebRepWhiteList';
import AdvisorRelations from './AdvisorRelations/AdvisorRelations';
import Advisor from './Advisor/Advisor';
import AdvisorAccountList from './Advisor/components/AdvisorAccountList';
import HouseholdList from './Advisor/components/HouseholdList';
import Timesheets from './Timesheets/Timesheets';
import Reports from './Reports/Reports';
import Vacation from './Vacation/Vacation';
import SqlEditor from './SqlEditor/SqlEditor';
import Ssrs from './Ssrs/Ssrs';
import Reporting from './Ssrs/Reporting';
import SuperAdminSettings from './SuperAdmin/SuperAdminSettings';
import CustomLandingSettings from './CustomLandingSettings/CustomLandingSettings';
import ReportLogs from './SuperAdmin/ReportLogs';
import ReportLanding from './Ssrs/ReportLanding';
// import ReportList from './Ssrs/ReportList';
import ReportManagement from './Ssrs/ReportManagement';
import SsrsPackager from './SsrsPackager/SsrsPackager';
import SsrsPackagerReview from './SsrsPackager/SsrsPackagerReview';
import Households from './Households/Households';
import BulkDocumentManagement from './BulkDocumentManagement/BulkDocumentManagement';
import BulkEmailManagement from './BulkEmailManagement/BulkEmailManagement';
import CustomMapping from './CustomMenu/CustomMapping';
import EditUserProfile from './UserProfile/EditUserProfile';
import Widgets from './Widgets/Widgets';
import * as utils from './utilities';

import Churchill from './CustomLanding/Churchill';
import Template1 from './CustomLanding/Template1';
import Template2 from './CustomLanding/Template2';
import Snapshot from './Snapshot/Snapshot';
import BannerSettings from './Banner/BannerSettings';
import BannerLaunchModal from './Banner/components/BannerLaunchModal';
import DataExports from './DataExports/DataExports';
import RunExport from './DataExports/RunExport';


// require('dotenv').config();

class App extends Component {

  goTo(route) {
    if (route !== 'login' && route !== 'home' && route !== 'banner' && route !== 'standardReporting') localStorage.setItem('appGoTo',route);
    this.props.history.push(`/${route}`);
  }

  goToRefresh(route) {
    this.props.history.replace(`/${route}`);
  }

  gotoHouseholdList() {
    this.props.history.push(`/householdList`);
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
  }

  testRep() {
    axios.get('api/testadhoc').then(response => {
      console.log(response.data);
      if (response.data.code === 200 && response.data.report.path) {
        window.open('http://54.175.1.143/api/files?file=' + response.data.report.path + '&name=report', '_blank');
      }
      else {
        alert('An error occurred while generating the report.');
      }
    }).catch(err => console.log(err));
  }

  constructor(props) {
    super(props);
    this.state = {
      company: localStorage.getItem('appCompany') != null ? JSON.parse(localStorage.getItem('appCompany')) : "",
      menuItems: props.menuItems || [],
      loading: localStorage.getItem('appCompany') != null ? true : false,
      openUserProfile: false,
      // styleSettings:{
      //   requireTermsAndConditions: false,
      //   cornerLogo: null,
      //   contactLabel: null,
      //   docsLabel: null,
      // },
      banner: {},
    }
    console.log('state setup',this.state)
    if (props.route !== 'webreportSignUp' && !props.auth.isAuthenticated())
      this.goTo('login');

  }

  componentDidMount() {
    const { renewSession } = this.props.auth;
    if (localStorage.getItem('isLoggedIn') === 'true') {
      renewSession(this.props.route,() => {
        this.checkLoginBeforeGetMenuItems();
        if (localStorage.getItem('appCompany') != null && !this.props.isPortalEntered) {
          this.onPortalClick();
        }
      });
    }
    
  }

  checkLoginBeforeGetMenuItems() {

    if (Object.keys(this.props.loggedInUser).length !== 0 && this.props.loggedInUser.constructor === Object) {//no need to waste time setting menu items if not even logged in yet
      if (!this.state.styleSettings) {
        this.getStyleSettings(() => {
          this.getMenuItems();
        })
      }
      else {
        this.getMenuItems();
      }

    }

  }

  getMenuItems(userinfo) {
    var menu = utils.checkPermission(this.props.loggedInUser, "View Documents") && this.props.loggedInUser.access.findIndex(item => String(item).substring(0, 6) === 'docTab') > -1 ? [{ "text": this.state.styleSettings.docsLabel, "key": "Documents" }] : [];


    var userMenu = {
      "text": this.props.loggedInUser.user, "key": this.props.loggedInUser.user, "items": [
        { "text": "Log Out", "key": "Log Out" }
      ]
    };

    if (this.props.auth.profile['https://' + process.env.REACT_APP_AUTH0_WEB_ADDRESS + '/companies'].length > 1) {
      userMenu.items.splice(0, 0, { "text": "Change Company", "key": "Change Company" });
    }

    if ((Object.keys(this.props.companySettings).length !== 0 && this.props.companySettings.constructor === Object) && this.props.companySettings.locations.length > 0 && utils.checkPermission(this.props.loggedInUser, "Contact")){
      userMenu.items.splice(0, 0, { "text": this.state.styleSettings.contactLabel, "key": "Contact" });
    }

    if (utils.checkPermission(this.props.loggedInUser, "Vacation")) {
      userMenu.items.splice(0, 0, { text: 'Vacation Request', key: 'Vacation' });
    }

    if (utils.checkPermission(this.props.loggedInUser, "Timesheet")) {
      userMenu.items.splice(0, 0, { text: 'Timesheets', key: 'Timesheets' });
    }

    if (utils.checkPermission(this.props.loggedInUser, "Enable My Profile")) {
      userMenu.items.splice(0, 0, { text: 'My Profile', key: 'UserProfile' });
    }

    menu.push(userMenu);

    var superAdminSettings = {
      "text": "CSSI Admin Settings", "key": "CSSI Admin Settings",
      "items": [{ "text": "Site Settings", "key": "Super Admin Settings", "allowedPermission": "CSSI Admin Site Settings" },
      { "text": "Report Logs", "key": "Report Logs", "allowedPermission": "CSSI Admin Site Settings" },
      ]
    }
    if (this.props.loggedInUser && this.props.loggedInUser.company === 'cssi') {
      superAdminSettings.items.push({ text: 'SQL Editor', key: 'SqlEditor', allowedPermission: 'CSSI Admin Site Settings' });
    }

    var customMenu = {
      "text": "Custom Settings", "key": "Custom Settings",
      "items": [{ "text": "Custom Mapping", "key": "Custom Mapping" },
      ]
    }

    console.log(this.props)
    var adminSettings = null;
    var portfolioMenu = null;
    var dataMenu = null;

    if (this.props.customLanding && (this.props.customLanding === "Template 1" || this.props.customLanding === "Template 2")) {
      adminSettings = {
        "text": "Admin Settings", "key": "Admin Settings",
        "items": [{ "text": "Home Page Settings", "key": "Home Page Settings", "allowedPermission": "Home Page Settings" },
        { "text": "Mail Settings", "key": "Mail Settings", "allowedPermission": "Mail Settings" },
        { "text": "Style Settings", "key": "Style Settings", "allowedPermission": "Style Settings" },
        { "text": "Location Settings", "key": "Location Settings", "allowedPermission": "Location Settings" },
        { "text": "User Groups", "key": "User Groups", "allowedPermission": "User Groups" },
        { "text": this.state.styleSettings.usersLabel, "key": "Users", "allowedPermission": "Users" },
        { "text": "Roles", "key": "Roles", "allowedPermission": "Roles" },
        { "text": "Permissions", "key": "Permissions", "allowedPermission": "Permissions" },
        { "text": "Data Mappings", "key": "Data Mappings", "allowedPermission": "Data Mappings" },
        { "text": "Household Management", "key": "Household List Admin", "allowedPermission": "Household Management" },
        { "text": "Report Management", "key": "Report Management", "allowedPermission": "Report Management" },
        { "text": "Banner Management", "key": "Banner Management", "allowedPermission": "Banner Management" },
        { "text": "Bulk Document Management", "key": "Bulk Document Management", "allowedPermission": "Bulk Document Management" },
        { "text": "Bulk Email Management", "key": "Bulk Email Management", "allowedPermission": "Bulk Email Management" }
        ]
      };
    }
    else {
      adminSettings = {
        "text": "Admin Settings", "key": "Admin Settings",
        "items": [{ "text": "Mail Settings", "key": "Mail Settings", "allowedPermission": "Mail Settings" },
        { "text": "Style Settings", "key": "Style Settings", "allowedPermission": "Style Settings" },
        { "text": "Location Settings", "key": "Location Settings", "allowedPermission": "Location Settings" },
        { "text": "User Groups", "key": "User Groups", "allowedPermission": "User Groups" },
        { "text": this.state.styleSettings.usersLabel, "key": "Users", "allowedPermission": "Users" },
        { "text": "Roles", "key": "Roles", "allowedPermission": "Roles" },
        { "text": "Permissions", "key": "Permissions", "allowedPermission": "Permissions" },
        { "text": "Data Mappings", "key": "Data Mappings", "allowedPermission": "Data Mappings" },
        { "text": "Household Management", "key": "Household List Admin", "allowedPermission": "Household Management" },
        { "text": "Report Management", "key": "Report Management", "allowedPermission": "Report Management" },
        { "text": "Banner Management", "key": "Banner Management", "allowedPermission": "Banner Management" },
        { "text": "Bulk Document Management", "key": "Bulk Document Management", "allowedPermission": "Bulk Document Management" },
        { "text": "Bulk Email Management", "key": "Bulk Email Management", "allowedPermission": "Bulk Email Management" },
        ]
      };
    }


    if (this.props.loggedInUser.company === 'cssi')
      adminSettings.items.push({ "text": 'WebRep WhiteList', "key": 'WhiteList', "allowedPermission": "View Whitelist" });

    if (!utils.isUser(this.props.loggedInUser)) {

      if (utils.checkPermission(this.props.loggedInUser, "View Portfolio") && this.props.loggedInUser.access.findIndex(i => i === "portfolio") > -1) {
        if (utils.checkPermission(this.props.loggedInUser, "View Snapshot")) {
          portfolioMenu = {
            "text": "Portfolio", "key": "PortfolioMenu",
            "items": [{ "text": "View Portfolio", "key": "Portfolio" },
                      { "text": "Snapshot", "key": "Snapshot" },
            ]
          }
          
          menu.splice(0, 0, portfolioMenu);
        }
        else {
          menu.splice(0, 0, { "text": "Portfolio", "key": "Portfolio" });
        }
      }


      if (this.props.companySettings.advisorTab) {
        if (this.props.companySettings.showAdvisorViews) {
          menu.splice(0, 0, {
            "text": "Reports", "key": "Advisor", "items": [
              { "text": "Advisor Views", "key": "Advisor Reports", "allowedPermission": "Advisor Reports" },
              { "text": "Run Reports", "key": "Reporting", "allowedPermission": ["Standard Reporting","Custom Reporting"] },
              { "text": "Packaging", "key": "SSRS Packager", "allowedPermission": "Packaging" },
              { "text": "Review Packages", "key": "Review SSRS Packages", "allowedPermission": "Review Packages" }
            ]
          });
        }
        else {
          menu.splice(0, 0, {
            "text": "Reports", "key": "Advisor", "items": [
              { "text": "Run Reports", "key": "Reporting", "allowedPermission": ["Standard Reporting","Custom Reporting"] },
              { "text": "Packaging", "key": "SSRS Packager", "allowedPermission": "Packaging" },
              { "text": "Review Packages", "key": "Review SSRS Packages", "allowedPermission": "Review Packages" }
            ]
          });
        }

      }
    }
    else {
      if (utils.checkPermission(this.props.loggedInUser, "View Portfolio") && this.props.loggedInUser.access.findIndex(i => i === "portfolio") > -1) {
        if (utils.checkPermission(this.props.loggedInUser, "View Snapshot")) {
          portfolioMenu = {
            "text": "Portfolio", "key": "PortfolioMenu",
            "items": [{ "text": "View Portfolio", "key": "Portfolio" },
                      { "text": "Snapshot", "key": "Snapshot" },
            ]
          }
          
          menu.splice(0, 0, portfolioMenu);
        }
        else {
          menu.splice(0, 0, { "text": "Portfolio", "key": "Portfolio" });
        }
      }
    }

    if (utils.checkPermission(this.props.loggedInUser, ["Benchmark Indexes", "Blended Index", "Model Fees", "Security Classification Split","Data Exports","Data File Mappings"])) {
      dataMenu = {
        "text": "Data", "key": "DataMenu",
        "items": [{ "text": "Data File Mappings", "key": "Data File Mappings", "allowedPermission": "Data File Mappings" },
                  { "text": "Data Exports", "key": "Data Exports", "allowedPermission": "Data Exports" },
                  { "text": "Data Management", "key": "Data Management", "allowedPermission": ["Benchmark Indexes", "Blended Index", "Model Fees", "Security Classification Split"] },
        ]
      }
      
      menu.splice(0, 0, dataMenu);
    }

    if (adminSettings.items.length > 0) menu.splice(0, 0, adminSettings);

    if (utils.checkPermission(this.props.loggedInUser, 'Custom Settings')) {
      menu.splice(0, 0, customMenu);
    }

    if (superAdminSettings.items.length > 0) menu.splice(0, 0, superAdminSettings);

    menu.splice(0, 0, { "text": "Home", "key": "HomeLanding" });
    menu = menu.map(
      topMenu => {
        let obj = { ...topMenu };
        if (topMenu.items != null) {
          obj.items = topMenu.items.filter(item =>
            utils.checkPermission((userinfo != null ? userinfo : this.props.loggedInUser), item.allowedPermission)
          )
        }
        return obj
      }
    ).filter(topMenu => topMenu.items == null || topMenu.items.length > 0)

    this.props.setMenuItems(menu);
  }

  onStyleChange = (element, value) => {
    if (this.props.style[element] !== value) {
      var apiBaseUrl = "api/";
      const headers = { 'authorization': 'Bearer '.concat(this.props.auth.idToken) };
      var payload = {
        "company": this.props.loggedInUser.company,
        "element": element,
        "value": value
      }
      axios
        .post(apiBaseUrl + "/style", payload, { headers })
        .then((response) => {
          if (response.data.code === 200) {
            console.log("Style updated.");
          }
        })
        .catch((e) => {
          console.log("Could not update style.");
          console.error(e);
        });
    }
  }

  onCompSettingsChange = (attr, val, isJSON) => {
    if (this.props.companySettings[attr] !== val) {
      this.props.setCompanySettings(attr, val);

      if (isJSON)
        val = JSON.stringify(val);

      this.checkLoginBeforeGetMenuItems();
      const headers = { 'authorization': 'Bearer '.concat(this.props.auth.idToken) };
      var payload;

      payload = {
        "company": this.props.loggedInUser.company,
        "attr": attr,
        "value": val
      }
      console.log(payload);
      axios.post('api/compsettings', payload, { headers }).then(response => console.log(response)).catch(error => console.log(error));
    }
  }

  onCompanyChange = (e) => {
    this.setState({ company: e.target.value });
  }

  onDocCenterClick = (e) => {
    if (this.props.loggedInUser !== "") {

      if (this.props.loggedInUser !== this.props.portalUser)
        this.props.setPortalUser(this.props.loggedInUser);
      this.goTo('docs');

    }
    else {
      alert("Please select a company to login to.");
    }
  }

  onPortfolioClick = (e) => {
    if (this.props.CHassociatedHousehold != null) {
      this.onCHAccountClick(null, this.props.CHassociatedHousehold)
    } else {

      if (this.props.loggedInUser !== this.props.portalUser)
        this.props.setPortalUser(this.props.loggedInUser);

      this.goTo('householdList');
    }
  }

  onSnapshotClick = (e) => {
    console.log('onSnapshotClick')
    this.goTo('snapshot');
  }

  onWidgetsClick = (e) => {
    this.goTo('widgets');
  }

  onStyleClick = (e) => {
    this.goTo('style');
  }

  onHomePageSettingsClick = (e) => {
    this.goTo('homePageSettings');
  }

  getStyleSettings = (cb) => {
    var headers = { 'authorization': 'Bearer '.concat(this.props.auth.idToken) };

    var styleNames = [
      'requireTermsAndConditions',
      'cornerLogo',
      'contactLabel',
      'docsLabel',
      'usersLabel',
      'showFilters',
      'hhPerPage',
      'portfolioTab',
      'advisorTab',
      'portHomeTab',
      'portTabOrder',
      'defaultViewYourDocs',
      'defaultViewSharedDocs',
      'defaultViewSharedDocs2',
      'defaultViewSharedDocs3',
      'defaultViewAdvisorSharedDocs',
      'defaultViewHouseholdDocs',
      'defaultViewWebReports',
      'defaultViewPortfolio',
      'portTabAcctOverview',
      'portTabAcctSumm',
      'portTabHoldings',
      'portTabFixedIncomeHoldings',
      'portTabRGains',
      'portTabTranSumm',
      'portTabPerfOver',
      'themeFontFamily',
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.state.company.company || this.props.loggedInUser.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings }, () => {
          if (cb) {
            cb()
          }
        });
      })
      .catch(err => {
        console.error(err);
      })


  }

  getBanner = (cb) => {
    var headers = { 'authorization': 'Bearer '.concat(this.props.auth.idToken) };

    axios(`api/getBanner`, {
      method: 'GET',
      params: { company: this.state.company.company },
      headers: headers
    })
      .then(response => {
        // console.log(response)
        if (response.data.data == null) {
          cb();
        } else {
          var banner = response.data.data
          //banner.BannerTextWithNewLine = banner.BannerText.replace('\n', '\r\n')
          this.setState({ banner: banner }, () => {
            // console.log(this.state.banner)
            cb()
          });
        }
      })
      .catch(err => {
        console.error(err);
      })


  }

  onLinkClick = (event, rep, idToken) => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.auth.idToken) };
    axios
      .get("api/download?company=" + this.props.loggedInUser.company + "&path=" + rep.contentPath + "&name=" + rep.contentName + "&action=View%20Banner", { headers })
      .then((response) => {
        window.open(response.data, '_blank');
        this.setState({ bannerAcceptEnabled: true })
      })
      .catch((e) => {
        console.error("ERROR");
        return false;
      });

    return false;
  }

  getTermsAccepted = (user, cb) => {
    var headers = { 'authorization': 'Bearer '.concat(this.props.auth.idToken) };

    axios(`api/getTermsAccepted`, {
      method: 'GET',
      params: { company: this.state.company.company, user: user },
      headers: headers
    })
      .then(response => {
        // console.log(response.data.data)
        var termsAccepted = response.data.data.termsAccepted

        this.setState({ termsAccepted: termsAccepted }, () => {
          // console.log(this.state.termsAccepted)
          cb()
        });
      })
      .catch(err => {
        console.error(err);
      })


  }

  routeDocumentUser = (user, bypassLocalStorage) => {
    if (localStorage.getItem('reportArray') || sessionStorage.getItem('reportGoTo')) {
      this.goTo('standardReporting');
    } else if (localStorage.getItem('appGoTo') != null && localStorage.getItem('appGoTo') !== '' && !bypassLocalStorage) {
      if (localStorage.getItem('appGoTo') === "runExport")
        this.goTo('dataExports');
      else
        this.goTo(localStorage.getItem('appGoTo'));
    } else {
      // admin, advisor or super admin
      if (utils.isAdmin(user) || utils.isAdvisor(user) || utils.isSuperAdmin(user)){
        if (user.access.indexOf('portfolio') === -1 && utils.checkPermission(user,"View Documents")) {
          console.log('1 - householdDocCenter - admin/advisor/super - portfolio is not in profile and view documents permission granted')
          this.goTo('HouseholdDocCenter');
        }else {
          console.log('2 - householdList - admin/advisor/super - portfolio is in profile and/or no view documents permission granted')
          this.goTo('householdList');
        }
      }
      // user
      else {
        if (user.access.indexOf('portfolio') === -1 && utils.checkPermission(user,"View Documents")) {
          console.log('3 - docs - user - portfolio is not in profile and view documents permission granted')
          this.goTo('docs');
        } else {
          console.log('4 - householdList - user - portfolio is in profile and/or no view documents permission granted')
          this.goTo('householdList');
        }
      }
    }
  }

  onPortalClick = (e) => {

    if (this.state.company !== "") {
      this.setState({ loading: true });

      const headers = { 'authorization': 'Bearer '.concat(this.props.auth.idToken) };
      var company = this.state.company.company;
      axios.get('api/userInfo?company=' + company + '&email=' + this.props.auth.profile['https://' + process.env.REACT_APP_AUTH0_WEB_ADDRESS + '/username'], { headers }).then((response) => {
        var userInfo = response.data.user;
        userInfo.user = userInfo.email;
        userInfo.company = company;
        this.getStyleSettings(() => {
          if (this.state.styleSettings.requireTermsAndConditions === true) {
            this.getBanner(() => {
              this.getTermsAccepted(userInfo.user, () => {
                userInfo.banner = this.state.banner
                userInfo.termsAccepted = this.state.termsAccepted

                this.logIntoCompany(userInfo, (err) => {
                  this.props.setPortalEntered(true,() => {
                    localStorage.setItem('appCompany',JSON.stringify(this.state.company));
                  
                    if (err) {
                      console.log(err);
                      alert("There was an error logging into this company. There may be an issue with the servers. Please Try Again.");
                      this.setState({ loading: false });
                      this.goToRefresh('home');
                    }
                    else {
                      //   console.log('banner',this.state.styleSettings.requireTermsAndConditions,Object.keys(this.state.banner).length > 0, !this.state.termsAccepted)
                      if (this.state.styleSettings.requireTermsAndConditions === true && Object.keys(this.state.banner).length > 0 && !this.state.termsAccepted){
                        this.goTo('banner');
                      }
                      else{
                        if (userInfo.customLanding && userInfo.customLanding !== 'false' && userInfo.customLanding !== 'None' && (localStorage.getItem('appGoTo') == null || localStorage.getItem('appGoTo') === '')){
                          if (userInfo.customLanding === 'Churchill')                        
                            this.goTo('Churchill');
                          else if (userInfo.customLanding === 'Snapshot')
                            this.goTo('Snapshot');
                          else if (userInfo.customLanding === 'Template 1' || userInfo.customLanding === 'Template 2')                        
                            this.goTo(company);
                        }
                        else{
                          this.routeDocumentUser(userInfo);
                        }
                      }
                    
                    }
                  })
                });
              })

            })
            //display banner here
            // console.log(this.state.styleSettings.requireTermsAndConditions)
            //asdf
          }
          else{
            this.logIntoCompany(userInfo, (err) => {
              this.props.setPortalEntered(true,() => {
                localStorage.setItem('appCompany',JSON.stringify(this.state.company));
                if (err) {
                  console.log(err);
                  alert("There was an error logging into this company. There may be an issue with the servers. Please Try Again.");
                  this.setState({ loading: false });
                  this.goToRefresh('home');
                }
                else {
                  if (userInfo.customLanding && userInfo.customLanding !== 'None' && (localStorage.getItem('appGoTo') == null || localStorage.getItem('appGoTo') === '')){
                    if (userInfo.customLanding === 'Churchill')                        
                      this.goTo('Churchill');
                    else if (userInfo.customLanding === 'Snapshot')
                      this.goTo('Snapshot');
                    else if (userInfo.customLanding === 'Template 1' || userInfo.customLanding === 'Template 2')                        
                      this.goTo(company);
                  }
                  else{
                    this.routeDocumentUser(userInfo);
                  }    
                }
              })
            });
          }


        })

      }).catch((e) => {
        console.error(e);
        alert("An error has occured while logging in.");
      });


    }
    else {
      alert("Please select a company to login to.");
    }
  }

  onBannerAccept = (e) => {

    const headers = { 'authorization': 'Bearer '.concat(this.props.auth.idToken) };

    var payload = {
      user: this.props.loggedInUser.user,
      company: this.props.loggedInUser.company,
    };

    axios.post('api/setAcceptance', payload, { headers })
    // .then(response => {
    //     if (response.data.code === 200) {
    //         this.setState({ fileDownload: [] });
    //         this.setState({ fileSelected: false });
    //         this.getBannerList();
    //     }
    //     else {
    //         alert("Error: User(s) could not be updated.");
    //         this.setState({ fileDownload: [] });
    //         this.setState({ fileSelected: false });
    //         this.getBannerList();
    //     }
    // }).catch(e => console.log(e));

    if (this.props.customLanding && this.props.customLanding !== 'false' && this.props.customLanding !== 'None' && (localStorage.getItem('appGoTo') == null || localStorage.getItem('appGoTo') === '')) {
      if (this.props.customLanding === 'Churchill')
        this.goTo('Churchill');
      else if (this.props.customLanding === 'Snapshot')
        this.goTo('Snapshot');
      else if (this.props.customLanding === 'Template 1' || this.props.customLanding === 'Template 2')
        this.goTo(this.props.loggedInUser.company);
    }
    else {
      this.routeDocumentUser(this.props.loggedInUser)
    }
  }

  onBannerDecline = (e) => {
    this.logout();
  }

  refreshUser = (page, company) => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.auth.idToken) };
    // var company = this.props.auth.profile['https://' + process.env.REACT_APP_AUTH0_WEB_ADDRESS + '/companies'][0].company;
    axios.get('api/userInfo?company=' + company + '&email=' + this.props.auth.profile['https://' + process.env.REACT_APP_AUTH0_WEB_ADDRESS + '/username'], { headers }).then((response) => {
      var userInfo = response.data.user;
      this.props.setLoggedInUser({ ...userInfo, company: company, user: userInfo.email, refreshUser: this.refreshUser },
        () => this.props.setPortalUser({ ...userInfo, company: company },
          () => {
            this.getMenuItems(userInfo);
            this.goTo(page)
          })
      );
    }).catch((e) => {
      console.error(e);
      alert("An error has occured while logging in.");
    });
  }

  logIntoCompany = (userInfo, cb) => {
    var headers = {
      'Authorization': 'Bearer '.concat(this.props.auth.idToken),
    };

    var payload = {
      user: userInfo.user,
      company: userInfo.company
    };

    this.props.setCustomLandingPage(userInfo.customLanding)
    if (userInfo.banner) { this.props.setBanner(userInfo.banner) }
    this.props.setTermsAccepted(userInfo.termsAccepted)
    // this.props.setSuperAdminStatus(userInfo.isSuperAdmin)

    //sets the user to verified if not already and records login time
    axios.post('api/setVerified', payload, { headers }).then(response => {

    }).catch(err => {
      console.log(err);
    });

    axios(`api/superAdminSettings`, {
      method: 'GET',
      params: { company: userInfo.company },
      headers: headers
    })
      .then(response => {
        response.data.data.forEach(setting => {
          if (setting.settingName === 'showAdvisorViews') {
            this.props.setCompanySettings("showAdvisorViews", setting.settingValue.toLowerCase() === 'true' ? true : false);
          }
        })
      })
      .catch(err => console.error(err));

    this.getStyleSettings(() => {
      // console.log(this.state.styleSettings)
      var homeTabTranslated = ''
      switch (this.state.styleSettings.portHomeTab) {
        case 'Account Overview':
          homeTabTranslated = 'equityOver'
          break
        case 'Account Summary':
          homeTabTranslated = 'acctSumm'
          break
        case 'Holdings':
          homeTabTranslated = 'holds'
          break
        case 'Fixed Income Holdings':
          homeTabTranslated = 'fiHolds'
          break
        case 'Realized Gains':
          homeTabTranslated = 'rgain'
          break
        case 'Transaction Summary':
          homeTabTranslated = 'tranSumm'
          break
        case 'Performance Overview':
          homeTabTranslated = 'perfOver'
          break
        default:
          break
      }

      axios.get('api/compsettings?attr=all&company=' + userInfo.company, { headers }).then(response => {
        this.props.setCompanySettings("folderOrder", response.data.setting.folderOrder);
        this.props.setCompanySettings("docTab1", response.data.setting.docTab1);
        this.props.setCompanySettings("docTab2", response.data.setting.docTab2);
        this.props.setCompanySettings("docTab3", response.data.setting.docTab3);
        this.props.setCompanySettings("docTab4", response.data.setting.docTab4);
        this.props.setCompanySettings("docTab5", response.data.setting.docTab5);
        this.props.setCompanySettings("docTab6", response.data.setting.docTab6);
        this.props.setCompanySettings("docTab7", response.data.setting.docTab7);
        this.props.setCompanySettings("contactLabel", response.data.setting.contactLabel);
        this.props.setCompanySettings("docsLabel", response.data.setting.docsLabel);
        this.props.setCompanySettings("usersLabel", response.data.setting.usersLabel);
        //this.props.setCompanySettings("cornerLogo", response.data.setting.cornerLogo);
        this.props.setCompanySettings("cornerLogo", this.state.styleSettings.cornerLogo);
        this.props.setCompanySettings("locations", response.data.setting.locations);
        this.props.setCompanySettings("userUpload", response.data.setting.userUpload);
        this.props.setCompanySettings("showAsOf", response.data.setting.showAsOf);
        this.props.setCompanySettings("showMonthEnds", response.data.setting.showMonthEnds);
        this.props.setCompanySettings("monthsBack", response.data.setting.monthsBack);
        this.props.setCompanySettings("portfolioTab", this.state.styleSettings.portfolioTab);
        this.props.setCompanySettings("advisorTab", this.state.styleSettings.advisorTab);
        this.props.setCompanySettings("docGrid", response.data.setting.docGrid);
        // this.props.setCompanySettings("advAllUsers", response.data.setting.advAllUsers);
        this.props.setCompanySettings("portHomeTab", homeTabTranslated);
        this.props.setCompanySettings("showConsolidated", response.data.setting.showConsolidated);
        this.props.setCompanySettings("showFilters", response.data.setting.showFilters);
        this.props.setCompanySettings("showNetCW", response.data.setting.showNetCW);
        this.props.setCompanySettings("showIncome", response.data.setting.showIncome);
        this.props.setCompanySettings("showInvstGL", response.data.setting.showInvstGL);
        this.props.setCompanySettings("showFeesPaid", response.data.setting.showFeesPaid);
        this.props.setCompanySettings("showPerfStart", response.data.setting.showPerfStart);
        this.props.setCompanySettings("showPerfBench", response.data.setting.showPerfBench);
        this.props.setCompanySettings("showPerfMTD", response.data.setting.showPerfMTD);
        this.props.setCompanySettings("showPerfQTD", response.data.setting.showPerfQTD);
        this.props.setCompanySettings("showPerfPrevQtr", response.data.setting.showPerfPrevQtr);
        this.props.setCompanySettings("showPerf3Month", response.data.setting.showPerf3Month);
        this.props.setCompanySettings("showPerf6Month", response.data.setting.showPerf6Month);
        this.props.setCompanySettings("showPerfYTD", response.data.setting.showPerfYTD);
        this.props.setCompanySettings("showPerfPrevYear", response.data.setting.showPerfPrevYear);
        this.props.setCompanySettings("showPerfYear", response.data.setting.showPerfYear);
        this.props.setCompanySettings("showPerf3Year", response.data.setting.showPerf3Year);
        this.props.setCompanySettings("showPerf5Year", response.data.setting.showPerf5Year);
        this.props.setCompanySettings("showPerf7Year", response.data.setting.showPerf7Year);
        this.props.setCompanySettings("showPerf10Year", response.data.setting.showPerf10Year);
        this.props.setCompanySettings("showPerf15Year", response.data.setting.showPerf15Year);
        this.props.setCompanySettings("showPerf20Year", response.data.setting.showPerf20Year);
        this.props.setCompanySettings("showPerf25Year", response.data.setting.showPerf25Year);
        this.props.setCompanySettings("showPerfITD", response.data.setting.showPerfITD);
        this.props.setCompanySettings("showTwrBench", response.data.setting.showTwrBench);
        this.props.setCompanySettings("showTwrMTD", response.data.setting.showTwrMTD);
        this.props.setCompanySettings("showTwrQTD", response.data.setting.showTwrQTD);
        this.props.setCompanySettings("showTwrPrevQtr", response.data.setting.showTwrPrevQtr);
        this.props.setCompanySettings("showTwr3Month", response.data.setting.showTwr3Month);
        this.props.setCompanySettings("showTwr6Month", response.data.setting.showTwr6Month);
        this.props.setCompanySettings("showTwrYTD", response.data.setting.showTwrYTD);
        this.props.setCompanySettings("showTwrPrevYear", response.data.setting.showTwrPrevYear);
        this.props.setCompanySettings("showTwrYear", response.data.setting.showTwrYear);
        this.props.setCompanySettings("showTwr3Year", response.data.setting.showTwr3Year);
        this.props.setCompanySettings("showTwr5Year", response.data.setting.showTwr5Year);
        this.props.setCompanySettings("showTwr7Year", response.data.setting.showTwr7Year);
        this.props.setCompanySettings("showTwr10Year", response.data.setting.showTwr10Year);
        this.props.setCompanySettings("showTwr15Year", response.data.setting.showTwr15Year);
        this.props.setCompanySettings("showTwr20Year", response.data.setting.showTwr20Year);
        this.props.setCompanySettings("showTwr25Year", response.data.setting.showTwr25Year);
        this.props.setCompanySettings("showTwrITD", response.data.setting.showTwrITD);
        this.props.setCompanySettings("showHoldAsset", response.data.setting.showHoldAsset);
        this.props.setCompanySettings("showHoldSecType", response.data.setting.showHoldSecType);
        this.props.setCompanySettings("showHoldSector", response.data.setting.showHoldSector);
        this.props.setCompanySettings("showHoldInd", response.data.setting.showHoldInd);
        this.props.setCompanySettings("showHoldUser1", response.data.setting.showHoldUser1);
        this.props.setCompanySettings("showHoldUser2", response.data.setting.showHoldUser2);
        this.props.setCompanySettings("showHoldUser3", response.data.setting.showHoldUser3);
        this.props.setCompanySettings("showHoldUser4", response.data.setting.showHoldUser4);
        this.props.setCompanySettings("showHoldUser5", response.data.setting.showHoldUser5);
        this.props.setCompanySettings("userDef1", response.data.setting.userDef1);
        this.props.setCompanySettings("userDef2", response.data.setting.userDef2);
        this.props.setCompanySettings("userDef3", response.data.setting.userDef3);
        this.props.setCompanySettings("userDef4", response.data.setting.userDef4);
        this.props.setCompanySettings("userDef5", response.data.setting.userDef5);
        this.props.setCompanySettings("holdGrp1", response.data.setting.holdGrp1);
        this.props.setCompanySettings("holdGrp2", response.data.setting.holdGrp2);
        this.props.setCompanySettings("holdGrp3", response.data.setting.holdGrp3);
        this.props.setCompanySettings("pieBorderColor", response.data.setting.pieBorderColor);
        this.props.setCompanySettings("pieBorderWidth", response.data.setting.pieBorderWidth);
        this.props.setCompanySettings("classificationGrouping1", response.data.setting.classificationGrouping1);
        this.props.setCompanySettings("pieGradient", response.data.setting.pieGradient);
        this.props.setCompanySettings("tranPeriod", response.data.setting.tranPeriod);
        this.props.setCompanySettings("showSettle", response.data.setting.showSettle);
        this.props.setCompanySettings("growthPeriod", response.data.setting.growthPeriod);
        this.props.setCompanySettings("defaultFees", response.data.setting.defaultFees);
        this.props.setCompanySettings("perfHistIrr", response.data.setting.perfHistIrr);
        this.props.setCompanySettings("growthIrr", response.data.setting.growthIrr);
        this.props.setCompanySettings("acctPeriod1", response.data.setting.acctPeriod1);
        this.props.setCompanySettings("acctPeriod2", response.data.setting.acctPeriod2);
        this.props.setCompanySettings("acctPeriod3", response.data.setting.acctPeriod3);
        this.props.setCompanySettings("acctPeriodMult", response.data.setting.acctPeriodMult);
        this.props.setCompanySettings("acctDropPeriods", response.data.setting.acctDropPeriods);
        this.props.setCompanySettings("aumGrp", response.data.setting.aumGrp);
        this.props.setCompanySettings("aumDropGrps", response.data.setting.aumDropGrps);
        this.props.setCompanySettings("showAcctSummIrr", response.data.setting.showAcctSummIrr);
        this.props.setCompanySettings("showAcctSummTwr", response.data.setting.showAcctSummTwr);
        this.props.setCompanySettings("rgainPeriod", response.data.setting.rgainPeriod);
        this.props.setCompanySettings("enableFeeSelect", response.data.setting.enableFeeSelect);
        this.props.setCompanySettings("landCols", response.data.setting.landCols);
        this.props.setCompanySettings("enablePerfReturnSelect", response.data.setting.enablePerfReturnSelect);
        this.props.setCompanySettings("hhPerPage", response.data.setting.hhPerPage);
        this.props.setCompanySettings("mvChart", response.data.setting.mvChart);
        this.props.setCompanySettings("portTabOrder", this.state.styleSettings.portTabOrder);
        this.props.setCompanySettings("ShowHoldingsAcqDate", response.data.setting.ShowHoldingsAcqDate);
        this.props.setCompanySettings("ShowHoldingsQuantity", response.data.setting.ShowHoldingsQuantity);
        this.props.setCompanySettings("ShowHoldingsUnitCost", response.data.setting.ShowHoldingsUnitCost);
        this.props.setCompanySettings("ShowHoldingsTotalCost", response.data.setting.ShowHoldingsTotalCost);
        this.props.setCompanySettings("ShowHoldingsPrice", response.data.setting.ShowHoldingsPrice);
        this.props.setCompanySettings("ShowHoldingsMarketValue", response.data.setting.ShowHoldingsMarketValue);
        this.props.setCompanySettings("ShowHoldingsPctOfAccount", response.data.setting.ShowHoldingsPctOfAccount);
        this.props.setCompanySettings("ShowHoldingsUGain", response.data.setting.ShowHoldingsUGain);
        this.props.setCompanySettings("ShowHoldingsEstAnnIncome", response.data.setting.ShowHoldingsEstAnnIncome);
        this.props.setCompanySettings("ShowHoldingsYield", response.data.setting.ShowHoldingsYield);
        this.props.setCompanySettings("AlwaysLumpUserHoldings", response.data.setting.AlwaysLumpUserHoldings);

        this.props.setCompanySettings("ShowFixedHoldCusip", response.data.setting.ShowFixedHoldCusip);
        this.props.setCompanySettings("ShowFixedHoldSPRating", response.data.setting.ShowFixedHoldSPRating);
        this.props.setCompanySettings("ShowFixedHoldMoodyRating", response.data.setting.ShowFixedHoldMoodyRating);
        this.props.setCompanySettings("ShowFixedHoldQuantity", response.data.setting.ShowFixedHoldQuantity);
        this.props.setCompanySettings("ShowFixedHoldInterestDividendRate", response.data.setting.ShowFixedHoldInterestDividendRate);
        this.props.setCompanySettings("ShowFixedHoldNextCallDate", response.data.setting.ShowFixedHoldNextCallDate);
        this.props.setCompanySettings("ShowFixedHoldMaturityDate", response.data.setting.ShowFixedHoldMaturityDate);
        this.props.setCompanySettings("ShowFixedHoldTotalCost", response.data.setting.ShowFixedHoldTotalCost);
        this.props.setCompanySettings("ShowFixedHoldMarketValue", response.data.setting.ShowFixedHoldMarketValue);
        this.props.setCompanySettings("ShowFixedHoldEstAnnIncome", response.data.setting.ShowFixedHoldEstAnnIncome);
        this.props.setCompanySettings("ShowFixedHoldUnrealized", response.data.setting.ShowFixedHoldUnrealized);
        this.props.setCompanySettings("ShowFixedHoldPercentage", response.data.setting.ShowFixedHoldPercentage);
        this.props.setCompanySettings("ShowFixedHoldUser5", response.data.setting.ShowFixedHoldUser5);
        this.props.setCompanySettings("ShowFixedHoldUser4", response.data.setting.ShowFixedHoldUser4);
        this.props.setCompanySettings("ShowFixedHoldUser3", response.data.setting.ShowFixedHoldUser3);
        this.props.setCompanySettings("ShowFixedHoldUser2", response.data.setting.ShowFixedHoldUser2);
        this.props.setCompanySettings("ShowFixedHoldUser1", response.data.setting.ShowFixedHoldUser1);
        this.props.setCompanySettings("ShowFixedHoldInd", response.data.setting.ShowFixedHoldInd);
        this.props.setCompanySettings("ShowFixedHoldSector", response.data.setting.ShowFixedHoldSector);
        this.props.setCompanySettings("ShowFixedHoldSecType", response.data.setting.ShowFixedHoldSecType);
        this.props.setCompanySettings("ShowFixedHoldAsset", response.data.setting.ShowFixedHoldAsset);
        this.props.setCompanySettings("fixedHoldGrp1", response.data.setting.fixedHoldGrp1);
        this.props.setCompanySettings("fixedHoldGrp2", response.data.setting.fixedHoldGrp2);
        this.props.setCompanySettings("fixedHoldGrp3", response.data.setting.fixedHoldGrp3);
        this.props.setCompanySettings("FixedHoldIncludeCash", response.data.setting.FixedHoldIncludeCash);


        this.props.setCompanySettings("ShowHoldingsSummUser1", response.data.setting.ShowHoldingsSummUser1);
        this.props.setCompanySettings("ShowHoldingsSummUser2", response.data.setting.ShowHoldingsSummUser2);
        this.props.setCompanySettings("ShowHoldingsSummUser3", response.data.setting.ShowHoldingsSummUser3);
        this.props.setCompanySettings("ShowHoldingsSummUser4", response.data.setting.ShowHoldingsSummUser4);
        this.props.setCompanySettings("ShowHoldingsSummUser5", response.data.setting.ShowHoldingsSummUser5);
        this.props.setCompanySettings("ShowHoldingsSummSector", response.data.setting.ShowHoldingsSummSector);
        this.props.setCompanySettings("ShowHoldingsSummIndustry", response.data.setting.ShowHoldingsSummIndustry);
        this.props.setCompanySettings("ShowHoldingsSummAssetAllocation", response.data.setting.ShowHoldingsSummAssetAllocation);
        this.props.setCompanySettings("ShowHoldingsSummPercentage", response.data.setting.ShowHoldingsSummPercentage);
        this.props.setCompanySettings("ShowHoldingsSummPortfolio", response.data.setting.ShowHoldingsSummPortfolio);
        this.props.setCompanySettings("ShowHoldingsSummMarketValue", response.data.setting.ShowHoldingsSummMarketValue);
        this.props.setCompanySettings("ShowHoldingsSummTotalCost", response.data.setting.ShowHoldingsSummTotalCost);
        this.props.setCompanySettings("ShowHoldingsSummUnrealized", response.data.setting.ShowHoldingsSummUnrealized);
        this.props.setCompanySettings("ShowHoldingsSummQuantity", response.data.setting.ShowHoldingsSummQuantity);
        this.props.setCompanySettings("ShowHoldingsSummSecuritySymbol", response.data.setting.ShowHoldingsSummSecuritySymbol);
        this.props.setCompanySettings("ShowHoldingsSummSecurityName", response.data.setting.ShowHoldingsSummSecurityName);
        this.props.setCompanySettings("holdingsSummGrp1", response.data.setting.holdingsSummGrp1);
        this.props.setCompanySettings("holdingsSummGrp2", response.data.setting.holdingsSummGrp2);
        this.props.setCompanySettings("holdingsSummGrp3", response.data.setting.holdingsSummGrp3);


        // var defaultViews = JSON.parse(response.data.setting.defaultViews);
        // var defaultPortTabs = JSON.parse(response.data.setting.defaultPortTabs);

        var defaultViews = [];
        var defaultPortTabs = [];

        if (this.state.styleSettings.defaultViewYourDocs) {
          defaultViews.push('docTab1')
        }
        if (this.state.styleSettings.defaultViewSharedDocs) {
          defaultViews.push('docTab2')
        }
        if (this.state.styleSettings.defaultViewSharedDocs2) {
          defaultViews.push('docTab3')
        }
        if (this.state.styleSettings.defaultViewSharedDocs3) {
          defaultViews.push('docTab4')
        }
        if (this.state.styleSettings.defaultViewAdvisorSharedDocs) {
          defaultViews.push('docTab5')
        }
        if (this.state.styleSettings.defaultViewWebReports) {
          defaultViews.push('docTab6')
        }
        if (this.state.styleSettings.defaultViewHouseholdDocs) {
          defaultViews.push('docTab7')
        }
        if (this.state.styleSettings.defaultViewPortfolio) {
          defaultViews.push('portfolio')
        }


        if (this.state.styleSettings.portTabAcctOverview) {
          defaultPortTabs.push('equityOver')
        }
        if (this.state.styleSettings.portTabAcctSumm) {
          defaultPortTabs.push('acctSumm')
        }
        if (this.state.styleSettings.portTabHoldings) {
          defaultPortTabs.push('holds')
        }
        if (this.state.styleSettings.portTabFixedIncomeHoldings) {
          defaultPortTabs.push('fiHolds')
        }
        if (this.state.styleSettings.portTabRGains) {
          defaultPortTabs.push('rgain')
        }
        if (this.state.styleSettings.portTabTranSumm) {
          defaultPortTabs.push('tranSumm')
        }
        if (this.state.styleSettings.portTabPerfOver) {
          defaultPortTabs.push('perfOver')
        }



        this.props.setCompanySettings("defaultViews", defaultViews);
        this.props.setCompanySettings("defaultPortTabs", defaultPortTabs);

        //axios.get('api/logo?path=' + response.data.setting.cornerLogo, { headers }).then(response => {
        axios.get('api/logo?company=' + userInfo.company + '&path=' + this.state.styleSettings.cornerLogo, { headers }).then(response => {
          this.props.setLoggedInUser(userInfo, () => {
            this.props.setPortalUser(userInfo, () => {
              var url = "api/style?company=" + userInfo.company;
              headers = {
                'Authorization': 'Bearer '.concat(this.props.auth.idToken),
                'Content-Type': 'application/json'
              };

              axios.get(url, { headers })
                .then(response => {
                  this.props.setStyle(JSON.parse(response.data.style));
                  cb(null);
                }).catch(error => cb(error));
            });
          });
        }).catch(err => cb(err));
      }).catch(error => cb(error));
    })

    // axios(`api/getStyleSettings`, {
    //   method: 'GET',
    //   params: { company: userInfo.company, styleNames: ['showAdvisorViews'] },
    //   headers: headers
    // })
    // .then(response => {
    //   this.props.setCompanySettings("showAdvisorViews", response.data.styleSettings.showAdvisorViews);
    // })
    // .catch(err => {
    //     console.error(err);
    // })


  }

  onViewUsersClick = (e) => {
    this.goTo('users');
  }

  onViewPermissionsClick = (e) => {
    this.goTo('permissions');
  }

  onViewRolesClick = (e) => {
    this.goTo('roles');
  }

  onSettingsClick = (e) => {
    const headers = { 'authorization': 'Bearer '.concat(this.props.auth.idToken) };
    //axios.get('api/notify', { headers }).then((response) => {
    var company = this.props.portalUser.company;
    var email = this.props.portalUser.email;
    axios.get('api/notify?company=' + company + '&email=' + email, { headers }).then((response) => {
      this.props.setUserSettings("notify", response.data.notify);
      this.goTo('settings');
    })
      .catch((e) => {
        console.error(e);
        this.goTo('settings');
      });
  }

  onUserClick = (e) => {
    var userInfo = {
      user: e.dataItem.email,
      company: this.props.portalUser.company,
      // role: e.dataItem.role,
      access: e.dataItem.access,
      upload: e.dataItem.upload
    }
    this.props.setPortalUser(userInfo);
    this.goTo('householdList');
  }

  onAccountClick = (e) => {
    var userInfo = {
      user: e.dataItem.email,
      company: this.props.portalUser.company,
      // role: e.dataItem.role,
      access: e.dataItem.access,
      upload: e.dataItem.upload,
      defaultAcct: { displayName: e.dataItem.displayName, account: e.dataItem.account, accountAnnotation: e.dataItem.accountAnnotation }
    }
    this.props.setPortalUser(userInfo);
    this.goTo('portfolio');
  }


  onCHDocClick = (e) => {
    var userInfo = this.props.portalUser;

    this.props.setPortalUser(userInfo);
    this.goTo('docs');
  }

  onCHAccountClick = (e, associatedHousehold) => {
    var userInfo = this.props.portalUser;

    if (associatedHousehold) {
      userInfo.defaultAcct = { household: associatedHousehold.household, accountAnnotation: associatedHousehold.accountAnnotation, displayName: (associatedHousehold.displayName != null ? associatedHousehold.displayName : associatedHousehold.household), account: associatedHousehold.account }

      if (associatedHousehold.accountCount > 1) {
        userInfo.clickedHousehold = true
      }
      else {
        userInfo.clickedHousehold = false
      }

      userInfo.goToVisualization = null
      this.props.setPortalUser(userInfo);
      this.goTo('portfolio');

    }
    else {
      this.props.setPortalUser(userInfo);
      this.goTo('householdList');
    }

  }

  onLandingAcctClick = (e) => {
    if (!e.dataItem.items) {
      var userInfo = this.props.portalUser;
      if (e.dataItem.details) {
        userInfo.defaultAcct = {
          displayName: e.dataItem.details[0].displayName,
          account: e.dataItem.details[0].account,
          accountAnnotation: e.dataItem.details[0].accountAnnotation,
          householdAnnotation: e.dataItem.details[0].householdAnnotation,
          allEntitiesAnnotation: e.dataItem.details[0].allEntitiesAnnotation,
          allGroupsAnnotation: e.dataItem.details[0].allGroupsAnnotation,
          allAccountsAnnotation: e.dataItem.details[0].allAccountsAnnotation,
        };
        userInfo.clickedHousehold = true
      }
      else {
        userInfo.defaultAcct = {
          displayName: e.dataItem.displayName,
          account: e.dataItem.account,
          accountAnnotation: e.dataItem.accountAnnotation,
          householdAnnotation: e.dataItem.householdAnnotation,
          allEntitiesAnnotation: e.dataItem.allEntitiesAnnotation,
          allGroupsAnnotation: e.dataItem.allGroupsAnnotation,
          allAccountsAnnotation: e.dataItem.allAccountsAnnotation,
        };
        userInfo.clickedHousehold = false
      }

      if (e.dataItem.household) {
        userInfo.defaultAcct.household = e.dataItem.household;
      }
      userInfo.goToVisualization = null
      this.props.setPortalUser(userInfo);

      this.goTo('portfolio');
    }
  }

  onSnapshotBodyClick = (selectedHousehold, visualizationName) => {
    var userInfo = this.props.portalUser;
    userInfo.defaultAcct = {
        displayName: selectedHousehold.details[0].displayName,
        account: selectedHousehold.details[0].account,
        accountAnnotation: selectedHousehold.details[0].accountAnnotation,
        householdAnnotation: selectedHousehold.details[0].householdAnnotation,
        allEntitiesAnnotation: selectedHousehold.details[0].allEntitiesAnnotation,
        allGroupsAnnotation: selectedHousehold.details[0].allGroupsAnnotation,
        allAccountsAnnotation: selectedHousehold.details[0].allAccountsAnnotation,
    };
    userInfo.clickedHousehold = true
    userInfo.defaultAcct.household = selectedHousehold.household;
    userInfo.goToVisualization = visualizationName

    this.props.setPortalUser(userInfo);
    this.goTo('portfolio');
  }

  onDataExportRun = (exportID) => {
    var userInfo = this.props.portalUser;
    userInfo.exportID = exportID
    this.props.setPortalUser(userInfo);
    this.goTo('runExport');
  }

  goToDataExports = (tabNumber) => {
    var userInfo = this.props.portalUser;
    userInfo.dataExportsTab = tabNumber
    this.props.setPortalUser(userInfo);
    this.goTo('dataExports');
  }

  resetDataExportsTab = () => {
    var userInfo = this.props.portalUser;
    userInfo.dataExportsTab = null
    this.props.setPortalUser(userInfo);
  }

  onPreviewUnmapped = (e) => {
    var userInfo = {
      user: e,
      company: this.props.portalUser.company,
      //role:'user',
      access: this.props.loggedInUser.access,
      upload: false,
      defaultAcct: { displayName: e, account: e, accountAnnotation: e },
      unmapped: true
    }
    this.props.setPortalUser(userInfo);
    this.goTo('portfolio');
  }

  onMailClick = (e) => {
    this.goTo('mailSettings');
  }

  onSuperAdminSettingsClick = (e) => {
    this.goTo('superAdminSettings');
  }

  onUserProfileClick = (e) => {
    this.setState({openUserProfile: !this.state.openUserProfile})
  }

  onContactClick = (e) => {
    this.goTo('contact');
  }

  onLocSettingsClick = (e) => {
    this.goTo('locationSettings');
  }

  onDataMappingClick = (e) => {
    this.goTo('dataMappings');
  }

  onDataManagementClick = (e) => {
    this.goTo('dataManagement');
  }

  onDataUploadClick = (e) => {
    this.goTo('dataUpload');
  }

  onDocClick = (e) => {
    if (e) {
      var userInfo = {
        user: e.dataItem.email,
        nickname: e.dataItem.nickname,
        company: this.props.portalUser.company,
        // role: e.dataItem.role,
        access: e.dataItem.access,
        upload: e.dataItem.upload
      };
      this.props.setPortalUser(userInfo);
    }
    else {
      this.props.setPortalUser(this.props.loggedInUser);
    }
    this.goTo('docs');
  }

  onAdvisorClick = (e) => {
    this.goTo('advisor');
  }
  onUserGroupsClick = (e) => {
    this.goTo('userGroups');
  }
  onAdvRelationsClick = (e) => {
    this.goTo('advisorRelations');
  }
  onHouseholdsClick = (e) => {
    this.goTo('Households');
  }
  onBulkDocumentManagementClick = (e) => {
    this.goTo('BulkDocumentManagement');
  }
  onBulkEmailManagementClick = (e) => {
    this.goTo('BulkEmailManagement');
  }
  onAdvAccountListClick = (e) => {
    this.goTo('advAccountList');
  }
  onHouseholdListClick = (e) => {
    this.goTo('householdList');
  }

  onHouseholdDocumentsClick = (e) => {
    if (this.props.loggedInUser !== "") {
      if (this.props.loggedInUser !== this.props.portalUser)
        this.props.setPortalUser(this.props.loggedInUser);
      this.goTo('HouseholdDocCenter');
    }
    else {
      alert("Please select a company to login to.");
    }
  }


  onMenuClick = (e) => {
    var key = e.item.data//"data" prop is used instead of "key" prop because MenuItem does not accept a "key" prop but does accept a "data" prop

    if (key === "Change Company") {
      this.props.changeCompany();
      this.goTo('home');
    }
    if (key === "HomeLanding") {
      this.goHome();
    }
    if (key === "Custom Mapping") {
      this.goTo('customMapping');
    }
    else if (key === "Log Out") {
      this.logout();
    }
    else if (key === "Documents") {
      this.onDocCenterClick();
    }
    else if (key === "Users") {
      this.onViewUsersClick();
    }
    else if (key === "Permissions") {
      this.onViewPermissionsClick();
    }
    else if (key === "Roles") {
      this.onViewRolesClick();
    }
    else if (key === "Style Settings") {
      this.onStyleClick();
    }
    else if (key === "Home Page Settings") {
      this.onHomePageSettingsClick();
    }
    else if (key === "Notification Settings") {
      this.onSettingsClick();
    }
    else if (key === 'Timesheets') {
      this.goTo('timesheet');
    }
    else if (key === 'UserProfile') {
      this.onUserProfileClick();
    }
    else if (key === "Super Admin Settings") {
      this.onSuperAdminSettingsClick();
    }
    else if (key === "Report Logs") {
      this.goTo('reportLogs');
    }
    else if (key === "Mail Settings") {
      this.onMailClick();
    }
    else if (key === "Contact") {
      this.onContactClick();
    }
    else if (key === "Location Settings") {
      this.onLocSettingsClick();
    }
    else if (key === "User Groups") {
      this.onUserGroupsClick();
    }
    else if (key === "Data Mappings") {
      this.onDataMappingClick();
    }
    else if (key === "Data File Mappings") {
      this.goTo('dataFileMappings');
    }
    else if (key === "Data Management") {
      this.onDataManagementClick();
    }
    else if (key === "Data Upload") {
      this.onDataUploadClick();
    }
    else if (key === "Portfolio") {
      this.onPortfolioClick();
    }
    else if (key === "Snapshot") {
      this.onSnapshotClick();
    }
    else if (key === "Widgets") {
      this.onWidgetsClick();
    }
    else if (key === 'WhiteList') {
      this.goTo('whitelist');
    }
    else if (key === 'Report Management') {
      this.goTo('reportManagement');
    }
    else if (key === 'Data Exports') {
      this.goTo('dataExports');
    }
    else if (key === 'Banner Management') {
      this.goTo('bannerManagement');
    }
    else if (key === 'Advisor Relationships') {
      this.onAdvRelationsClick();
    }
    else if (key === 'Household List Admin') {
      this.onHouseholdsClick();
    }
    else if (key === 'Bulk Document Management') {
      this.onBulkDocumentManagementClick();
    }
    else if (key === 'Bulk Email Management') {
      this.onBulkEmailManagementClick();
    }
    else if (key === 'Advisor Reports') {
      this.onAdvisorClick();
    }
    else if (key === 'Account List') {
      this.onAdvAccountListClick();
    }
    else if (key === 'Household List') {
      this.onHouseholdListClick();
    }
    else if (key === 'Household Documents') {
      this.onHouseholdDocumentsClick();
    }
    else if (key === 'Reports') {
      this.goTo('reports');
    }
    else if (key === "Vacation") {
      this.goTo('vacation');
    }
    else if (key === "SqlEditor") {
      this.goTo('sqleditor');
    }
    else if (key === "Reporting") {
      this.goTo('reporting');
    }
    else if (key === "Standard Reporting") {
      this.goTo('standardReporting');
    }
    else if (key === "Standard Reporting - New") {
      this.goTo('standardReportingNew');
    }
    else if (key === "Custom Reporting") {
      this.goTo('customReporting');
    }
    else if (key === "SSRS Packager") {
      this.goTo('ssrsPackager');
    }
    else if (key === "Review SSRS Packages") {
      this.goTo('ssrsPackagerReview');
    }
  }

  goHome = () => {
    console.log('go home')
    if (this.props.customLanding && this.props.customLanding !== 'None' && this.props.customLanding !== 'false') {
      if (this.props.customLanding === 'Churchill')
        this.goTo('Churchill');
      if (this.props.customLanding === 'Snapshot')
        this.goTo('Snapshot');
      else if (this.props.customLanding === 'Template 1' || this.props.customLanding === 'Template 2')
        this.goTo(this.props.loggedInUser.company);
    }
    else {
      this.routeDocumentUser(this.props.loggedInUser, true);
    }

  }

  removeHomepagePadding = () => {
    if (this.props.route === 'home' || !this.props.auth.isAuthenticated()) {
      return { padding: 0, height: '100%' };
    }
    else return { height: '100%' };
  }

  render() {
    const fontFamily = this.props.style ? this.props.style["--theme-font-family"] : ''
    const { isAuthenticated } = this.props.auth;
    if (isAuthenticated() && this.props.route === 'home' && !this.state.loading && this.props.auth.profile['https://' + process.env.REACT_APP_AUTH0_WEB_ADDRESS + '/companies'].length === 1) {
    this.setState({
        company: { company: this.props.auth.profile['https://' + process.env.REACT_APP_AUTH0_WEB_ADDRESS + '/companies'][0].company }
      }, () => this.onPortalClick())
    }
    return (
      <CustomProperties global properties={this.props.style}>
        <div className="App" style={this.removeHomepagePadding()}>
        {this.state.openUserProfile && <EditUserProfile  user={this.props.portalUser} refreshUser={this.refreshUser} currentPage={this.props.route} templates={[]} tempDefault={null} idToken={this.props.auth.idToken} role={this.props.portalUser.role} company={this.props.portalUser.company} open={this.state.openUserProfile} close={this.onUserProfileClick}/>}
          {
            !isAuthenticated() && this.props.route !== 'webreportSignUp' && (
              <HomePage onLogin={this.login} />
            )
          }
          {
            this.props.route === 'webreportSignUp' && (
              <WebRepSignUp />
            )
          }
          {isAuthenticated() && this.props.route !== 'webreportSignUp' && (
            <div className="menuBar">
              <img className="cornerUpload" src={"Logos/" + this.props.companySettings.cornerLogo} alt="Corner Logo" onClick={e => this.goHome()} />
              {isAuthenticated() && this.props.route !== 'home' && (<div className="buttons">
                <Menu user={this.props.loggedInUser} onSelect={this.onMenuClick}>
                  {this.props.menuItems && this.props.menuItems.map(item => {
                    return (<MenuItem text={<>{item.key === this.props.loggedInUser.user ? <div className="userIcon" ><span className="k-icon k-font-icon k-i-user" /></div> : <></>}<div className='menuItemText'>{item.text}</div></>} cssStyle={{fontFamily: fontFamily}} data={item.key} >
                      {item.items && item.items.map(subItem => {
                        return subItem.key === 'Log Out' ?
                          item.items.length > 1 ? <MenuItem cssClass='logout' cssStyle={{ minWidth: '150px', borderTop: '2px solid #9D9D9D', fontFamily: fontFamily }} text={subItem.text} data={subItem.key} />
                            : <MenuItem cssClass='logout' cssStyle={{ minWidth: '150px', fontFamily: fontFamily }} text={subItem.text} data={subItem.key} />
                          : <MenuItem cssStyle={{ minWidth: '150px', fontFamily: fontFamily }} text={subItem.text} data={subItem.key} />
                      })}
                    </MenuItem>)
                  })
                  }
                </Menu>
              </div>)}
            </div>
          )
          }

          {
            isAuthenticated() && this.props.route === 'home' && !this.state.loading && this.props.auth.profile['https://' + process.env.REACT_APP_AUTH0_WEB_ADDRESS + '/companies'].length > 1 && (
              <div className='shadowBox'>
                <div className='companySelect'>
                  <div>Companies</div>
                  <div>
                    <DropDownList data={this.props.auth.profile['https://' + process.env.REACT_APP_AUTH0_WEB_ADDRESS + '/companies']}
                      textField='company'
                      onChange={this.onCompanyChange}
                      className="companyDrop"
                      style={{width:'25vw'}}
                    />
                    {/* <div>Role: {this.state.company.role}</div> */}
                  </div>

                  <Button className='select' onClick={this.onPortalClick}>Enter Portal</Button>

                </div>
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'banner' && (
              <BannerLaunchModal
                banner={this.props.banner}
                onLinkClick={this.onLinkClick}
                idToken={this.props.auth.getIdToken()}
                bannerAcceptEnabled={this.state.bannerAcceptEnabled}
                onBannerAccept={this.onBannerAccept}
                onBannerDecline={this.onBannerDecline}
                user={this.props.portalUser}
              />
            )
          }

          {
            isAuthenticated() && this.props.route === 'home' && this.state.loading && (
              <div style={loadStyle}>
                <img src={LoadIcon} alt="loading" />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'docs' && utils.checkPermission(this.props.loggedInUser, "View Documents") &&
            (
              <div>
                <DocCenter compSettings={this.props.companySettings} user={this.props.portalUser} uploadUser={this.props.loggedInUser} idToken={this.props.auth.getIdToken()} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'HouseholdDocCenter' /*&& utils.checkPermission(this.props.loggedInUser,"Household Document Center")*/ &&
            (
              <div>
                <HouseholdDocCenter compSettings={this.props.companySettings} user={this.props.portalUser} uploadUser={this.props.loggedInUser} idToken={this.props.auth.getIdToken()} />
              </div>
            )
          }
          {
            isAuthenticated() && this.props.route === 'users' && utils.checkPermission(this.props.loggedInUser, "Users") &&
            (
              <div>
                <UserList idToken={this.props.auth.idToken} user={this.props.loggedInUser} onDocClick={this.onDocClick} company={this.props.loggedInUser.company} compSettings={this.props.companySettings} role={this.props.loggedInUser.role} onUserClick={this.onUserClick} refreshUser={this.refreshUser} />
              </div>
            )
          }
          {
            isAuthenticated() && this.props.route === 'permissions' && utils.checkPermission(this.props.loggedInUser, "Permissions") &&
            (
              <div>
                <PermissionList idToken={this.props.auth.idToken} user={this.props.loggedInUser} onDocClick={this.onDocClick} company={this.props.loggedInUser.company} compSettings={this.props.companySettings} role={this.props.loggedInUser.role} onUserClick={this.onUserClick} refreshUser={this.refreshUser} />
              </div>
            )
          }
          {
            isAuthenticated() && this.props.route === 'roles' && utils.checkPermission(this.props.loggedInUser, "Roles") &&
            (
              <div>
                <RoleList idToken={this.props.auth.idToken} user={this.props.loggedInUser} onDocClick={this.onDocClick} company={this.props.loggedInUser.company} compSettings={this.props.companySettings} role={this.props.loggedInUser} onUserClick={this.onUserClick} refreshUser={this.refreshUser} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'style' && utils.checkPermission(this.props.loggedInUser, "Style Settings") &&
            (
              <div>
                <StyleCenter refresh={this.goToRefresh} setStyle={this.props.setStyle} onCompSettingsChange={this.onCompSettingsChange} compSettings={this.props.companySettings} company={this.props.loggedInUser.company} onStyleChange={this.onStyleChange} style={this.props.style} user={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'homePageSettings' && utils.checkPermission(this.props.loggedInUser, "Home Page Settings") &&
            (
              <div>
                <CustomLandingSettings refresh={this.goToRefresh} setStyle={this.props.setStyle} onCompSettingsChange={this.onCompSettingsChange} compSettings={this.props.companySettings} company={this.props.loggedInUser.company} onStyleChange={this.onStyleChange} style={this.props.style} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'settings' && utils.checkPermission(this.props.loggedInUser, "Notification Settings") &&
            (
              <div>
                <UserSettings idToken={this.props.auth.idToken} user={this.props.loggedInUser} userSettings={this.props.userSettings} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'mailSettings' && utils.checkPermission(this.props.loggedInUser, "Mail Settings") &&
            (
              <div>
                <MailTemplate idToken={this.props.auth.idToken} user={this.props.loggedInUser} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'superAdminSettings' && utils.checkPermission(this.props.loggedInUser, "CSSI Admin Site Settings") &&
            (
              <div>
                <SuperAdminSettings idToken={this.props.auth.idToken} user={this.props.loggedInUser} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'reportLogs' && utils.checkPermission(this.props.loggedInUser, "CSSI Admin Site Settings") &&
            (
              <div>
                <ReportLogs idToken={this.props.auth.idToken} user={this.props.loggedInUser} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'customMapping' && utils.checkPermission(this.props.loggedInUser, "Custom Mapping") &&
            (
              <div>
                <CustomMapping idToken={this.props.auth.idToken} user={this.props.loggedInUser} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'Churchill' &&
            (
              <div>
                <Churchill idToken={this.props.auth.idToken} user={this.props.loggedInUser} onCHDocClick={this.onCHDocClick} onCHAccountClick={this.onCHAccountClick} setCHassociatedHousehold={this.props.setCHassociatedHousehold} CHassociatedHousehold={this.props.CHassociatedHousehold} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === this.props.loggedInUser.company && this.props.customLanding === 'Template 1' &&
            (
              <div>
                <Template1 idToken={this.props.auth.idToken} user={this.props.loggedInUser} onCHDocClick={this.onCHDocClick} onCHAccountClick={this.onCHAccountClick} setCHassociatedHousehold={this.props.setCHassociatedHousehold} CHassociatedHousehold={this.props.CHassociatedHousehold} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === this.props.loggedInUser.company && this.props.customLanding === 'Template 2' &&
            (
              <div>
                <Template2 idToken={this.props.auth.idToken} user={this.props.loggedInUser} compSettings={this.props.companySettings} loggedInUser={this.props.loggedInUser} onSnapshotBodyClick={(selectedHousehold, visualizationName) => this.onSnapshotBodyClick(selectedHousehold, visualizationName)} onBack={(e) => this.goBack()} onCHDocClick={this.onCHDocClick} onCHAccountClick={this.onCHAccountClick} setCHassociatedHousehold={this.props.setCHassociatedHousehold} CHassociatedHousehold={this.props.CHassociatedHousehold} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'reportManagement' && utils.checkPermission(this.props.loggedInUser, "Report Management") &&
            (
              <div>
                <ReportManagement idToken={this.props.auth.idToken} user={this.props.loggedInUser} uploadUser={this.props.loggedInUser} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'dataExports' && utils.checkPermission(this.props.loggedInUser, "Data Exports") &&
            (
              <div>
                <DataExports idToken={this.props.auth.idToken} user={this.props.loggedInUser} styleSettings={this.state.styleSettings} onDataExportRun={this.onDataExportRun} resetDataExportsTab={this.resetDataExportsTab} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'runExport' && utils.checkPermission(this.props.loggedInUser, "Data Exports") &&
            (
              <div>
                <RunExport idToken={this.props.auth.idToken} user={this.props.loggedInUser} goToDataExports={this.goToDataExports} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'bannerManagement' && utils.checkPermission(this.props.loggedInUser, "Banner Management") &&
            (
              <div>
                <BannerSettings idToken={this.props.auth.idToken} user={this.props.loggedInUser} styleSettings={this.state.styleSettings} uploadUser={this.props.loggedInUser} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'locationSettings' && utils.checkPermission(this.props.loggedInUser, "Location Settings") &&
            (
              <div>
                <LocationList idToken={this.props.auth.idToken} user={this.props.loggedInUser} updateCompSettings={this.props.setCompanySettings} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'contact' && utils.checkPermission(this.props.loggedInUser, "Contact") &&
            (
              <div>
                <Contact compSettings={this.props.companySettings} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'dataMappings' && utils.checkPermission(this.props.loggedInUser, "Data Mappings") &&
            (
              <div>
                <DataMapping compSettings={this.props.companySettings} user={this.props.loggedInUser} idToken={this.props.auth.idToken} previewUnmapped={this.onPreviewUnmapped} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'dataFileMappings' && utils.checkPermission(this.props.loggedInUser, "Data File Mappings") &&
            (
              <div>
                <DataFileMapping compSettings={this.props.companySettings} user={this.props.loggedInUser} idToken={this.props.auth.idToken} previewUnmapped={this.onPreviewUnmapped} />
              </div>
            )
          }

          {

            isAuthenticated() && this.props.route === 'dataManagement' && utils.checkPermission(this.props.loggedInUser, ["Benchmark Indexes", "Blended Index", "Model Fees", "Security Classification Split"]) &&
            (
              <div>
                <DataManagement compSettings={this.props.companySettings} styleSettings={this.state.styleSettings} user={this.props.loggedInUser} idToken={this.props.auth.idToken} previewUnmapped={this.onPreviewUnmapped} uploadUser={this.props.loggedInUser} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'dataUpload' && utils.checkPermission(this.props.loggedInUser, "Data Upload") &&
            (
              <DataUpload user={this.props.loggedInUser} idToken={this.props.auth.idToken} />
            )
          }

          {
            isAuthenticated() && this.props.route === 'portfolio' && utils.checkPermission(this.props.loggedInUser, "View Portfolio") &&
            (
              <div>
                <Portfolio onGotoHouseholdList={(e) => this.gotoHouseholdList()} compSettings={this.props.companySettings} user={this.props.portalUser} loggedInUser={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'snapshot' && utils.checkPermission(this.props.loggedInUser, "View Snapshot") &&  this.state.styleSettings &&
            (
              <div>
                <Snapshot styleSettings={this.state.styleSettings} type={'full'} onSnapshotBodyClick={(selectedHousehold, visualizationName) => this.onSnapshotBodyClick(selectedHousehold, visualizationName)} onBack={(e) => this.goBack()} compSettings={this.props.companySettings} user={this.props.portalUser} loggedInUser={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }
          
          {
            isAuthenticated() && this.props.route === 'widgets' && utils.checkPermission(this.props.loggedInUser, "Widgets") &&
            (
              <div>
                <Widgets compSettings={this.props.companySettings} user={this.props.portalUser} loggedInUser={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'landing' &&
            (
              <div>
                <Landing onAccountClick={this.onLandingAcctClick} compSettings={this.props.companySettings} user={this.props.portalUser} loggedInUser={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'userGroups' && utils.checkPermission(this.props.loggedInUser, "User Groups") &&
            (
              <div>
                <UserGroups user={this.props.loggedInUser} idToken={this.props.auth.idToken} size='Full' />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'whitelist' && utils.checkPermission(this.props.loggedInUser, "Whitelist") &&
            (
              <div>
                <WebRepWhiteList user={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'advisorRelations' && utils.checkPermission(this.props.loggedInUser, "Advisor Relations") &&
            (
              <div>
                <AdvisorRelations user={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'Households' && utils.checkPermission(this.props.loggedInUser, "Household Management") &&
            (
              <div>
                <Households user={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'BulkDocumentManagement' && utils.checkPermission(this.props.loggedInUser, "Bulk Document Management") &&
            (
              <div>
                <BulkDocumentManagement user={this.props.loggedInUser} idToken={this.props.auth.idToken} previewUnmapped={this.onPreviewUnmapped} />
              </div>
            )
          }
          {
            isAuthenticated() && this.props.route === 'BulkEmailManagement' && utils.checkPermission(this.props.loggedInUser, "Bulk Email Management") &&
            (
              <div>
                <BulkEmailManagement user={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }
          {
            isAuthenticated() && this.props.route === 'advisor' && utils.checkPermission(this.props.loggedInUser, "Advisor Reports") &&
            (
              <div>
                <Advisor user={this.props.loggedInUser} compSettings={this.props.companySettings} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'advAccountList' && utils.checkPermission(this.props.loggedInUser, "Advisor Account List") &&
            (
              <div>
                <AdvisorAccountList user={this.props.loggedInUser} onAccountClick={this.onAccountClick} compSettings={this.props.companySettings} idToken={this.props.auth.idToken} previewUnmapped={this.onPreviewUnmapped} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'householdList' && this.state.styleSettings &&
            (
              <div>
                <HouseholdList user={this.props.loggedInUser} onAccountClick={this.onLandingAcctClick} compSettings={this.props.companySettings} styleSettings={this.state.styleSettings} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'reports' && utils.checkPermission(this.props.loggedInUser, "Report Management") &&
            (
              <div>
                <Reports idToken={this.props.auth.idToken} user={this.props.loggedInUser} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'timesheet' && utils.checkPermission(this.props.loggedInUser, "Timesheet") &&
            (
              <div>
                <Timesheets user={this.props.loggedInUser} compSettings={this.props.companySettings} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'vacation' && utils.checkPermission(this.props.loggedInUser, "Vacation") &&
            (
              <div>
                <Vacation user={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'sqleditor' && this.props.loggedInUser.company === 'cssi' && utils.checkPermission(this.props.loggedInUser, "SqlEditor") &&
            (
              <div>
                <SqlEditor user={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'standardReporting' && utils.checkPermission(this.props.loggedInUser, ["Standard Reporting","Custom Reporting"]) &&
            (
              <div>
                <Ssrs user={this.props.loggedInUser} idToken={this.props.auth.idToken} showStandard={utils.checkPermission(this.props.loggedInUser, "Standard Reporting")} showCustom={utils.checkPermission(this.props.loggedInUser, "Custom Reporting")} />
              </div>
            )
          }

{
            isAuthenticated() && this.props.route === 'reporting' && utils.checkPermission(this.props.loggedInUser, ["Standard Reporting","Custom Reporting"]) &&
            (
              <div>
                <Reporting user={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'standardReportingNew' && utils.checkPermission(this.props.loggedInUser, "Standard Reporting") &&
            (
              <div>
                <ReportLanding user={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'customReporting' && utils.checkPermission(this.props.loggedInUser, "Custom Reporting") &&
            (
              <div>
                <Ssrs user={this.props.loggedInUser} idToken={this.props.auth.idToken} showCustom={true} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'ssrsPackager' && utils.checkPermission(this.props.loggedInUser, "Packaging") &&
            (
              <div>
                <SsrsPackager user={this.props.loggedInUser} idToken={this.props.auth.idToken} />
              </div>
            )
          }

          {
            isAuthenticated() && this.props.route === 'ssrsPackagerReview' && utils.checkPermission(this.props.loggedInUser, "Review Packages") && this.state.styleSettings &&
            (
              <div>
                <SsrsPackagerReview user={this.props.loggedInUser} styleSettings={this.state.styleSettings} idToken={this.props.auth.idToken} />
              </div>
            )
          }

        </div>
      </CustomProperties>
    );
  }
}

const loadStyle = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  height: '100vh',
  width: '100vw',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'white',
}

export default App;