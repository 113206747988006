import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import AccountMapping from './components/AccountMapping';
import AccountAnnotation from './components/AccountAnnotation';
import HouseholdMapping from './components/HouseholdMapping';
import HouseholdUsers from './components/HouseholdUsers';
import UnmappedUsers from "./components/UnmappedUsers";
import axios from 'axios';


class DataMapping extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tabSelected:0,
      hasUnmappedUsers: false
    }
  }

  handleSelect = (e) => {
    this.setState({ tabSelected: e.selected });
  }

  componentDidMount(){
    this.getUnmappedUserCount();
  }

  getUnmappedUserCount() {

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios(`api/unmappedUserCount`, {
      method: 'GET',
      params: { company: this.props.user.company, },
      headers: headers
    })
      .then(response => {
        if (response.data.code === 200) {
          if (response.data.count > 0){
            this.setState({ hasUnmappedUsers: true })
          }
        }

      })
      .catch(err => {
        console.log(err)
      });
  }

  render() {
    return(
        <div>
            <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
                {this.state.hasUnmappedUsers && 
                  <TabStripTab title='Unmapped Users'>
                    <UnmappedUsers user={this.props.user} idToken={this.props.idToken} compSettings={this.props.compSettings}/>
                  </TabStripTab>  
                }
                <TabStripTab title='Unmapped Accounts'>
                    <AccountMapping user={this.props.user} idToken={this.props.idToken} previewUnmapped={this.props.previewUnmapped}/>
                </TabStripTab>
                <TabStripTab title='Household Accounts'>
                    <HouseholdMapping user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title='Household Users'>
                    <HouseholdUsers user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title='Account Annotation'>
                    <AccountAnnotation user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>

            </TabStrip>
        </div>
    );
  }

}

export default DataMapping;