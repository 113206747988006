import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import axios from 'axios';
//import GridPopup from './GridPopup';
import { Upload } from '@progress/kendo-react-upload';
import { process } from '@progress/kendo-data-query';
import { Input } from '@progress/kendo-react-inputs';

class AccountAnnotation extends Component {

  constructor(props) {
    super(props);
    this.state = {
        files:[],
        previewAnnotations:[],
        annotations:[],
        edit:false,
        result:[],
        filter:'',
        showMapped:true,
        dataState:{filter:[]},
    }

    this.getAcctAnnotations=this.getAcctAnnotations.bind(this);
    this.clearState=this.clearState.bind(this);
    this.newRender = this.newRender.bind(this);
    this.onGroupExpandClick = this.onGroupExpandClick.bind(this);
    this.addAnnotation = this.addAnnotation.bind(this);
  }

  componentDidMount(){
    this.getAcctAnnotations();
  }

  clearState(){
    this.setState({
        files:[],
        previewAnnotations:[],
        annotations:[],
        edit:false
    });
  };

  onFilterChange = (e)=>{
    var newDataState = {filter:{logic: 'or',
        filters:[
        {field: 'account', operator: 'contains', value:e.value},
        {field: 'Annotation', operator: 'contains', value:e.value}
      ]}
    };

    var newResult = process(this.state.annotations, newDataState);

    //expand when searching
    for(let i=0; i<newResult.data.length; i++){  
        newResult.data[i].expanded = true;
    }

    this.setState({filter:e.value, dataState:newDataState, result:newResult});
  }

  onAdd = (e)=>{
      this.setState({files:e.newState});
  }

  onRemove = (e)=>{
    this.setState({files:e.newState});
  }

  onStatusChange = (e)=>{
      if(e.response.response.code===200){
        e.newState[0].progress=100;
        e.newState[0].status=4;
        this.setState({previewAnnotations:e.response.response.annotations});
      }
      else{
        e.newState[0].progress=0;
        e.newState[0].status=0;
        e.newState[0].errorLine = e.response.response.errorLine;
        e.newState[0].error = e.response.response.error;
      }
      this.setState({files:e.newState});
  }

  onBeforeUpload = (e)=>{
    e.additionalData.company=this.props.user.company;
  }

  uploadAnnotationsFile(e){
    
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var formData = new FormData();

    formData.append('company', this.props.user.company);
    formData.append('upload', true);
    formData.append('file' , this.state.files[0].getRawFile());

    axios.post('api/uploadAccountAnnotations?company='+this.props.user.company, formData, {headers}).then(response=>{
        if(response.data.code===200){
            alert("Annotations have been successfully uploaded.");
            this.getAcctAnnotations();
            this.clearState();
        }
        else{
            alert("An error has occurred while uploading. Please try again.");
            this.getAcctAnnotations();
            this.clearState();
        }
    }).catch(err=>{
        alert("An error has occurred while uploading. Please try again.");
        this.getAcctAnnotations();
        this.clearState();
    });

  }




  uploadAcctAnnotations(e, annotations){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company:this.props.user.company,
      annotations:annotations
    }
    axios.post('api/accountAnnotations', payload, {headers}).then(response=>{
      if(response.data.code===200){
        alert("Account Annotations have been successfully uploaded.");
        this.clearState();
        this.getAcctAnnotations();
      }
      else if(response.data.code===304){
        alert(response.data.error);
      }
      else{
        alert("An error has occurred while uploading. Please try again.");
        this.clearState();
        this.getAcctAnnotations();
      }
    }).catch(err=>{
      alert("An error has occurred while uploading. Please try again.");
      this.clearState();
      this.getAcctAnnotations();
      console.log(err);
    });
  }

  getAcctAnnotations(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    this.setState({loading:true});

    axios.get('api/accountAnnotations?company='+this.props.user.company, {headers}).then(response=>{
      var result = process(response.data.annotations, this.state.dataState);
      for(let i=0; i<result.data.length; i++){
        result.data[i].expanded = false;
      }
      this.setState({annotations:response.data.annotations, result:result, edit:false});
    }).catch(err=>{
      console.log(err);
      this.setState({annotations:[], edit:false});
    });

  }

  gridItemChange = (e) => {

    e.dataItem[e.field] = e.value;

    
    var originalIndex = this.state.result.data.findIndex(item=>{
      return item.value === e.dataItem.account;
    });

    var annotations = this.state.annotations.slice();

    var annotIndex = annotations.findIndex(annotation=>{
      return annotation.id === e.dataItem.id;
    });

    if(annotIndex>=0)
      annotations[annotIndex][e.field] = e.value;

    var result = process(annotations, this.state.dataState);

    var newIndex = result.data.findIndex(item=>{
      return item.value === e.dataItem.account;
    });

    var currAnnotations;

    if(originalIndex===-1)
    {
      originalIndex = annotIndex;
      newIndex = annotIndex;
    }

    if(originalIndex === -1 || (annotIndex>=0 && annotations[annotIndex].new===true)){
      currAnnotations = result.data.splice(newIndex, 1);
      result.data.splice(0,0,currAnnotations[0]);
    }
    else if(annotIndex>=0 && annotations[annotIndex].newAccountAnnot===true){
        currAnnotations = result.data.splice(newIndex, 1);
        result.data.splice(originalIndex,0,currAnnotations[0]);
    }

    /*
    //keep collapsed/expanded from previous state
    for(let i=0; i<result.data.length; i++){  
      var ind = this.state.result.data.findIndex(item=>{
        return item.value===result.data[i].value;
      });
      if(ind>=0)
        result.data[i].expanded = this.state.result.data[ind].expanded;
      else
        result.data[i].expanded = true;
    }
    */

    this.setState({
        annotations:annotations, result:result
    });
  }

  editGrid = (e) =>{
    var isEdit = !this.state.edit;
    var editAnnot = this.state.annotations.slice();
    for(let i=0; i<editAnnot.length; i++){
        editAnnot[i].inEdit = isEdit;
      
      if(isEdit===false){
        editAnnot[i].new = false;
        editAnnot[i].newAccountAnnot = false;
      }
    }
    this.setState({annotations:editAnnot, edit:isEdit});
  }

  addAnnotation = (e) =>{

    var resetDataState = {filter:[]};
    var annots = this.state.annotations.slice();
    var id = 0;
    for(let i=0; i<annots.length; i++){
      if (annots[i].id >= id){
        id = annots[i].id + 1
      }
      annots[i].new=false;
    } 
    
    //annots.push({id:id, account:"", Annotation:"", inEdit:true, new:true});
    annots.unshift({id:id, account:"", Annotation:"", inEdit:true, new:true});

    var newResult = process(annots, resetDataState);
    //keep collapsed/expanded from previous state
    for(let i=0; i<newResult.data.length; i++){

      var ind = this.state.result.data.findIndex(item=>{
        return item.value===newResult.data[i].value;
      });

      if(ind>=0)
        newResult.data[i].expanded = this.state.result.data[ind].expanded;
      else
        newResult.data[i].expanded = true;
    }

    this.setState({annotations:annots, result:newResult, dataState:resetDataState, filter:''});
  }

  /*
  addMappingEmail(email){
    var maps = this.state.mappings.slice();
    var id = 1;
    if(maps.length>0){
      id = maps[maps.length-1].id+1
    }

    var newResult = this.state.result;

    var index = newResult.data.findIndex(x=>{
      return x.value === email;
    });

    newResult.data[index].items.push({id:id, email:email, account:"", inEdit:true, newEmailMap:true});
    newResult.data[index].expanded = true;

    maps.push({id:id, email:email, account:"", inEdit:true, newEmailMap:true});

    this.setState({mappings:maps, result:newResult});
  }
*/

  deleteAnnotation(e, cell){
    var annots = this.state.annotations.slice();
    var index = annots.findIndex(x=>{
      return (x.id===cell.dataItem.id);
    });

    if(index>=0){
        annots.splice(index, 1);
      var result = process(annots, this.state.dataState);

      //keep collapsed/expanded from previous state
      for(let i=0; i<result.data.length; i++){
        var ind = this.state.result.data.findIndex(item=>{
          return item.value===result.data[i].value;
        });

        result.data[i].expanded = this.state.result.data[ind].expanded;
      }

      this.setState({annotations:annots, result:result});
    }
  }


  createCell(cell){
    return (
      <td>
        <Button icon="trash" onClick = {(event) => this.deleteAnnotation(event, cell)}>Delete?</Button>
      </td>
    );
    // if(cell.rowType==='data'){
    //   if(this.state.edit){
    //     return (
    //       <td>
    //         <Button icon="trash" onClick = {(event) => this.deleteAnnotation(event, cell)}>Delete?</Button>
    //       </td>
    //     );
    //   }
    //   else{
    //     return (
    //       <td>
    //       </td>
    //     );
    //   }
    // }
    // else{
    //   return (
    //     <></>
    //   );
    // }
  }

  createInputCell = (cell) =>{
    return (
      <td>
        <Input value={cell.dataItem[cell.field]} cell={cell} onChange={this.updateInputCell}/>
      </td>
    );
  }

  updateInputCell = (e) => {
    var cell = e.target.props.cell

    var annots = this.state.annotations.slice();
    var index = annots.findIndex(x=>{
      return (x.id===cell.dataItem.id);
    });

    if(index>=0){
        annots[index][cell.field] = e.target.value
        var result = process(annots, this.state.dataState);

      //keep collapsed/expanded from previous state
      for(let i=0; i<result.data.length; i++){
        var ind = this.state.result.data.findIndex(item=>{
          return item.value===result.data[i].value;
        });

        result.data[i].expanded = this.state.result.data[ind].expanded;
      }

      this.setState({annotations:annots, result:result});
    }
  }

  onGroupExpandClick(e){
    e.dataItem.expanded = !e.dataItem.expanded;
    this.forceUpdate();
  }

  newRender(tdElement, cell){
    if(cell.rowType==='groupHeader'){
      if(cell.field==='value'){
        return(
          <td colSpan="3" style={{position: 'sticky', left: '0px', right: '0px', zIndex: '1', background: 'rgb(246, 246, 246)'}}>
            {// eslint-disable-next-line
            <p className="k-reset"><a tabIndex="-1" className={cell.dataItem.expanded ? "k-i-collapse k-icon" : "k-i-expand k-icon"} onClick={(e)=>this.onGroupExpandClick(cell)}></a>{cell.dataItem.value}</p>
            }
          </td>
        );
      }
    }
    else if(cell.rowType==='data'){
      if(cell.field==='email' && cell.dataItem.new!==true){
        return(
          <td>
            {cell.dataItem.email}
          </td>
        );
      }
    }

    return tdElement;
  }

  renderFile = (props)=>{
    const { files } = props;

      return (
      <ul>
          {
            files.map(file =>{
            return file.error ?
            <>
            <div style={{color:'red'}}key={file.name}>
                <div>{file.name}</div>
                <div>Error: {file.error}</div>
            </div>
            </>
            :
            <div style={{color:'green'}}key={file.name}>
                <div>{file.name}</div>
                <div>{this.state.previewAnnotations.length} annotations found.</div>
                <Button style={{textAlign:'right', marginLeft:'10px'}} onClick={e=>this.uploadAnnotationsFile(e)}>Confirm Upload</Button>
                </div>
            })          
          }
      </ul>);
  }

  
  expandChange = (event) => {
    event.dataItem[event.target.props.expandField] = event.value;
    this.forceUpdate();
  }

  onShowMapClick = (e)=>{
    if(this.state.showMapped){
      this.setState({showMapped:false, buttonText:'View Mapped Accounts'});
    }
    else{
      this.getAcctMappings();
      this.setState({showMapped:true, buttonText:'View Unmapped Accounts'});
    }
  }

  render() {
    return(
        <div>
            <table className='fullWidth'>
                <tbody>
                    <tr>
                        <td><h3>Upload Tab-Delimited Account Annotations:</h3>
                          <div>Format should be account/group [tab] Annotation</div>
                          <div><strong>Note:</strong> This will overwrite the current account annotations.</div>
                        </td>
                        <td style={{textAlign: 'right'}}><Upload autoUpload={true} 
                                    disabled={false}
                                    multiple={false} 
                                    files={this.state.files}
                                    listItemUI={this.renderFile}
                                    onAdd={this.onAdd}
                                    onRemove={this.onRemove}
                                    onStatusChange={this.onStatusChange}
                                    onBeforeUpload={this.onBeforeUpload}
                                    saveUrl={'api/uploadAccountAnnotations?company='+this.props.user.company}
                                    saveHeaders={{'authorization': 'Bearer '.concat(this.props.idToken)}}/></td>
                    </tr>
                    <tr><br/></tr>
                    <tr>
                        <td>
                          <div><strong>Special Keywords that can be used in Account/Group field:</strong></div>
                          <div>allportalaccounts = annotation displays for all accounts</div>
                          <div>allportalgroups = annotation displays for all consolidated household groups</div>
                          <div>allportalentities = annotation displays for all accounts and consolidated household groups (will overwrite the above if entered)</div>
                          <div><strong>Note: </strong>specific account/group annotations will take priority over these 3 keywords</div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <br/>
            {this.state.showMapped && !this.state.edit &&
              <span className='acctAnnotationList'>
                <Grid data={this.state.result} 
                      editField="inEdit"
                      //onExpandChange={this.expandChange}
                      expandField="expanded"
                      onItemChange={this.gridItemChange}
                      cellRender={this.newRender}
                      {...this.state.dataState}>
                    <GridToolbar>
                      <strong>Current Mappings</strong>
                      <Button className='docButton' icon='edit' onClick={this.editGrid}>Edit</Button>
                      <Button className='docButton' icon='plus' disabled={!this.state.edit} onClick={this.addAnnotation}>Add Annotation</Button>
                      <Button className='docButton' icon='save' disabled={!this.state.edit} onClick={e=>this.uploadAcctAnnotations(e, this.state.annotations)}>Save</Button>
                      <Input value={this.state.filter} onChange={this.onFilterChange} placeholder='Search...'/>
                    </GridToolbar>
                    <GridColumn headerClassName='gridHeader' width='200' field='account' title='Account/Group' />
                    <GridColumn headerClassName='gridHeader' field='Annotation' title='Annotation' />
                </Grid>
              </span>
            }
            {this.state.showMapped && this.state.edit &&
              <span className='acctAnnotationList'>
                <Grid data={this.state.result} 
                      editField="inEdit"
                      //onExpandChange={this.expandChange}
                      expandField="expanded"
                      onItemChange={this.gridItemChange}
                      cellRender={this.newRender}
                      {...this.state.dataState}>
                    <GridToolbar>
                      <strong>Current Mappings</strong>
                      <Button className='docButton' icon='edit' onClick={this.editGrid}>Edit</Button>
                      <Button className='docButton' icon='plus' disabled={!this.state.edit} onClick={this.addAnnotation}>Add Annotation</Button>
                      <Button className='docButton' icon='save' disabled={!this.state.edit} onClick={e=>this.uploadAcctAnnotations(e, this.state.annotations)}>Save</Button>
                      <Input value={this.state.filter} onChange={this.onFilterChange} placeholder='Search...'/>
                    </GridToolbar>
                    <GridColumn headerClassName='gridHeader' width='200' field='account' title='Account/Group' cell={this.createInputCell} />
                    <GridColumn headerClassName='gridHeader' width='950' field='Annotation' title='Annotation' cell={this.createInputCell} />
                    <GridColumn headerClassName='gridHeader' editable={false} cell={(props) => this.createCell(props)}/>
                </Grid>
              </span>
            }
            
        </div>
    );
  }

}

export default AccountAnnotation;