import React, { Component } from "react";
import {Grid, GridColumn, GridToolbar, GridNoRecords} from '@progress/kendo-react-grid';
import axios from 'axios';
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import AddHouseholdUser from "./AddHouseholdUser";
import { process } from '@progress/kendo-data-query';
import GridLoading from "../../Loaders/GridLoading";

class HouseholdUsers extends Component {

    constructor(props) {
    super(props);

    this.state = {
        household:this.props.household,
        company:this.props.company,
        users:[],
        result:[],
        loading:false,
        dataState:{sort:[{field:'nickname', dir:'asc'}]},
        refreshText:''
      }
      
      this.getHouseholdUsers=this.getHouseholdUsers.bind(this);
      this.clearState=this.clearState.bind(this); 
      this.onCloseModal=this.onCloseModal.bind(this); 
      this.refresh = this.refresh.bind(this);
  }


  componentDidMount(){
  }

  
  componentWillUnmount() {
    this.clearState();
  }
  
  refresh(){
    this.getHouseholdUsers();
  }

  getHouseholdUsers(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    this.setState({loading:true, refreshText:'Loading...'});
    var payload ={
      company:this.props.company,
      household:this.props.household
    }
    axios.post('api/householdUsersGet', payload, {headers}).then(response=>{
      var result = process(response.data.users, this.state.dataState);
      this.setState({users:response.data.users,result:result,  loading:false ,refreshText:''});
    }).catch(err=>{
      console.log(err);
      this.setState({users:[], loading:false, refreshText:''});
    });
  }


  clearState(){
    //this.setState({selectedRole:{text:this.props.user.role}, views:this.props.user.access});
    this.setState({ users:[], result:[] });
  }


  createCell(cell){
    if(cell.rowType==='data'){
      return (
          <div>
          
          <Button icon="trash" onClick = {(event) => this.deleteUserMapping(event, cell)}>Delete?</Button>
          </div>

      );
    }
    else{
      return (
        <></>
      );
    }
  }



  deleteUserMapping(e, cell){
    var users = this.state.users.slice();
    var index = users.findIndex(x=>{
      return (x.id===cell.dataItem.id);
    });

    //verify deletion
    const confirm = window.confirm("Are you sure you want to delete this User Mapping? (" + cell.dataItem.nickname + ")" );

    if(confirm===true){
      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      var payload = {
          company:this.props.company,
          Userid:cell.dataItem.id,
          houeshold:cell.dataItem.household,
          email:cell.dataItem.email
        }

      axios.post('api/deleteHouseholdUser', payload, {headers}).then(response=>{
          if(response.data.code!==201){
              alert("Error: User Mapping could not be deleted.");
          }
          else{      
            if(index>=0){
                alert('Household/User Mapping deleted successfully');
                this.getHouseholdUsers();
            }

          }
      });
    }
  }

  onCloseModal = () => {
    this.clearState();
  }



  changeDataState = (e)=>{
    var newData = process(this.state.users, e.dataState);
    this.setState({dataState:e.dataState, result:newData});
  }

  render() {
    return(
    
      <Popup closeOnDocumentClick={false} trigger={this.props.button} onOpen={(e) => { this.getHouseholdUsers(); }} onClose={(event) => {this.clearState() }} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">
            <Button className="close" icon="close" onClick={(e)=>{close();}} />


            <div className="header" align="left">
                Edit Household Users
              </div>

            <div className='userMappingList'>
                <Grid data={this.state.result}
                  sortable={true}
                  onDataStateChange={this.changeDataState}
                    {...this.state.dataState}>
                <GridNoRecords>
                  {this.state.loading && 'Loading...'}
                  {!this.state.loading && 'No records available'}
                </GridNoRecords>
                <GridToolbar>
                <strong>Current Users Mapped</strong>
                
                <Button icon='refresh' title='Refresh' onClick={(e)=>this.getHouseholdUsers()}>{this.state.refreshText}</Button>
                <AddHouseholdUser refresh={this.refresh} household={this.props.household} user={this.props.loggedInUser} idToken={this.props.idToken} company={this.props.company}   button={<Button className='docButton' icon='plus' onClick={(e)=>this.getHouseholdUsers()   }  >Add User Mapping</Button>} />

                </GridToolbar>

                    <GridColumn  width='200' field='household' title='Household'/>
                    <GridColumn  field='nickname' title='User'/>
                    <GridColumn  field='email' title='Email'/>
                    <GridColumn headerClassName='gridHeader' width='90'  editable={false} cell={(props) => this.createCell(props)}/>
                </Grid>
                {this.state.loading && <GridLoading/>}
            </div>

            
            <div className="actions">              
                <Button
                  className="button"
                  onClick={() => {close();}}>
                  Close
                </Button>

              </div>
        
        
              </div>
          )}
        </Popup>

    );
  }

}


export default HouseholdUsers;