import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { Switch } from '@progress/kendo-react-inputs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ViewsList from './ViewsList'
import axios from 'axios';

class EditUser extends Component {

  constructor(props) {
    super(props);

    var companyViews = [{name:this.props.user.compSettings.docTab1, page:"docTab1"}, 
                        {name:this.props.user.compSettings.docTab2, page:"docTab2"}, 
                        {name:this.props.user.compSettings.docTab3, page:"docTab3"}, 
                        {name:this.props.user.compSettings.docTab4, page:"docTab4"}, 
                        {name:this.props.user.compSettings.docTab5, page:"docTab5"},
                        {name:this.props.user.compSettings.docTab7, page:"docTab7"},
                        {name:'Portfolio', page:"portfolio"}];
    
    if(this.props.user.company==='cssi')
      companyViews.push({name:this.props.user.compSettings.docTab6, page:"docTab6"});

    var portTabs = [{name:"Account Summary", page:"acctSumm"}, 
      {name:"Account Overview", page:"equityOver"}, 
      {name:"Performance Overview", page:"perfOver"}, 
      {name:"Holdings", page:"holds"}, 
      //{name:"Holdings Summary", page:"holdSumm"}, 
      {name:"Fixed Income Holdings", page:"fiHolds"},
      //{name:"Market Value", page:"markVal"},
      //{name:"Group Appraisal", page:"grpApp"},
      //{name:"Historical Market Value", page:"histMarkVal"},
      {name:"Transaction Summary", page:"tranSumm"},
      //{name:"Performance Growth", page:"growth"},
      //{name:"Account AUM", page:"aum"},
      {name:"Realized Gains", page:"rgain"}];

    var userViews = this.props.user.access.slice();
    var userTabs = this.props.user.access.slice();
    


    userViews = userViews.filter(x=>{
      var ind = companyViews.findIndex(y=>{return y.page===x});
      return ind>=0;
    });

    userTabs = userTabs.filter(x=>{
      var ind = portTabs.findIndex(y=>{return y.page===x});
      return ind>=0;
    });


    var useCompDef = this.props.user.access.indexOf('defaultTabs')>=0;
    

    this.state = {
        roles:[{text:"admin"}, {text:"advisor"}, {text:"user"}],
        selectedRole:{text:this.props.user.role},
        companyViews:[],
        portTabs:portTabs,
        enabledTabs:userTabs,
        views:userViews,
        approved:this.props.user.approved,
        searchCompany: this.props.user.searchCompany,
        userUpload:this.props.user.upload,
        userReceiveEmail:this.props.user.receiveEmail,
        nickname:this.props.user.nickname,
        sendApprovalEmail:false,
        editTabs:false,
        useCompDefTabs:useCompDef,
        userEnableMFA: this.props.user.useMFA,
        editEmail: false,
        templates: this.props.templates || [],
        tempSelected: '',
        tempDefault: this.props.tempDefault || {title: 'Default Template'},
        userEmail: this.props.user.email,
        styleSettings: {
          docTab1: null,
          docTab2: null,
          docTab3: null,
          docTab4: null,
          docTab5: null,
          docTab6: null,
          docTab7: null,
        }
      }

      this.updateRole = this.updateRole.bind(this);
      this.onViewsChange = this.onViewsChange.bind(this);
      this.onTabsChange = this.onTabsChange.bind(this);
      this.clearState = this.clearState.bind(this);
      this.sendApprovalEmail = this.sendApprovalEmail.bind(this);
      this.getUploadRadioValue = this.getUploadRadioValue.bind(this);
      this.getReceiveEmailRadioValue = this.getReceiveEmailRadioValue.bind(this);
      this.onUploadChange = this.onUploadChange.bind(this);
      this.onReceiveEmailChange = this.onReceiveEmailChange.bind(this);
      this.resetMFAPhoneNumber = this.resetMFAPhoneNumber.bind(this);
      this.updateEmail = this.updateEmail.bind(this);
      this.onTemplateChange = this.onTemplateChange.bind(this);
      this.getStyleSettings = this.getStyleSettings.bind(this);
  }



  componentDidMount(){
    this.getStyleSettings()
  }

  
  getStyleSettings() {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
        'docTab1',
        'docTab2',
        'docTab3',
        'docTab4',
        'docTab5',
        'docTab7',
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
    .then(response => {
      var viewList = []
      // if (this.props.role === 'admin'){
      //   viewList = [{name:response.data.styleSettings.docTab1, page:"docTab1"}, 
      //                   {name:response.data.styleSettings.docTab2, page:"docTab2"}, 
      //                   {name:response.data.styleSettings.docTab3, page:"docTab3"}, 
      //                   {name:response.data.styleSettings.docTab4, page:"docTab4"}, 
      //                   {name:response.data.styleSettings.docTab5, page:"docTab5"},
      //                   {name:response.data.styleSettings.docTab7, page:"docTab7"},
      //                   {name:'Portfolio', page:"portfolio"}];

      //   if(this.props.company==='cssi')
      //     viewList.push({name:'Web Reports', page:"docTab6"});
      // }
      // else{
      //   viewList = [
      //     {name:response.data.styleSettings.docTab1, page:"docTab1"}, 
      //     {name:response.data.styleSettings.docTab2, page:"docTab2"}, 
      //     {name:response.data.styleSettings.docTab3, page:"docTab3"}, 
      //     {name:response.data.styleSettings.docTab4, page:"docTab4"}, 
      //     {name:response.data.styleSettings.docTab5, page:"docTab5"}, 
      //     {name:'Web Reports', page:"docTab6"}, 
      //     {name:response.data.styleSettings.docTab7, page:"docTab7"}]
      // }

        viewList = [{name:response.data.styleSettings.docTab1, page:"docTab1"}, 
          {name:response.data.styleSettings.docTab2, page:"docTab2"}, 
          {name:response.data.styleSettings.docTab3, page:"docTab3"}, 
          {name:response.data.styleSettings.docTab4, page:"docTab4"}, 
          {name:response.data.styleSettings.docTab5, page:"docTab5"},
          {name:response.data.styleSettings.docTab7, page:"docTab7"},
          {name:'Portfolio', page:"portfolio"}];

        if(this.props.company==='cssi')
          viewList.push({name:'Web Reports', page:"docTab6"});
      

        this.setState({
          styleSettings: response.data.styleSettings, 
          companyViews: viewList
        })
    })
    .catch(err => {
        console.error(err);
    })
}

  getRoles(){
    var arr = this.state.roles.slice();
    var roleInd = arr.findIndex(role=>{
      return role.text===this.props.user.role;
    });
    if(roleInd!==-1){
      arr.splice(roleInd,1);
    }
    return arr;
  }

  clearState(){
    this.setState({selectedRole:{text:this.props.user.role}, views:this.props.user.access, editEmail: false, tempSelected: '', userEnableMFA: this.props.user.useMFA});
  }

  // onTemplateChange = (e) =>{
  //   this.setState({tempSelected:e.target.value});
  // }

  onRoleChange = (e) =>{
    this.setState({selectedRole:e.target.value});
  }

  onUploadChange(e, attr){
    var val='default';

    if(attr==='enabled')
      val=true;
    else if(attr==='disabled')
      val=false;

      this.setState({userUpload:val});
  }


  onReceiveEmailChange(e, attr){
    var val='default';

    if(attr==='enabled')
      val=true;
    else if(attr==='disabled')
      val=false;

      this.setState({userReceiveEmail:val});
  }

  onNameChange = (e) =>{
    this.setState({nickname:e.target.value});
  }

  onEmailChange = (e) =>{
    this.setState({userEmail:e.target.value});
  }

  onCompChange = (e) =>{
    this.setState({searchCompany:e.target.value});
  }

  onViewsChange(view, e){
    var newViews = this.state.views.slice();
    var indView = newViews.indexOf(view.page);

    if(indView===-1)
      newViews.push(view.page);
    else
      newViews.splice(indView, 1);

    if(this.state.approved===false && view.page==='docTab6'){
      this.setState({approved:true, sendApprovalEmail:true});
      newViews.push(view.page);
    }

    this.setState({views:newViews});
  }

  onTabsChange(tab, e){
    var newTabs = this.state.enabledTabs.slice();
    var indTab = newTabs.indexOf(tab.page);

    if(indTab===-1)
      newTabs.push(tab.page);
    else
      newTabs.splice(indTab, 1);

    this.setState({enabledTabs:newTabs});
  }

  sendApprovalEmail(callback){
    var user = this.props.user;
    const headers = { 'authorization': 'Bearer '.concat(user.idToken) };
    var payload = {
        to:user.email,
        userId:user.id
    }

    axios.post('api/approveWebRep', payload, {headers}).then(response=>{
        if(response.data.code!==200){
            callback("Error: Approval email was not sent.");
        }
        callback();
    });
  }

  updateRole(e){
    var user = this.props.user;
    const headers = { 'authorization': 'Bearer '.concat(user.idToken) };

    var payload = {
        email:user.email,
        nickname:this.state.nickname,
        company:user.company,
        searchCompany:this.state.searchCompany,
        role:this.state.selectedRole.text,
        access:this.state.views,
        approved:this.state.approved,
        upload:this.state.userUpload,
        receiveEmail:this.state.userReceiveEmail,
        useMFA:this.state.userEnableMFA
    }

    if(this.state.useCompDefTabs)
      payload.access.push('defaultTabs');
    else
      payload.access = payload.access.concat(this.state.enabledTabs);

    axios.post('api/updateuser', payload, {headers}).then(response=>{
        if(response.data.code===201){
          user.refreshList();
        }
        else if(response.data.code===202){
          this.sendApprovalEmail((err)=>{
            if(err){
              alert(err);
            }
            user.refreshList();
          });
        }
        else{
          alert("Error: User could not be updated.");
        }
    });
  }

  onTemplateChange = (e) => {
    if (e.target.value.title === '-Select a role-') {
      this.setState({ tempSelected: ''});
    }
    else {
      this.setState({ tempSelected: e.target.value.title });
    }
  }

  resetMFAPhoneNumber(e){
    var conf = "";
    conf = window.confirm("Are you sure you want to Reset the users MFA Phone Number?");

    if (conf) {
      var user = this.props.user;
      const headers = { 'authorization': 'Bearer '.concat(user.idToken) };
  
      var payload = {
          email:user.email,
          company:user.company,
          useMFA:this.state.userEnableMFA
      }
  
      axios.post('api/resetMFAPhoneNumber', payload, {headers}).then(response=>{
          if(response.data.code===200){
            alert("MFA Phone Number has been reset.");
          }
          else if(response.data.code===201){
            alert("MFA Phone Number has not be setup yet by the user.");
          }
          else {
            alert("Unable to reset MFA Phone Number.");
          }
          
      });
    }

  }

  updateEmail(e){
    var user = this.props.user;

    var conf = "";


    conf = window.confirm("Are you sure you want to update the Email Address to:\r\n\r\n" + this.state.userEmail);

  //place holders
  var template;
  template=this.state.tempSelected;

    user.verified = false;
    user.emailOld = user.email;
    user.email = this.state.userEmail;
    

    if (conf) {

      const headers = { 'authorization': 'Bearer '.concat(user.idToken) };
      
      var payload = {
        company:user.company,
        user:user,
        template:template
      };
  
      axios.post('api/updateEmail', payload, {headers}).then(response=>{
          if(response.data.code===200){

            var payload2 = {
              emailOld:user.emailOld,
              email:user.email,
              company:user.company,
            }
            axios.post('api/updateUserRolesAfterEmailChange', payload2, {headers}).then(response=>{
              if(response.data.code!==200){
                  alert("Error copying custom roles to new user.");
              }

              var payload3 = {
                email:user.emailOld,
                company:user.company,
                companyToRemove:user.company
              }
              axios.post('api/removeuser', payload3, {headers}).then(response=>{
                if(response.data.code!==201){
                    alert("Error: User Copied, but Old User could not be removed from company.");
                }
                else{
                  alert("User email address has been updated.");
                  user.refreshList();
                }
                });

              });



              
          }
          else if(response.data.code===301){
              alert("Email already exists in the company.");
          }
          else{
              alert(response.data.error + " \nUnable to change user email address.");
          }
          
        });
     
      }


  }



  getUploadRadioValue(field){
    if(field==='default'){
      return this.state.userUpload===null || this.state.userUpload===undefined;
    }
    else if(field==='enabled'){
      return this.state.userUpload;
    }
    else if(field==='disabled' && this.state.userUpload!==undefined){
      return this.state.userUpload===false;
    }
  }

  getReceiveEmailRadioValue(field){
    if(field==='enabled'){
      return this.state.userReceiveEmail || this.state.userReceiveEmail===null || this.state.userReceiveEmail===undefined;;
    }
    else if(field==='disabled'){
      return this.state.userReceiveEmail===false
    }
  }

  useDefault = (e)=>{
    var checked = !this.state.useCompDefTabs;
    if(checked)
      this.setState({useCompDefTabs:checked, editTabs:false});
    else
      this.setState({useCompDefTabs:checked});
  }

  handleChange(e){

    this.setState({[e.target.props.name]: e.target.value})
    //this.forceUpdate();
  }

  /*
  onPhoneInput = (e) =>{
    if(phoneRegex.test(e.target.value)){
      this.setState({invitePhone:e.target.value});
    }
    else{
      this.setState({invitePhone:''});
    }
    
  }
  */



  render() {
      return (
        <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(event) => {this.clearState()}} overlayStyle={{ zIndex: 5 }}  modal>
          {close => (
            <div className="modal">

              <Button className="close" icon="close" onClick={(e)=>{close();}} />


              <div className="header" align="left">
                Edit Profile
              </div>

              <div className="content">
                <div>
                    <label>Name: </label>
                    <input defaultValue={this.props.user.nickname} onChange={this.onNameChange}/>
                </div>
                <br />
                <div>
                    <label>Email: </label>
                    {!this.state.editEmail && <>
                    <input defaultValue={this.props.user.email}  disabled={true} readonly="readonly" />
                    <Button
                    className="button"  
                    onClick={(event)=>{this.setState({editEmail: true}); }}
                    // onClick={(event)=>{this.updateEmail(event); }}
                    style={{margin:'5px', marginLeft:'5px'}}
                    >
                    Update Email
                    </Button>
                    </>
                    }
                    {this.state.editEmail && 
                    <>
                    <input defaultValue={this.props.user.email} onChange={this.onEmailChange}/>
                    <br/>
                    <label>Email Template: </label>
                    <DropDownList 
                      data={this.state.templates}
                      textField='title'
                      defaultItem={this.state.tempDefault} 
                      onChange={this.onTemplateChange} 
                      popupSettings={style} 
                    />
                    <br/>
                    <label>*The updated email address will receive the selected template invite</label>
                    <br/>
                    <Button
                    className="button"
                    // icon="check"  
                    onClick={(event)=>{this.updateEmail(event); }}
                    style={{margin:'5px', marginLeft:'5px'}}
                    >
                      Confirm
                    </Button>
                    <Button
                    className="button"
                    icon="close"  
                    onClick={(event)=>{this.setState({editEmail: false}); }}
                    // onClick={(event)=>{this.updateEmail(event); }}
                    style={{margin:'5px', marginLeft:'5px'}}
                    >
                      Cancel
                    </Button>
                    <br/>
                    </>
                    }
                    

                    

                    

                </div>

                {this.props.user.company==='cssi' && <div style={{marginTop:'5px'}}><label>Company: </label><input defaultValue={this.props.user.searchCompany} onChange={this.onCompChange}/></div>}
                <br />
                {/* <div><label>Role: </label><RoleDropDown roles={this.getRoles()} onChange={this.onRoleChange} default={{text:this.props.user.role}}/></div>
                <br /> */}
                
                {this.props.enableMFA &&
                  <>
                  <label>Enable Multi-Factor Authentication</label><Switch
                    name='userEnableMFA'
                    checked={this.state.userEnableMFA}
                    value={this.state.userEnableMFA}
                    onChange={(e) => this.handleChange(e)}
                  />


                  {/*this.state.userEnableMFA &&
                    <>
                      <Input
                        className="phoneInput"
                        name="phoneNum"
                        style={{ width: "100%", padding:'0px'}}
                        label="Phone (optional)"
                        onBlur = {this.onPhoneInput} 
                        type="tel"
                        pattern={'^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'}
                        required={false}
                        validationMessage="Must enter a valid phone number."
                      />
                      <br/>
                      <br/>
                    </>
                  */}


                  </>
                }

                {this.props.enableMFA && this.props.user.useMFA &&
                  <>
                  <br />
                  <Button
                    className="button"
                    onClick={(event)=>{this.resetMFAPhoneNumber(event); }}>
                    Reset MFA Phone Number
                  </Button>
                  
                  
                  </>
  }

                <div style={{marginBottom:'5px', marginTop:'5px'}}><Button onClick={(e)=>{this.setState({editViews:!this.state.editViews})}}>Edit Views</Button></div>
                {this.state.editViews && <div style={{maxWidth:'600px', maxHeight:'25vh', overflow:'auto'}}>(Changes take effect on next login)<ViewsList data={this.state.companyViews} views={this.state.views} approved={this.props.user.approved} onChange={this.onViewsChange} hidePermissions={true}/></div>}
                <div style={{marginBottom:'10px'}}>
                  <input className='bigger' type="checkbox" checked={this.state.useCompDefTabs} onChange={this.useDefault}/>
                  <label style={{marginLeft:'5px'}}>Use default portfolio tabs?</label>
                </div>
                <div style={{marginBottom:'5px', marginTop:'5px'}}><Button onClick={(e)=>{this.setState({editTabs:!this.state.editTabs})}} disabled={this.state.useCompDefTabs}>Edit Portfolio Tabs</Button></div>
                {this.state.editTabs && <div style={{maxWidth:'600px', maxHeight:'25vh', overflow:'auto'}}>(Changes take effect on next login)<ViewsList data={this.state.portTabs} views={this.state.enabledTabs} onChange={this.onTabsChange} hidePermissions={true}/></div>}
                <div><strong>Upload? </strong>
                                    <input type='radio' onChange={(e)=>this.onUploadChange(e, 'default')} defaultChecked={this.getUploadRadioValue('default')} name='upload' />Default
                                    <input type='radio' onChange={(e)=>this.onUploadChange(e, 'enabled')} defaultChecked={this.getUploadRadioValue('enabled')} name='upload' />Enabled
                                    <input type='radio' onChange={(e)=>this.onUploadChange(e, 'disabled')} defaultChecked={this.getUploadRadioValue('disabled')} name='upload' />Disabled
                </div>
                <br/>
                <div><strong>Receive Portal Email Notifications? </strong>
                                    <input type='radio' onChange={(e)=>this.onReceiveEmailChange(e, 'enabled')} defaultChecked={this.getReceiveEmailRadioValue('enabled')} name='receiveEmail' />Enabled
                                    <input type='radio' onChange={(e)=>this.onReceiveEmailChange(e, 'disabled')} defaultChecked={this.getReceiveEmailRadioValue('disabled')} name='receiveEmail' />Disabled
                </div>

              </div>
              

              <div className="actions">              
                <Button
                  className="button"
                  onClick={() => {close();}}>
                  Cancel
                </Button>

                <Button 
                  className="upload"  
                  onClick={(event)=>{this.updateRole(event); close();}}>
                      Update
                </Button>
              </div>

            </div>
          )}
        </Popup>

      );
  };
}

const style = {
  className: "top",
};

//const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

export default EditUser;