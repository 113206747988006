import React, { Component } from "react";
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import GridLoading from '../Loaders/GridLoading';
import { Button } from '@progress/kendo-react-buttons';
import { Upload } from '@progress/kendo-react-upload';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process, orderBy } from '@progress/kendo-data-query';
import EditHousehold from "./components/EditHousehold";
import HouseholdAccounts from "./components/HouseholdAccounts";
import HouseholdUsers from "./components/HouseholdUsers";
import DetailGrid from './components/DetailGrid';
import AddGroup from './components/AddGroup';

import axios from 'axios';


class Households extends Component {

  constructor(props) {
    super(props);
    this.state = {
      files: [],
      previewHouseholds: [],
      households: [],
      result: [],
      loading: true,
      groupLoading: true,
      hasAggAcctCode: false,
      dataState: { skip: 0, take: 25, sort:[{field:'household', dir:'asc'}] },
      tabSelected:0,
      groups:[],
      sort:[{field: 'name', dir:'asc'}],
      append: false,
      copyHouseholds: false,
      convertHouseholdLoading: false,
      convertButtonText: 'Update Household Entries in Group Tables'
    }

    this.getHouseholds = this.getHouseholds.bind(this);
    this.getSuperAdminSettings = this.getSuperAdminSettings.bind(this);
    this.addHousehold = this.addHousehold.bind(this);
    this.clearState = this.clearState.bind(this);
    this.checkHHExists = this.checkHHExists.bind(this);
    this.getGroups = this.getGroups.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);

  }


    checkHHExists(householdCode) {

    var bFlag = false;

    for (let i = 0; i < this.state.households.length; i++) {
      if (this.state.households[i].household.toUpperCase() === householdCode.toUpperCase()) {
        bFlag = true;
      }
    }
      return bFlag;
    }


    componentDidMount() {
      this.getSuperAdminSettings();
      this.getHouseholds();
      this.getGroups();
    }


    _export;
    export = () =>{
      this._export.save();
    }


    /*
      getHouseholds(){
        this.setState({refreshText:'Loading...'});
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        this.setState({loading:true});
        axios.get('api/households?company='+this.props.user.company, {headers}).then(response=>{
          var result = process(response.data.households, this.state.dataState);
          for(let i=0; i<result.data.length; i++){
            result.data[i].expanded = false;
          }
          this.setState({households:response.data.households, result:result,  refreshText:''});
        }).catch(err=>{
          console.log(err);
          this.setState({households:[],   refreshText:''});
        });
      }
    */

      getSuperAdminSettings() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios(`api/superAdminSettings`, {
          method: 'GET',
          params: { company: this.props.user.company },
          headers: headers
        })
          .then(response => {
            response.data.data.forEach(setting => {
              if (setting.settingName === 'copyHouseholds') {
                this.setState({ copyHouseholds: setting.settingValue.toLowerCase() === 'true' ? true : false })
              }
              if (setting.settingName === 'createHouseholdGroupTableEntriesAutomation') {
                this.setState({ enableAutomation: setting.settingValue.toLowerCase() === 'true' ? true : false })
              }
            })
    
          })
          .catch(err => console.log(err));
      }

    getHouseholds() {
      this.setState({ loading: true, hasAggAcctCode: false });
      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      axios.get('api/households?company=' + this.props.user.company, { headers }).then(response => {
        var result = process(response.data.households, this.state.dataState);
        this.setState({ households: response.data.households, result: result, loading: false });
        for (let hh of response.data.households){
          if (hh.AggAccountCode){
            this.setState({hasAggAcctCode: true})
            break
          }
        }
      }).catch(err => {
        console.log(err);
        this.setState({ households: [], loading: false });
      });
    }

    convertHouseholdStructureToGroup() {
      this.setState({convertHouseholdLoading: true, convertButtonText: 'Converting...'})
      var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
  
      var payload = {
        company:this.props.user.company
      }
      axios.post("api/convertHouseholdStructureToGroup", payload, {headers}).then(response => {
        if(response.data.code===200){
          alert('Group Tables have been updated');
          this.setState({convertHouseholdLoading: false, convertButtonText: 'Update Household Entries in Group Tables' })
          this.getGroups();
        }
        else{
          alert("Error converting household structure to group membership"); 
          console.log(response.data.error);
          this.setState({convertHouseholdLoading: false, convertButtonText: 'Update Household Entries in Group Tables'})
          this.getGroups();
        }
      }).catch(err=>{
          alert("Error converting household structure to group membership"); 
          console.log(err);
          this.setState({convertHouseholdLoading: false, convertButtonText: 'Update Household Entries in Group Tables'})
          this.getGroups();
        });  
    }

    deleteExistingHouseholdsFromGroupTables() {
      var confirm = window.confirm("This will delete existing households from the group tables. Continue?");

      if (confirm){
        this.setState({deleteHouseholdLoading: true})
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
        var payload = {
          company:this.props.user.company
        }
        axios.post("api/deleteExistingHouseholdsFromGroupTables", payload, {headers}).then(response => {
          if(response.data.code===200){
            alert('Existing households have been deleted from the group tables.');
            this.setState({deleteHouseholdLoading: false})
            this.getGroups();
          }
          else{
            alert("Error deleting existing households from the group tables."); 
            console.log(response.data.error);
            this.setState({deleteHouseholdLoading: false})
            this.getGroups();
          }
        }).catch(err=>{
            alert("Error deleting existing households from the group tables."); 
            console.log(err);
            this.setState({deleteHouseholdLoading: false})
            this.getGroups();
          });
      }
        
    }

    setHouseholdGroupTableAutomation(e) {
      var enableAutomation = e.target.checked
      this.setState({enableAutomationLoading: true})

        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    
        var payload = {
          company:this.props.user.company,
          enableAutomation: enableAutomation
        }
        axios.post("api/setCreateHouseholdGroupTableEntriesAutomation", payload, {headers}).then(response => {
          if(response.data.code!==200){
            alert("Error updating automation setting."); 
            console.log(response.data.error);
            this.setState({enableAutomationLoading: false})
          }
          else{
            this.setState({enableAutomationLoading: false, enableAutomation: enableAutomation})
          }
        }).catch(err=>{
            alert("Error updating automation setting."); 
            console.log(err);
            this.setState({enableAutomationLoading: false})
          });
        
    }


    changeDataState = (e) => {
      var newData = process(this.state.households, e.dataState);
      this.setState({ dataState: e.dataState, result: newData });
    }




    createCell(cell) {
      if (cell.rowType === 'data') {
        return (
          <div>
            {<EditHousehold isAdd={false} refreshHH={this.getHouseholds} checkHHExists={this.checkHHExists} user={this.props.loggedInUser} idToken={this.props.idToken} company={this.props.user.company} existingHH={true} household={cell.dataItem.household} householdName={cell.dataItem.householdName} AggAccountCode={cell.dataItem.AggAccountCode} button={<Button>Edit Household</Button>} />}
            <HouseholdAccounts user={this.props.loggedInUser} idToken={this.props.idToken} company={this.props.user.company} household={cell.dataItem.household} button={<Button>Account List</Button>} />
            <HouseholdUsers user={this.props.loggedInUser} idToken={this.props.idToken} company={this.props.user.company} household={cell.dataItem.household} button={<Button>User List</Button>} />
            <Button icon="trash" onClick={(event) => this.deleteHousehold(event, cell)}>Delete?</Button>
          </div>

        );
      }
      else {
        return (
          <></>
        );
      }
    }


    deleteHousehold(e, cell) {
      var households = this.state.households.slice();
      var index = households.findIndex(x => {
        return (x.id === cell.dataItem.id);
      });

      //verify deletion
      const confirm = window.confirm("Are you sure you want to delete this Household? (" + cell.dataItem.household + ")");

      if (confirm === true) {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = {
          company: this.props.user.company,
          HHid: cell.dataItem.id,
          houeshold: cell.dataItem.household
        }

        axios.post('api/deleteHousehold', payload, { headers }).then(response => {
          if (response.data.code !== 201) {
            alert("Error: Household could not be deleted.");
          }
          else {
            if (index >= 0) {
              households.splice(index, 1);
              var result = process(households, this.state.dataState);
              //keep collapsed/expanded from previous state
              for (let i = 0; i < result.data.length; i++) {
                var ind = this.state.result.data.findIndex(item => {
                  return item.value === result.data[i].value;
                });
                result.data[i].expanded = this.state.result.data[ind].expanded;
              }
              this.setState({ households: households, result: result });
            }

          }
        });
      }
    }



    addHousehold() {



      //want to refresh the data grid (DB might be updated)
      this.getHouseholds();
      //this.getGroups();

      //maybe default the filter?  for user new household easily

    }


    clearState() {
      this.setState({
        filter: ''
      });
    }




    handleSelect = (e) => {        
      this.setState({ tabSelected: e.selected });

      // if(this.props.user.access.indexOf('docTab1')!==-1 && e.selected===0)
      //     this.getFileTree();

      // var copyFiles = this.state.files.slice();
      // var copyAdv = this.state.advSharedFiles.slice();
      // var copyHousehold = this.state.householdFiles.slice();
      // var copyGridFiles = this.state.gridFiles.slice();
      // var copyGridAdv = this.state.gridAdvSharedFiles.slice();
      // var copyGridHousehold= this.state.gridHouseholdFiles.slice();

      // this.setState({fileDownload:{ text: "", path: ""}});
      // this.setState({fileSelected:false});
      // this.setState({gridFilter:undefined});
      // this.setState({filterable:this.props.compSettings.showFilters});
      
      // this.clearSelected(copyFiles);
      // this.clearSelected(copyAdv);
      // this.clearSelected(copyHousehold);
      // this.clearSelected(copyGridFiles);
      // this.clearSelected(copyGridAdv);
      // this.clearSelected(copyGridHousehold);
    }

    deleteGroup(groupName){
      var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
  
      var payload = {
        group:groupName,
        company:this.props.user.company
      }
      axios.post("api/deleteHouseholdGroup", payload, {headers}).then(response => {
        if(response.data.code===200){
          alert('Household group successfully deleted');
          this.getGroups();
        }
        else{
          alert(response.data.error);
          this.getGroups();
        }
      }).catch(err=>{
          alert("An error has occured"); 
          console.log(err);
          this.getGroups();
        });  
    }
  
    getGroups(){
      this.setState({groupLoading:true});
      var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      axios.get("api/householdgroups?company="+this.props.user.company, {headers}).then(response => {
        if(response.data.code===200){
          this.setState({groups:response.data.groups, groupLoading:false});
        }
        else{
          alert("An error occured while fetching the user groups.");
          this.setState({groupLoading:false});
        }
      }).catch(error => console.log(error));
    }

    expandChange = (e) =>{
      e.dataItem.expanded = e.value;
      if(e.value){
        e.dataItem.idToken = this.props.idToken;
        e.dataItem.loggedInUser = this.props.user;
        e.dataItem.refresh = this.getGroups;
      }
      else{
        e.dataItem.idToken=null;
        e.dataItem.loggedInUser=null;
        e.dataItem.refresh = null;
      }
      this.setState({ ...this.state });
    }

    render() {
      return (
        <div className="householdManagement">
          <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
            <TabStripTab title={'Households'}>
              <ExcelExport data={this.state.households} fileName={'HouseholdList_Export.xlsx'} ref={(exporter)=>{this._export = exporter;}}>
                <ExcelExportColumn field='household' title='Household'/>
                <ExcelExportColumn field='householdName' title='Household Name'/>
                {this.state.hasAggAcctCode &&
                  <ExcelExportColumn field='AggAccountCode' title='AggAcctCode'/>          
                }
              </ExcelExport>
              <table className='fullWidth'>
                <tbody>
                  <tr>
                    <td><h3>Upload Tab-Delimited Household List:</h3>
                    <label style={{paddingLeft:'20px'}} ><input type='checkbox' checked={this.state.append} onChange={(e)=>this.setState({append:!this.state.append})}/>Append Only</label>
                      <br/><br/>
                      <div>Format should be Household [tab] Household Name [tab] AggAcctCode (Optional)</div>
                      {!this.state.append && <div><strong>Note:</strong> This will overwrite the current Household List.</div>}
                      {this.state.append && <br/>}
                    </td>
                    {this.state.copyHouseholds && 
                      <td>
                        <Button title='Convert Household Struture' disabled={this.state.convertHouseholdLoading} onClick={(e) => this.convertHouseholdStructureToGroup()}>{this.state.convertButtonText}</Button>
                        <span style={{padding:'10px'}} />
                        <Button title='Delete Household Strutures' disabled={this.state.deleteHouseholdLoading} onClick={(e) => this.deleteExistingHouseholdsFromGroupTables()}>{"Delete Existing Households from Group Tables"}</Button>
                        <br/><br/>
                        <label style={{}} ><input type='checkbox' checked={this.state.enableAutomation} disabled={this.state.enableAutomationLoading} onChange={(e)=>this.setHouseholdGroupTableAutomation(e)}/>Automatically Refresh Group Table (approx. 6am ET)</label>
                      </td>
                    }
                    <td style={{textAlign: 'right'}}><Upload autoUpload={true}
                      disabled={false}
                      multiple={false}
                      files={this.state.files}
                      listItemUI={this.renderFile}
                      onAdd={this.onAdd}
                      onRemove={this.onRemove}
                      onStatusChange={this.onStatusChange}
                      onBeforeUpload={this.onBeforeUpload}
                      saveUrl={'api/householdsList?company='+this.props.user.company}
                      saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }} /></td>
                  </tr>
                </tbody>
              </table>



              <Grid data={this.state.result}
                filterable={true}
                sortable={true}
                pageable={{ pageSizes: [10, 25, 50, 100, 250, 500] }}
                onDataStateChange={this.changeDataState}
                {...this.state.dataState}>

                <GridToolbar>
                  <strong>Current Households</strong>
                  <Button icon='refresh' title='Refresh' onClick={(e) => this.getHouseholds()}>{this.state.refreshText}</Button>
                  <EditHousehold isAdd={true} refreshHH={this.getHouseholds} checkHHExists={this.checkHHExists} user={this.props.loggedInUser} idToken={this.props.idToken} company={this.props.user.company} onClose={() => { this.clearState() }} button={<Button className='docButton' icon='plus'>Add Household</Button>} />
                  <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
                </GridToolbar>

                <GridColumn headerClassName='gridHeader' field='household' title='Household' />
                <GridColumn headerClassName='gridHeader' field='householdName' title='Name' />
                <GridColumn headerClassName='gridHeader' width='200' field='AggAccountCode' title='Agg. Account Code' />
                <GridColumn headerClassName='gridHeader' width='400' filterable={false} editable={false} cell={(props) => this.createCell(props)} />

              </Grid>
              {this.state.loading && <GridLoading />}
            </TabStripTab>

            <TabStripTab title={'Household Groups'}>
              <div className={'householdGroupsFull'}>
                <Grid data={orderBy(this.state.groups, this.state.sort)}
                    detail={DetailGrid}
                    expandField='expanded'
                    onExpandChange={this.expandChange}>
                    <GridToolbar>
                        {this.props.back && <Button onClick={this.props.back}>Back to Users</Button>}
                        <AddGroup user={this.props.user} idToken={this.props.idToken} households={this.state.households} refreshGroups={this.getGroups} button={<Button icon='plus'>Create Group</Button>} />
                    </GridToolbar>
                    <GridColumn field='name' title='Group'/>
                    <GridColumn width='105px' cell={(props)=><td><Button icon='delete' onClick={(e)=>this.deleteGroup(props.dataItem.name)}>Delete</Button></td>}/>
                </Grid>
                {this.state.groupLoading && <GridLoading gridClass='userGroups'/>}
              </div>
            </TabStripTab>
          </TabStrip>
        </div>
        
      );
    }


    onAdd = (e) => {
      this.setState({ files: e.newState });
    }
    onRemove = (e) => {
      this.setState({ files: e.newState });
    }
    onBeforeUpload = (e) => {
      e.additionalData.company = this.props.user.company;
    }
    onStatusChange = (e) => {
      if (e.response.response.code === 200) {
        e.newState[0].progress = 100;
        e.newState[0].status = 4;
        this.setState({ previewHouseholds: e.response.response.households });
      }
      else {
        e.newState[0].progress = 0;
        e.newState[0].status = 0;
        e.newState[0].error = e.response.response.error;
      }
      this.setState({ files: e.newState });
    }
    renderFile = (props) => {
      const { files } = props;

      return (
        <ul>
          {
            files.map(file => {
              return file.error ?
                <>
                  <div style={{ color: 'red' }} key={file.name}>
                    <div>{file.name}</div>
                    <div>Error: {file.error}</div>
                  </div>
                </>
                :
                <div style={{ color: 'green' }} key={file.name}>
                  <div>{file.name}</div>
                  <div>{this.state.previewHouseholds.length} households found.</div>
                  <Button style={{ textAlign: 'right', marginLeft: '10px' }} onClick={e => this.uploadHouseholds(e)}>Confirm Upload</Button>
                </div>
            })
          }
        </ul>);
    }
    uploadHouseholds(e) {

      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      var formData = new FormData();

      formData.append('company', this.props.user.company);
      formData.append('upload', true);
      formData.append('file', this.state.files[0].getRawFile());

      var appendMode = false
      if (this.state.append){
        appendMode = true
        formData.append('append', true);
      }
        


      axios.post('api/householdsList?company='+this.props.user.company, formData, { headers }).then(response => {
        console.log(response.data.code)
        if (response.data.code === 200) {
          if (appendMode){
            var existingString = ''
            if (response.data.householdCount.existing > 0)
                existingString = "\n" + response.data.householdCount.existing + " mappings already existed"

            alert("Households have been successfully uploaded.\n\n" 
              + response.data.householdCount.new + " households were added"
              + existingString)  
          }
          else{
            alert("Households have been successfully uploaded.");
          }
          this.getHouseholds();
          this.clearState();
        }
        else {
          alert("An error has occurred while uploading. Please try again.");
          this.getHouseholds();
          this.clearState();
        }
      }).catch(err => {
        alert("An error has occurred while uploading. Please try again.");
        this.getHouseholds();
        this.clearState();
      });

    }

  }

  export default Households;