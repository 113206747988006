import React from 'react';
import axios from 'axios';
import loading from '../../Callback/loading.svg';

class AccountSummary extends React.Component {

  constructor(props) {
    super(props);

    const valObj = this.getValues(this.props.width);

    this.state = {
      data: null,
      labels: [],
      transactions: [],
      loading: true,
      col1: valObj.col1,
      col2: valObj.col2,
      graphHeight: valObj.graphHeight,
      dataState: {
        group: [{ field: 'tranCode', aggregates: [{ field: 'tradeAmount', aggregate: 'sum' }, { field: 'tranName', aggregate: 'max' }] }],
        sort: [{ field: 'tradeDate', dir: 'desc' }]
      },
      styleSettings: null,
      date: '',
    };

  }

  getValues = (width) => {
    let col1 = 0, col2 = 0, graphHeight = 0;
    switch (width) {
      case 4:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      case 6:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      case 12:
        col1 = 12; col2 = 12; graphHeight = 350;
        break;
      default:
    }
    return { col1: col1, col2: col2, graphHeight: graphHeight }
  }

  componentDidMount() {
    this.getStyleSettings(() => {
      this.props.callback((width) => {
        const CBvalObj = this.getValues(width);
        this.setState({
          graphHeight: CBvalObj.graphHeight,
          col1: CBvalObj.col1,
          col2: CBvalObj.col2
        })
      });
      if (this.props.onWidgetLoad) {
        this.props.onWidgetLoad();
        this.props.onAddCallback((result, err) => {
          this.setState({ loading: true }, () => {
            this.onSubmit();
          })
        })
      }
    })
  }

  getStyleSettings(cb) {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'acctPeriod1',
      'chartDefaultFees',
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        this.setState({ styleSettings: response.data.styleSettings }, () => {
          cb()
        })
      })
      .catch(err => {
        console.error(err);
      })
  }

  getPerfDates(cb) {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    var payload = {
      company: this.props.user.company,
      port: '',
      perfType: this.state.styleSettings.chartDefaultFees,
      email: this.props.user.user,
      household: this.props.selectedHousehold.household
    }
    console.log(payload)
    axios.post('api/perfIRRDates', payload, { headers }).then(response => {
      if (response.data.code === 200) {
        if (response.data.data.length > 0)
          this.setState({ date: response.data.data[0].fDate }, () => {
            cb()
          });
        else
          cb();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  onSubmit = () => {
    this.getPerfDates(() => {
      this.getTransactions();
    })
  }

  getTransactions() {
    this.setState({ loading: true });
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var port = this.props.selectedHousehold.household

    var date = new Date(this.state.date)
    // var totals = null

    axios.get(`api/accountSummarySnapshot`, {
      method: 'GET',
      params: {
        company: this.props.user.company,
        household: port,
        date: date,
        fees: this.state.styleSettings.chartDefaultFees,
        period: this.getDateCode(this.state.styleSettings.acctPeriod1)
      },
      headers: headers,
    })
      .then(response => {
        if (response.data.code === 200) {

          var data = response.data.data

          if (data.length > 0) {
            var beginDate = new Date(data[0].beginDate)
            var endDate = new Date(data[0].endDate)

            var totals = {
              beginMV: 0,
              additionsWithdrawals: 0,
              income: 0,
              realizedGains: 0,
              unrealizedGains: 0,
              fees: 0,
              endMV: 0
            }

            for (let i = 0; i < data.length; i++) {
              totals.beginMV = totals.beginMV + (data[i].beginAI + data[i].beginMV)
              totals.additionsWithdrawals = totals.additionsWithdrawals + (data[i].additions + data[i].withdrawals)
              totals.income = totals.income + (data[i].transfersIn + data[i].transfersOut)
              totals.realizedGains = totals.realizedGains + data[i].realizedGains
              totals.unrealizedGains = totals.unrealizedGains + data[i].unrealizedGains
              totals.fees = totals.fees + (data[i].manfees + data[i].manFeesClient + data[i].portFees + data[i].portFeesClient)
              totals.endMV = totals.endMV + (data[i].endAI + data[i].endMV)
            }

          }

          if (this.state.styleSettings.chartDefaultFees === 'Gross') {
            totals.additionsWithdrawals = totals.additionsWithdrawals + totals.fees
          }


          this.setState({ data: totals, loading: false, date: date, beginDate: beginDate, endDate: endDate });
        }
      }).catch(err => {
        this.setState({ data: null, loading: false });
        console.log(err);
      });
  }

  getDateCode(dateString) {
    switch (dateString) {
      case 'Month to Date':
        return 'mtd';
      case 'Quarter to Date':
        return 'qtd';
      case 'Previous Quarter':
        return 'pqtr'
      case 'Previous 3 Months':
        return '3mo'
      case 'Previous 6 Months':
        return '6mo'
      case 'Year to Date':
        return 'ytd'
      case 'Previous Year':
        return 'pyr'
      case 'Last 1 Year':
        return 'l1y'
      case 'Last 3 Years':
        return 'l3y'
      case 'Last 5 Years':
        return 'l5y'
      case 'Last 7 Years':
        return 'l7y'
      case 'Last 10 Years':
        return 'l10y'
      case 'Last 15 Years':
        return 'l15y'
      case 'Last 20 Years':
        return 'l20y'
      case 'Last 25 Years':
        return 'l25y'
      case 'Inception to Date':
        return 'itd'
      case 'Hidden':
        return '';
      default:
        return '';
    }

  }

  numberWithCommas(x) {
    return "$" + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  formatCurrency(val) {
    if (isNaN(val))
      val = 0;

    var formatted = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(val);
    return formatted;
  }

  render() {
    var data = this.state.data
    var padding = this.props.styleSettings ? ((this.props.styleSettings.themeFontFamily === 'Poppins') ? 'EvenLessPadding' : (this.props.styleSettings.themeFontFamily === 'OpenSans' ? 'LessPadding' : '')) : ''
    return (<div>
      <div className="widget">
        <div className={'widgetWidth' + this.state.col2}>
          {this.state.loading && <img className="loadingIcon" src={loading} alt="loading" />}
          {!this.state.loading ?
            <>
              {this.state.data ?
                <>
                  <div className='acctSummSnapshotHeader' ><label>{`${this.state.beginDate.toLocaleDateString("en-US", { timeZone: "UTC" })} to ${this.state.endDate.toLocaleDateString("en-US", { timeZone: "UTC" })}`}</label></div>
                  <div style={{ height: this.state.graphHeight + 'px' }}>
                    <table className='acctSummSnapshot'>
                      <tbody>
                        <tr>
                          <td className={'acctSummLabel' + padding}>
                            <label style={{ fontWeight: 'bold' }}>{'Beginning Value'}</label>
                          </td>
                          <td className='acctSummValue'>
                            <label style={{ fontWeight: 'bold' }}>{this.formatCurrency((Math.round(data.beginMV * 100) / 100).toFixed(2))}</label>
                          </td>
                        </tr>
                        <tr>
                          <td className={'acctSummLabelIndent' + padding}>
                            <label style={{ fontWeight: 'bold' }}>{'Additions/Withdrawals'}</label>
                          </td>
                          <td className='acctSummValue'>
                            <label style={{ fontWeight: 'bold' }}>{this.formatCurrency((Math.round(data.additionsWithdrawals * 100) / 100).toFixed(2))}</label>
                          </td>
                        </tr>
                        <tr>
                          <td className={'acctSummLabelIndent' + padding}>
                            <label style={{ fontWeight: 'bold' }}>{'Income'}</label>
                          </td>
                          <td className='acctSummValue'>
                            <label style={{ fontWeight: 'bold' }}>{this.formatCurrency((Math.round(data.income * 100) / 100).toFixed(2))}</label>
                          </td>
                        </tr>
                        <tr>
                          <td className={'acctSummLabelIndent' + padding}>
                            <label style={{ fontWeight: 'bold' }}>{'Realized Gains'}</label>
                          </td>
                          <td className='acctSummValue'>
                            <label style={{ fontWeight: 'bold' }}>{this.formatCurrency((Math.round(data.realizedGains * 100) / 100).toFixed(2))}</label>
                          </td>
                        </tr>
                        <tr>
                          <td className={'acctSummLabelIndent' + padding}>
                            <label style={{ fontWeight: 'bold' }}>{'Unrealized Gains'}</label>
                          </td>
                          <td className='acctSummValue'>
                            <label style={{ fontWeight: 'bold' }}>{this.formatCurrency((Math.round(data.unrealizedGains * 100) / 100).toFixed(2))}</label>
                          </td>
                        </tr>
                        {this.state.styleSettings.chartDefaultFees === 'Net' && <tr>
                          <td className={'acctSummLabelIndent' + padding}>
                            <label style={{ fontWeight: 'bold' }}>{'Total Fees'}</label>
                          </td>
                          <td className='acctSummValue'>
                            <label style={{ fontWeight: 'bold' }}>{this.formatCurrency((Math.round(data.fees * 100) / 100).toFixed(2))}</label>
                          </td>
                        </tr>}
                        <tr>
                          <td className={'acctSummLabelIndent' + padding}>
                            <label style={{ fontWeight: 'bold' }}>{'Ending Value'}</label>
                          </td>
                          <td className='acctSummValue'>
                            <label style={{ fontWeight: 'bold' }}>{this.formatCurrency((Math.round(data.endMV * 100) / 100).toFixed(2))}</label>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </>
                : <div className='widgetNoData'>No data available</div>
              }
            </>
            : <div className='widgetNoData'></div>}
        </div>
      </div>
    </div>
    );
  }
}

export default AccountSummary;