import React, { Component } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn, GridToolbar } from '@progress/kendo-react-grid';
//import {filterBy, orderBy} from '@progress/kendo-data-query';
import { parseDate } from '@telerik/kendo-intl';
import { process } from '@progress/kendo-data-query';
import axios from 'axios';
import GridLoading from '../Loaders/GridLoading';

class SsrsPackagerReview extends Component {

    constructor(props) {
        super(props);
        this.state = {
            packages: [],
            categories: [],
            result:[],
            platforms: [],
            reportsLoading: true,
            fileDownload: '',
            fileSelected: false,
            gridFilter: undefined,
            dataState:{skip:0, take:500, group:undefined, filter:undefined, sort:undefined},
            filterable: props.styleSettings.showFilters,
            sort: undefined,
            refreshText: '',
            preppingZip: false
        }

        this.download = this.download.bind(this);
        this.onDownloadClick = this.onDownloadClick.bind(this);
        this.refreshPackages = this.refreshPackages.bind(this);
        this.getFileTree = this.getFileTree.bind(this);
    }

    componentDidMount() {
        this.getFileTree();
    }

    getFileTree() {
        this.setState({ refreshText: 'Loading...' });
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios(`api/ssrsGetS3FileTree`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                var packages = []
                console.log(response)
                for (let i = 0; i < response.data.Contents.length; i++) {
                    //response.data.Contents[i].LastModified = this.getDate(response.data.Contents[i].LastModified, true);
                    //lastModified = this.getDate(response.data.Contents[i].LastModified, true);
                    if (response.data.Contents[i].Key !== 'packages/') {
                        packages.push({
                            Key: response.data.Contents[i].Key,
                            name: response.data.Contents[i].Key.split('/')[1],
                            LastModified: this.getDate(response.data.Contents[i].LastModified)
                        })
                    }
                }
                //var packages = response.data.Contents
                //packages.sort((a, b) => b.LastModified - a.LastModified)
                packages.sort((a, b) => b.LastModified - a.LastModified)
                var result = process(packages, this.state.dataState);
                this.setState({ packages: packages, result:result });
                this.setState({ refreshText: '' });
            })
            .catch(err => {
                console.error(err);
            })
    }

    changeDataState = (e)=>{
        var newData = process(this.state.packages, e.dataState);
        this.setState({dataState:e.dataState, result:newData});
      }

    getReports() {
        this.setState({ refreshText: 'Loading...' });
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios.get("api/webrep?company="+this.props.user.company, { headers }).then(response => {
            this.setState({ categories: [] });
            var cats = [];
            var newCat;
            var indexOfCat;
            var plats = [];
            var newPlat;
            var indexOfPlat;

            for (let i = 0; i < response.data.length; i++) {
                response.data[i].expiration = this.getDate(response.data[i].expiration, true);
                response.data[i].uploadDate = this.getDate(response.data[i].uploadDate);
                if (response.data[i].category !== undefined && response.data[i].category !== '') {

                    newCat = { text: response.data[i].category, path: response.data[i].category };
                    newPlat = { text: response.data[i].platform }

                    // eslint-disable-next-line
                    indexOfCat = cats.findIndex((cat) => {
                        return cat.text === newCat.text;
                    });

                    // eslint-disable-next-line
                    indexOfPlat = plats.findIndex((plat) => {
                        return plat.text === newPlat.text;
                    });

                    if (indexOfCat === -1) {
                        cats.push(newCat);
                    }
                    if (indexOfPlat === -1) {
                        plats.push(newPlat);
                    }
                }
            }
            this.setState({ categories: cats });
            this.setState({ platforms: plats });
            this.setState({ reports: response.data });
            this.setState({ refreshText: '' });
        }).catch(err => console.log(err));
    }

    getDate(sqlDate, isUTC) {
        var d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX");
        var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds());
        if (isUTC)
            return utc;
        else
            return d;
    }

    onLinkClick = (packageIn) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios(`api/ssrsGetS3File`, {
            method: 'GET',
            params: { key: packageIn.Key, name: packageIn.name, company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                window.location.assign(response.data)
            })
            .catch(err => {
                console.error(err);
            })

        return false;
    }

    selectionChange(event, gFiles) {
        event.dataItem.selected = !event.dataItem.selected;
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    headerSelectionChange(event, gFiles) {
        const checked = event.syntheticEvent.target.checked;
        gFiles.forEach(item => item.selected = checked);
        var selectedFiles = [];
        for (let i = 0; i < gFiles.length; i++) {
            if (gFiles[i].selected) {
                selectedFiles.push(gFiles[i]);
            }
        }
        this.setState({ fileDownload: selectedFiles });
        if (selectedFiles.length > 0) {
            this.setState({ fileSelected: true });
        }
        else {
            this.setState({ fileSelected: false });
        }
        this.forceUpdate();
    }

    getDownButtonText(files) {
        if (!files || files.length === 0) {
            return 'Select Package(s) to Download'
            //this.setState({downButtonText: 'Select Package(s) to Download'})
        }
        else if (Array.isArray(files) && files.length > 1) {
            return 'Download ' + files.length + ' packages';
            //this.setState({downButtonText: 'Download ' + files.length + ' packages'})
        }
        else if (Array.isArray(files) && files.length === 1) {
            return 'Download ' + files[0].name;
            //this.setState({downButtonText: 'Download ' + files[0].name})
        }
        else {
            return 'Download ' + files.name;
            //this.setState({downButtonText: 'Download ' + files.name})
        }
    }

    download() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var payload = { "files": [], "company": this.props.user.company };
        if (Array.isArray(this.state.fileDownload) && this.state.fileDownload.length > 1) {
            this.setState({preppingZip: true })
            for (let i = 0; i < this.state.fileDownload.length; i++) {
                console.log(this.state.fileDownload[i])
                payload.files.push({
                    "path": this.state.fileDownload[i].Key,
                    "name": this.state.fileDownload[i].name
                });
            }
            console.log(payload)
            axios
                .post("api/downloadzip", payload, { headers })
                .then((response) => {
                    console.log(response)
                    window.open("api/downloadzip?user=" + this.props.user.name);
                    this.setState({preppingZip: false })
                })
                .catch((e) => {
                    console.error(e);
                    this.setState({preppingZip: false })
                });
        }
        else if (Array.isArray(this.state.fileDownload)) {
            axios(`api/ssrsGetS3File`, {
                method: 'GET',
                params: { key: this.state.fileDownload[0].Key, company: this.props.user.company, name: this.state.fileDownload[0].name},
                headers: headers,
            })
                .then(response => {
                    window.location.assign(response.data)
                })
                .catch(err => {
                    console.error(err);
                    return false;
                })
        }
        else {
            axios(`api/ssrsGetS3File`, {
                method: 'GET',
                params: { key: this.state.fileDownload[0].Key, company: this.props.user.company, name: this.state.fileDownload[0].name},
                headers: headers,
            })
                .then(response => {
                    console.log(response.data)
                    window.location.assign(response.data)
                })
                .catch(err => {
                    console.error(err);
                    return false;
                })
        }

    }

    /*
        download() {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = { "files": [], "company": this.props.user.company };
            if (Array.isArray(this.state.fileDownload) && this.state.fileDownload.length > 1) {
                for (let i = 0; i < this.state.fileDownload.length; i++) {
                    payload.files.push(this.state.fileDownload[i].Key);
                }
                axios
                    .post("api/ssrsDownloadZipFile", payload, { headers })
                    .then((response) => {
                        console.log(response)
                        window.location.assign("api/ssrsDownloadZipFile?user=" + this.props.user.name);
                    })
                    .catch((e) => {
                        console.error(e);
                    });
            }
            else if (Array.isArray(this.state.fileDownload)) {
                axios(`api/ssrsGetS3File`, {
                    method: 'GET',
                    params: { key: this.state.fileDownload[0].Key, company: this.props.user.company },
                    headers: headers,
                })
                    .then(response => {
                        window.location.assign(response.data)
                    })
                    .catch(err => {
                        console.error(err);
                        return false;
                    })
            }
            else {
                axios(`api/ssrsGetS3File`, {
                    method: 'GET',
                    params: { key: this.state.fileDownload[0].Key, company: this.props.user.company },
                    headers: headers,
                })
                    .then(response => {
                        console.log(response.data)
                        window.location.assign(response.data)
                    })
                    .catch(err => {
                        console.error(err);
                        return false;
                    })
            }
    
        }
    */
    onDelete = (e) => {
        var files = [];
        var packageToDelete;
        var addFile = {};
        for (let i = 0; i < this.state.fileDownload.length; i++) {
            packageToDelete = this.state.fileDownload[i];
            addFile.Key = packageToDelete.Key;
            files.push(addFile);
            addFile = {};
        }

        var confirm = window.confirm("Are you sure you want to delete these file(s)?");

        if (confirm) {
            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
            var payload = {
                files: files,
                company: this.props.user.company,
            };
            axios.post('api/ssrsDeletePackageOutput', payload, { headers }).then(response => {
                if (response.data.code === 200) {
                    this.setState({ fileDownload: '' });
                    this.setState({ fileSelected: false });
                    this.refreshPackages();
                }
                else {
                    alert("Error: Packages(s) could not be deleted.");
                    this.setState({ fileDownload: '' });
                    this.setState({ fileSelected: false });
                    this.refreshPackages();
                }
            }).catch(e => console.log(e));
        }
    }

    onDownloadClick = (e) => {
        this.download();
    }

    onFilterChange = (e) => {
        this.setState({ gridFilter: e.filter });
    }

    refreshPackages() {
        this.setState({ fileDownload: '' });
        this.setState({ fileSelected: false });
        this.getFileTree();
    }

    render() {
        return (
            <>
                {//this.props.uploadUser.approved !== false &&
                    <div className="packageReview">
                        <Grid data={this.state.result}
                            selectedField='selected'
                            pageable={true}
                            onSelectionChange={(e) => this.selectionChange(e, this.state.packages)}
                            onRowClick={(e) => this.selectionChange(e, this.state.packages)}
                            onDataStateChange={this.changeDataState}
                            onHeaderSelectionChange={(e) => this.headerSelectionChange(e, this.state.packages)}
                            filterable={this.state.filterable}
                            resizable
                            {...this.state.dataState}
                        >
                            <GridToolbar>
                                <Button icon='refresh' title='Refresh' onClick={this.refreshPackages}>{this.state.refreshText}</Button>
                                <Button icon='filter' title='Filter' onClick={(e) => this.setState({ filterable: !this.state.filterable })}>Filter</Button>
                                {this.state.preppingZip 
                                    ? <Button className="docButton" disabled={true} icon="download" >{'Preparing Files...'}</Button>
                                    : <Button className="docButton" disabled={!this.state.fileSelected} icon="download" onClick={this.onDownloadClick}>{this.getDownButtonText(this.state.fileDownload)}</Button>
                                }
                                <Button className="docButton" disabled={!this.state.fileSelected} onClick={this.onDelete} icon='delete'>Delete</Button>
                            </GridToolbar>
                            <GridColumn width='50px' field="selected" filterable={false} headerClassName='gridHeader' headerSelectionValue={this.state.packages.findIndex(dataItem => dataItem.selected === false) === -1} />
                            <GridColumn
                                field="Key"
                                title="Package"
                                headerClassName='gridHeader'
                                cell={(props) =>
                                    <td>
                                        <button className='link-button' style={{ color: '#656565' }} onClick={(event) => this.onLinkClick(props.dataItem)}>{props.dataItem.name}</button>
                                    </td>
                                } />
                            <GridColumn width='220px' field="LastModified" headerClassName='gridHeader' filter="date" format="{0:d}" title="Uploaded:" />
                        </Grid>
                        {this.state.refreshText && <GridLoading gridClass='packageReview' />}
                    </div>
                }

            </>
        );
    }
}

export default SsrsPackagerReview;