import React, { Component } from "react";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import CreateStagedBulkEmailSet from './components/CreateStagedBulkEmailSet';
import StagedEmailSets from './components/StagedEmailSets';
import StagedEmailRequestsStatus from './components/StagedEmailRequestsStatus';

class BulkEmailManagement extends Component {

  constructor(props) {
    super(props);
    this.state = {
      tabSelected:0
    }
  }

  handleSelect = (e) => {
    this.setState({ tabSelected: e.selected });
  }

  render() {
    return(
        <div className="bulkEmailManagement">
            <TabStrip selected={this.state.tabSelected} onSelect={this.handleSelect}>
                <TabStripTab title='Create Staged Bulk Email Set'>
                    <CreateStagedBulkEmailSet user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title='Bulk Email Sets'>
                  <StagedEmailSets user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>
                <TabStripTab title='Bulk Email Requests'>
                  <StagedEmailRequestsStatus user={this.props.user} idToken={this.props.idToken}/>
                </TabStripTab>

            </TabStrip>
        </div>
    );
      
  }

}

export default BulkEmailManagement;