import React, { Component } from "react";
import { Upload } from '@progress/kendo-react-upload';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import axios from 'axios';


class BulkUploadForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: this.props.user,
            files: [],
            altFiles: '',
            title: '',
            expiration: '',
            initialLoad: true,
            householdGroupCheckbox: false,
            groups: [],
            groupsToAdd: [],



        }


        this.onAdd = this.onAdd.bind(this);
        this.onRemove = this.onRemove.bind(this);
        this.clearState = this.clearState.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getGroups = this.getGroups.bind(this);
        this.onHouseholdGroupsChange = this.onHouseholdGroupsChange.bind(this)
        this.onProgress = this.onProgress.bind(this);

    }

    componentDidMount() {
        this.getGroups();
    }

    useEffect(){
        window.addEventListener('beforeunload', this.displayAlertOnClose);
        return () => {
          window.removeEventListener('beforeunload', this.displayAlertOnClose);
        }
      }
    
      displayAlertOnClose = (event) => {   
        event.preventDefault();
        event.returnValue = "";
        return "";
      }

    getGroups() {
        //this.setState({ loading: true });
        var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        axios.get("api/householdgroups?company=" + this.props.company, { headers }).then(response => {
            if (response.data.code === 200) {
                console.log(response.data)
                this.setState({ groups: response.data.groups, loading: false });
            }
            else {
                alert("An error occured while fetching the user groups.");
                this.setState({ loading: false });
            }
        }).catch(error => console.log(error));
    }

    onAdd(event, isAlt) {
        this.setState({
            files: event.newState
        });
    }

    onRemove(event, isAlt) {
        this.setState({
            files: event.newState
        });
    }



    onBeforeUpload = (event) => {
        //define some additional fields to pass though Upload object
        event.additionalData.username = this.props.user;
        event.additionalData.company = this.props.company;
        event.additionalData.uploadedBy = this.props.uploadUser.user;
        event.additionalData.type = 'bulkStatementZIPFile';
        event.additionalData.notifyUsers = false;//just set for now
        event.additionalData.description = 'bulkstatementfile';
        event.additionalData.uploadedBy = this.props.user;;
        event.additionalData.useHouseholdFolders = false;
        this.setState({ initialLoad: false });
        this.useEffect();

    }

    onBeforeHouseholdGroupUpload = (event) => {
        //get the list of household groups
        var householdGroupList="";
        
        

        if (this.state.groupsToAdd.length > 0)
        {
            var groupList = [];
            this.state.groupsToAdd.forEach(group => {
                groupList.push(group.name.toString());
              });
             householdGroupList = groupList.join('[]');
        }
        householdGroupList='[]' + householdGroupList + '[]';

        //define some additional fields to pass though Upload object
        event.additionalData.username = this.props.user;
        event.additionalData.company = this.props.company;
        event.additionalData.uploadedBy = this.props.uploadUser.user;
        event.additionalData.groups = householdGroupList;
        event.additionalData.type = 'householdGroupFiles';
        event.additionalData.notifyUsers = false;//just set for now
        event.additionalData.description = 'householdgroupfiles';
        event.additionalData.uploadedBy = this.props.user;;
        event.additionalData.useHouseholdFolders = false;
        alert("File is being uploaded.");
        this.setState({ initialLoad: false });

    }


    onStatusChange(event) {
        if (event.response !== undefined) {
            if (event.response.response.code === "200") {
                //this.props.uploadMethod();
                alert("File is currently processing. You will receive an email notification when complete.");
                this.setState({ files: [] });
                this.setState({ initialLoad: true });
                window.removeEventListener('beforeunload', this.displayAlertOnClose);
            }
            else {
                this.setState({ initialLoad: true });
            }
        }
        else if (event.response === undefined) {
            //this.props.uploadMethod();
            // this.setState({ files: [] });
            //alert("File(s) were successfully uploaded!");
            //this.setState({ initialLoad:true });
        }
        else if (event.response !== undefined) {
            alert("Files could not be uploaded successfully. Please try again.")
            this.setState({ initialLoad: true });
        }


    }



    clearState = (e) => {
        this.setState({
            title: '',
            altFiles: '',
            files: [],
            expiration: '',
            initialLoad: true,
            groupsToAdd: []
        });

    }

    handleChange = (event) => {
        this.setState({ 
            householdGroupCheckbox: !this.state.householdGroupCheckbox,
            files:[],
            groupsToAdd: [],
        });
    }


    onHouseholdGroupsChange = (e)=>{
        this.setState({
            groupsToAdd:e.target.value,
        });

      }

    onProgress(event) {
        this.setState({
            files: event.newState
        });
    }


    render() {
        return (

            <div className="bulkDocUpload">


                <div className="content">
                    {this.state.groups.length > 0 &&
                        <label>
                            <input type="checkbox" checked={this.state.householdGroupCheckbox} onChange={this.handleChange} label={'Bulk Upload to Household Groups'} />Upload to a specific list of household groups
                        </label>
                    }
                    {!this.state.householdGroupCheckbox && <>
                        <h3>Instructions:
                        <br /> Upload a zip file containing files to be loaded into the Staged Documents area.
                        </h3>
                        You will be emailed when the ZIP file has been processed and is ready for review in the "Staged Documents" tab.
                        <br /> <br />
                        <div>Please select a .zip file to upload:</div>

                        {/* {this.state.initialLoad === true && */}
                            <div className="bulkDocUpload">
                            <Upload batch={true}
                                autoUpload={false}
                                multiple={false}
                                files={this.state.files}
                                onAdd={(e) => this.onAdd(e)}
                                onRemove={(e) => this.onRemove(e)}
                                onBeforeUpload={this.onBeforeUpload}
                                onStatusChange={(e) => { this.onStatusChange(e) }}
                                onProgress={(e) => this.onProgress(e)}
                                saveUrl={"api/fileupload?company="+this.props.company}
                                saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                                restrictions={{
                                    allowedExtensions: ['.zip']
                                }}
                            />
                            </div>
                        {/* } */}
                    </>}
                    {this.state.householdGroupCheckbox && <>

                        <h3>Instructions:
                        <br />Select the Household Group(s), select the file and then click upload.
                        </h3>
                        You will receive an email when the file has been processed and is ready for review on the "Staged Documents" tab.
                        <br /> <br />
                        Select Household Group(s):
                        <MultiSelect
                            data={this.state.groups}
                            textField="name"
                            onChange={this.onHouseholdGroupsChange}
                        />
                        <br /> <br />
                        <div>Select a file to upload:</div>

                        {/* {this.state.initialLoad === true && */}

                            <Upload batch={true}
                                autoUpload={false}
                                showActionButtons={this.state.files.length > 0 && this.state.groupsToAdd.length > 0}
                                multiple={true}
                                files={this.state.files}
                                onAdd={(e) => this.onAdd(e)}
                                onRemove={(e) => this.onRemove(e)}
                                onBeforeUpload={this.onBeforeHouseholdGroupUpload}
                                onStatusChange={(e) => { this.onStatusChange(e) }}
                                onProgress={(e) => this.onProgress(e)}
                                saveUrl={"api/fileupload?company="+this.props.company}
                                //saveUrl=""
                                saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }}
                            />
                        {/* } */}
                    </>}


                    {this.state.initialLoad === false &&
                        <h3 style={{ color: 'red' }} >
                            <br /> File is being uploaded, please wait for it to complete.
                    <br /> Larger ZIP files may take a few minutes to upload.
                    </h3>
                    }

                </div>
            </div>


        );


    };

}

export default BulkUploadForm;