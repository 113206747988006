import React, { Component } from "react";
import Popup from "reactjs-popup";
import { Button } from '@progress/kendo-react-buttons';
import { Input, Switch } from '@progress/kendo-react-inputs';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { Upload } from '@progress/kendo-react-upload';
import ViewsList from '../../UserList/components/ViewsList';
import axios from 'axios';

class InviteForm extends Component {

  constructor(props) {
    super(props);



    var portTabViews = [{ name: "Account Summary", page: "acctSumm" },
    { name: "Account Overview", page: "equityOver" },
    { name: "Performance Overview", page: "perfOver" },
    { name: "Holdings", page: "holds" },
    //{name:"Holdings Summary", page:"holdSumm"}, 
    { name: "Fixed Income Holdings", page: "fiHolds" },
    //{name:"Market Value", page:"markVal"},
    //{name:"Group Appraisal", page:"grpApp"},
    //{name:"Historical Market Value", page:"histMarkVal"},
    { name: "Transaction Summary", page: "tranSumm" },
    //{name:"Performance Growth", page:"growth"},
    //{name:"Account AUM", page:"aum"},
    { name: "Realized Gains", page: "rgain" }];

    if (this.props.role === 'admin') {
      this.state = {
        roles: [],
        inviteEmail: '',
        invitePhone: '',
        inviteName: '',
        inviteComp: '',
        roleSelected: '',
        roleSelectedGUID: '',
        default: '-Select a role-',
        files: [],
        users: [],
        uploadText: "Send Invite(s)",
        uploading: false,
        viewList: [],
        portTabs: portTabViews,
        defaultTabs: this.props.compSettings.defaultPortTabs,
        defaultViews: this.props.compSettings.defaultViews,
        originalViews: this.props.compSettings.defaultViews,
        editViews: false,
        editTabs: false,
        useCompDefTabs: true,
        templates: [],
        tempSelected: '',
        tempDefault: { title: 'Default Template' },
        householdsAll: [],
        selectedHousehold: [],
        enableMFA: null,
        userEnableMFA: false
      }
    }
    else {
      this.state = {
        roles: [],
        inviteName: '',
        inviteComp: '',
        inviteEmail: '',
        invitePhone: '',
        roleSelected: '',
        roleSelectedGUID: '',
        default: '-Select a role-',
        //viewList:[{name:this.props.compSettings.docTab1, page:"docTab1"}, {name:this.props.compSettings.docTab2, page:"docTab2"}, {name:this.props.compSettings.docTab3, page:"docTab3"}, {name:this.props.compSettings.docTab4, page:"docTab4"}, {name:this.props.compSettings.docTab5, page:"docTab5"}, {name:this.props.compSettings.docTab6, page:"docTab6"}, {name:this.props.compSettings.docTab7, page:"docTab7"}],       
        viewList: [],
        defaultViews: this.props.compSettings.defaultViews,
        portTabs: portTabViews,
        defaultTabs: this.props.compSettings.defaultPortTabs,
        editViews: false,
        editTabs: false,
        useCompDefTabs: true,
        templates: [],
        tempSelected: '',
        tempDefault: { title: 'Default Template' },
        enableMFA: null,
        userEnableMFA: false,
        styleSettings: {
          docTab1: null,
          docTab2: null,
          docTab3: null,
          docTab4: null,
          docTab5: null,
          docTab6: null,
          docTab7: null,
        }
      }
    }

    this.onViewsChange = this.onViewsChange.bind(this);
    this.onTabsChange = this.onTabsChange.bind(this);
    this.sendInvites = this.sendInvites.bind(this);
    this.clearState = this.clearState.bind(this);
    this.getMailTemplates = this.getMailTemplates.bind(this);
    this.getHouseholdsAll = this.getHouseholdsAll.bind(this);
    this.getSuperAdminSettings = this.getSuperAdminSettings.bind(this);
  }

  componentDidMount() {
    this.getMailTemplates();
    this.getHouseholdsAll();
    this.getSuperAdminSettings();
    this.getStyleSettings();
  }



  /*
    getMailTemplates(){
      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
      axios.get("api/mailtemplate?company="+this.props.company, {headers}).then(response=>{          
        this.setState({templates:response.data});
      }).catch(err=>console.log(err));
    }
    */

  getStyleSettings() {
    var headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    var styleNames = [
      'docTab1',
      'docTab2',
      'docTab3',
      'docTab4',
      'docTab5',
      'docTab6',
      'docTab7',
    ]

    axios(`api/getStyleSettings`, {
      method: 'GET',
      params: { company: this.props.user.company, styleNames: styleNames },
      headers: headers
    })
      .then(response => {
        var viewList = []
        if (this.props.role === 'admin') {
          viewList = [{ name: response.data.styleSettings.docTab1, page: "docTab1" },
          { name: response.data.styleSettings.docTab2, page: "docTab2" },
          { name: response.data.styleSettings.docTab3, page: "docTab3" },
          { name: response.data.styleSettings.docTab4, page: "docTab4" },
          { name: response.data.styleSettings.docTab5, page: "docTab5" },
          { name: response.data.styleSettings.docTab7, page: "docTab7" },
          { name: 'Portfolio', page: "portfolio" }];

          if (this.props.company === 'cssi')
            viewList.push({ name: 'Web Reports', page: "docTab6" });
        }
        else {
          viewList = [
            { name: response.data.styleSettings.docTab1, page: "docTab1" },
            { name: response.data.styleSettings.docTab2, page: "docTab2" },
            { name: response.data.styleSettings.docTab3, page: "docTab3" },
            { name: response.data.styleSettings.docTab4, page: "docTab4" },
            { name: response.data.styleSettings.docTab5, page: "docTab5" },
            { name: 'Web Reports', page: "docTab6" },
            { name: response.data.styleSettings.docTab7, page: "docTab7" }]
        }


        this.setState({
          styleSettings: response.data.styleSettings,
          viewList: viewList
        })
      })
      .catch(err => {
        console.error(err);
      })
  }

  getMailTemplates() {
    var defaultTemplate = "Portal Invite";

    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios.get("api/mailtemplate?company=" + this.props.company, { headers }).then(response => {
      var filtered = response.data.filter(
        function (e) {
          return e.title !== defaultTemplate;
        }
      );
      this.setState({ templates: filtered });
      if (filtered.length !== response.data.length) {
        this.setState({ tempDefault: { title: defaultTemplate } })
      }
      else {
        this.setState({ tempDefault: { title: "Default Template" } })
      }
    }).catch(err => console.log(err));

  }

  getHouseholdsAll() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    axios.get("api/households?company=" + this.props.company, { headers }).then(response => {
      if (response.data.code === 200) {
        this.setState({ householdsAll: response.data.households });
      }
      else {
        alert("An error occured while fetching the Household list.");
        this.setState({ householdsAll: [] });
      }
    }).catch(error => console.log(error));

  }

  onRoleChange = (e) => {
    if (e.target.value.text === '-Select a role-') {
      this.setState({ roleSelected: '', roleSelectedGUID: '' });
    }
    else {
      this.setState({ roleSelected: String(e.target.value.text).toLowerCase(), roleSelectedGUID: e.target.value.id });
    }
  }

  onTemplateChange = (e) => {
    if (e.target.value.title === '-Select a role-') {
      this.setState({ tempSelected: '' });
    }
    else {
      this.setState({ tempSelected: e.target.value.title });
    }
  }

  onEmailInput = (e) => {
    if (emailRegex.test(e.target.value)) {
      this.setState({ inviteEmail: e.target.value });
    }
    else {
      this.setState({ inviteEmail: '' });
    }

  }

  /*
  onPhoneInput = (e) =>{
    if(phoneRegex.test(e.target.value)){
      this.setState({invitePhone:e.target.value});
    }
    else{
      this.setState({invitePhone:''});
    }
    
  }
  */

  onNameInput = (e) => {
    this.setState({ inviteName: e.target.value });
  }

  onCompInput = (e) => {
    this.setState({ inviteComp: e.target.value });
  }

  onAdd = (e) => {
    this.setState({ files: e.newState });
  }

  onRemove = (e) => {
    this.setState({ files: e.newState });
  }

  onStatusChange = (e) => {
    if (e.response.response.code === 200) {
      e.newState[0].progress = 100;
      e.newState[0].status = 4;
      this.setState({ users: e.response.response.users });
    }
    else {
      e.newState[0].progress = 0;
      e.newState[0].status = 0;
      e.newState[0].errorLine = e.response.response.errorLine;
      e.newState[0].error = e.response.response.error;
    }
    this.setState({ files: e.newState });
  }

  onBeforeUpload = (e) => {
    e.additionalData.company = this.props.company;
    e.additionalData.EnableMFA = this.state.enableMFA;
  }

  onUserChange = (e) => {
    this.setState({ selectedHousehold: e.target.value });
  }

  onViewsChange(view, e) {
    var newViews = this.state.defaultViews.slice();
    var indView = newViews.indexOf(view.page);

    if (indView === -1)
      newViews.push(view.page);
    else
      newViews.splice(indView, 1);

    this.setState({ defaultViews: newViews });
  }

  onTabsChange(tab, e) {
    var newTabs = this.state.defaultTabs.slice();
    var indTab = newTabs.indexOf(tab.page);

    if (indTab === -1)
      newTabs.push(tab.page);
    else
      newTabs.splice(indTab, 1);

    this.setState({ defaultTabs: newTabs });
  }

  useDefault = (e) => {
    var checked = !this.state.useCompDefTabs;
    if (checked)
      this.setState({ useCompDefTabs: checked, editTabs: false });
    else
      this.setState({ useCompDefTabs: checked });
  }

  sendInvites(callback) {
    var users_with_view;
    if (emailRegex.test(this.state.inviteEmail) && this.state.roleSelected !== '')//normal user invite
    {
      var user = { email: this.state.inviteEmail, useMFA: this.state.userEnableMFA, nickname: this.state.inviteName, searchCompany: this.state.inviteComp, role: this.state.roleSelected, roleGUID: this.state.roleSelectedGUID, access: this.state.defaultViews };
      if (!this.state.useCompDefTabs) {
        user.access = user.access.concat(this.state.defaultTabs);
      }

      if (this.state.useCompDefTabs) {
        user.access.push('defaultTabs');
      }

      this.props.inviteUser(user, this.state.tempSelected, this.state.enableMFA, (err) => {
        callback(err);
      });
    }
    else if (this.state.users.length > 0) {//bulk invite

      //update users defaultViews
      users_with_view = [...this.state.users];
      users_with_view.forEach(indvuser => {
        indvuser.access = this.state.defaultViews
        if (this.state.useCompDefTabs) {
          indvuser.access = indvuser.access.concat('defaultTabs');
        }
        else {
          indvuser.access = indvuser.access.concat(this.state.defaultTabs);
        }
      });
      this.setState({ users: users_with_view });

      //create the users
      this.props.inviteUser(users_with_view, this.state.tempSelected, this.state.enableMFA, (err) => {
        callback(err);
      });
    }
    else if (this.props.unmappedUsers && this.props.unmappedUserList && this.props.unmappedUserList.length > 0) {//bulk unmappedusers invite

      //update users defaultViews
      var unmappedUserList = [...this.props.unmappedUserList];
      users_with_view = []

      unmappedUserList.forEach(indvuser => {

        var newUser = {}
        const roleIndex =  this.props.roles.findIndex(role => String(role.text).toLowerCase() === String(indvuser.role).toLowerCase());
        newUser.email = indvuser.email
        newUser.role = roleIndex > -1 ? this.props.roles[roleIndex].text : '';
        newUser.roleGUID = roleIndex > -1 ? this.props.roles[roleIndex].id : 0;
        newUser.nickname = indvuser.name
        newUser.useMFA = indvuser.useMFA

        newUser.access = this.state.defaultViews
        if (this.state.useCompDefTabs) {
          newUser.access = newUser.access.concat('defaultTabs');
        }
        else {
          newUser.access = newUser.access.concat(this.state.defaultTabs);
        }

        users_with_view.push(newUser)
      });
      this.setState({ users: users_with_view });

      //create the users
      this.props.inviteUser(users_with_view, this.state.tempSelected, this.state.enableMFA, (err) => {
        callback(err);
      });
    }
    else if (this.state.roleSelected === '') {
      alert("You must select a role for this user");
      callback("ERR");
    }
    else {
      alert("Invalid Email Address");
      callback("ERR");
    }
  }

  mapHouseholds(callback) {
    if (this.state.selectedHousehold.length > 0) {
      const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

      var payload = {
        company: this.props.company,
        email: this.state.inviteEmail,
        household: this.state.selectedHousehold
      }

      axios.post('api/createHouseholdUserMapping', payload, { headers }).then(response => {
        callback()
      }).catch(err => {
        console.log(err);
        callback(err)
      });
    }
    else {
      callback()
    }
  }

  clearState(file) {
    // var defaultViews = this.props.compSettings.defaultViews
    // var defaultIndex = this.props.compSettings.defaultViews.indexOf('defaultTabs');

    // if (defaultIndex > -1)
    //   defaultViews = this.props.compSettings.defaultViews.splice(defaultIndex, 1);


    if (file) {
      this.setState({
        files: [],
        users: [],
        uploadText: "Send Invite(s)",
        uploading: false,
        tempSelected: '',
      });
    }
    else {
      this.setState({
        inviteEmail: '',
        invitePhone: '',
        roleSelected: '',
        roleSelectedGUID: '',
        files: [],
        users: [],
        uploadText: "Send Invite(s)",
        uploading: false,
        tempSelected: '',
        selectedHousehold: [],
        useCompDefTabs: true,
        defaultTabs: this.props.compSettings.defaultPortTabs,
        defaultViews: this.state.originalViews,
        editViews: false,
        editTabs: false,
        userEnableMFA: false
      });

      if (this.props.unmappedUsers){
        this.props.unmappedChangeMFA(true);
      }
    }
  }

  renderFile = (props) => {
    const { files } = props;

    return (
      <ul>
        {
          files.map(file => {
            return file.error ?
              <>
                <div style={{ color: 'red' }} key={file.name}>
                  <div>{file.name}</div>
                  <div>Error on line: {file.errorLine}</div>
                  <div>Error: {file.error}</div>
                </div>
              </>
              :
              <div>
                <div style={{ color: 'green' }} key={file.name}>
                  <div>{file.name}</div>
                  <div>{this.state.users.length} users found</div>
                </div>
                <Button style={{ textAlign: 'right', marginLeft: '10px' }} disabled={this.state.uploading} onClick={e => this.clearState("file")}>Cancel</Button>
              </div>

          })
        }
      </ul>);
  }

  getSuperAdminSettings() {
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
    axios(`api/superAdminSettings`, {
      method: 'GET',
      params: { company: this.props.user.company },
      headers: headers
    })
      .then(response => {
        response.data.data.forEach(setting => {
          if (setting.settingName === 'enableMFA') {
            this.setState({ enableMFA: setting.settingValue.toLowerCase() === 'true' ? true : false })
          }
        })

      })
      .catch(err => console.log(err));
  }

  handleChange(e) {

    if (e.target.props.name === 'userEnableMFA' && this.props.unmappedUsers){
      this.props.unmappedChangeMFA();
    }

    this.setState({ [e.target.props.name]: e.target.value })
    //this.forceUpdate();
  }

  render() {
    return (
      <Popup closeOnDocumentClick={false} trigger={this.props.button} onClose={(e) => { this.clearState() }} overlayStyle={{ zIndex: 5 }} modal>
        {close => (
          <div className="modal">

            <Button className="close" icon="close" onClick={close} />


            <div className="header" align="left">
              {this.props.unmappedUsers ? 'Invite Unmapped Users' : 'Invite User'}
            </div>

            <div className="content">
              {this.props.unmappedUsers && <><label style={{color: 'red'}}>{this.props.unmappedUserList.length} user{this.props.unmappedUserList.length === 1 ? '' : 's'} will be invited{this.state.userEnableMFA ? ' with MFA': ''}. </label><br/><br/></>}
              <label>Email Template: </label>
              <DropDownList
                data={this.state.templates}
                textField='title'
                defaultItem={this.state.tempDefault}
                onChange={this.onTemplateChange}
                popupSettings={style} />

                <><br /><br /><label style={{ paddingLeft: '30px', fontStyle: 'italic' }}>The company default views will be used.</label></>

                {this.state.enableMFA &&
                  <>
                    <br />
                    <br />
                    <label>Add Multi-Factor Authentication</label><Switch
                      name='userEnableMFA'
                      checked={this.state.userEnableMFA}
                      value={this.state.userEnableMFA}
                      onChange={(e) => this.handleChange(e)}
                    />
                    <br/>
                    <label style={{fontSize: '75%', fontStyle: 'italic', paddingLeft: '10px'}}>If checked, each user will be invited with MFA turned on.</label>
                  </>
                }

              {!this.props.unmappedUsers &&
                <>
                  <h3 style={{marginBottom: '0px'}}>Individual:</h3>

                  <table className='inviteTable'>
                    <tbody>
                      <tr>
                        <td style={{paddingTop: '0px'}}>
                          <Input name='name' style={{ width: "100%", marginBottom: '0px' }} label="Name" onBlur={this.onNameInput} disabled={this.state.files.length > 0} />
                          <Input
                            type="text"
                            // className="emailInput"
                            name="email"
                            style={{ width: "100%", marginBottom: '0px' }}
                            label="Email"
                            onBlur={this.onEmailInput}
                            pattern={emailRegexString}
                            required={true}
                            validationMessage="Must enter a valid email address."
                            disabled={this.state.files.length > 0}
                          />
                          {this.state.enableMFA &&
                            <>
                              <br />
                              <br />
                              {/* <label>Enable Multi-Factor Authentication</label><Switch
                                name='userEnableMFA'
                                checked={this.state.userEnableMFA}
                                value={this.state.userEnableMFA}
                                onChange={(e) => this.handleChange(e)}
                              /> */}
                              {/* this.state.userEnableMFA &&
                            <Input
                              className="phoneInput"
                              name="phoneNum"
                              style={{ width: "100%", padding:'0px'}}
                              label="Phone (optional)"
                              onBlur = {this.onPhoneInput} 
                              type="tel"
                              pattern={'^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$'}
                              required={false}
                              validationMessage="Must enter a valid phone number."
                              disabled={this.state.files.length>0}
                            />
                          */}
                            </>
                          }

                          {this.props.company === 'cssi' && <Input name='comp' style={{ width: '100%' }} label="Company" onBlur={this.onCompInput} disabled={this.state.files.length > 0} />}
                        </td>
                        <td>
                          <DropDownList
                            data={this.props.roles}
                            textField="text"
                            dataItemKey="id"
                            defaultItem={{id:0, text: this.state.default}}
                            onChange={this.onRoleChange}
                            popupSettings={style}
                            disabled={this.state.files.length > 0}
                          />
                        </td>
                      </tr>
                      {this.state.roleSelected === 'user' &&
                        <tr>
                          <td>
                            <h3>Household(s) (optional):</h3>
                            <div>Use this field to associate the user to one or many households</div>
                            <MultiSelect
                              style={{ width: '100%' }}
                              data={this.state.householdsAll}
                              textField='household'
                              onChange={this.onUserChange}
                              filterable={true}
                              allowCustom={false}
                              onFilterChange={(e) => {
                                this.setState({ result: filterBy(this.state.householdsAll, e.filter) });
                              }}
                            />
                            <br />
                          </td>
                        </tr>
                      }
                      <tr>
                        <td>
                          <Button onClick={(e) => this.setState({ editViews: !this.state.editViews })}>Edit Views</Button>
                        </td>
                      </tr>
                      {this.state.editViews && <div style={{ maxHeight: '25vh', overflow: 'auto' }}><ViewsList data={this.state.viewList} views={this.state.defaultViews} onChange={this.onViewsChange} hidePermissions={true} /></div>}
                      <tr>
                        <td>
                          <div style={{ marginBottom: '10px' }}>
                            <input className='bigger' type="checkbox" checked={this.state.useCompDefTabs} onChange={this.useDefault} />
                            <label style={{ marginLeft: '5px' }}>Use default portfolio tabs?</label>
                          </div>
                          <Button onClick={(e) => this.setState({ editTabs: !this.state.editTabs })} disabled={this.state.useCompDefTabs}>Edit Portfolio Tabs</Button>
                        </td>
                      </tr>
                      {this.state.editTabs && <div style={{ maxHeight: '25vh', overflow: 'auto' }}><ViewsList data={this.state.portTabs} views={this.state.defaultTabs} onChange={this.onTabsChange} hidePermissions={true} /></div>}
                    </tbody>
                  </table>

                  <h3>Bulk:</h3>

                  {this.state.enableMFA &&
                    <div>Upload a tab-delimited text file with Email Address in the first column, the user's Full Name in the second column, the user's role in the third column and the users MFA setting (yes/no) in the forth column. The company default views will be used.</div>
                  }
                  {!this.state.enableMFA &&
                    <div>Upload a tab-delimited text file with Email Address in the first column, the user's Full Name in the second column and the user's role in the third column. The company default views will be used.</div>
                  }


                  <Upload autoUpload={true}
                    multiple={false}
                    files={this.state.files}
                    listItemUI={this.renderFile}
                    onAdd={this.onAdd}
                    onRemove={this.onRemove}
                    onStatusChange={this.onStatusChange}
                    onBeforeUpload={e => this.onBeforeUpload(e, true)}
                    saveUrl={'api/parseUserFile?company='+this.props.company}
                    saveHeaders={{ 'authorization': 'Bearer '.concat(this.props.idToken) }} />
                </>}

            </div>

            <div className="actions">

              <Button
                className="button"
                onClick={() => {
                  close()
                }}
              >
                Close
              </Button>

              <Button
                disabled={this.state.uploadText === "Sending..."}
                className="upload"
                onClick={(event) => {
                  this.setState({ uploadText: "Sending...", uploading: true });
                  this.sendInvites((err) => {
                    if (!err) {
                      this.mapHouseholds((err) => {
                        if (!err) {
                          this.clearState();
                          close();
                        }
                        else {
                          alert("User Invite was successful but one or more households were not mapped")
                          this.setState({ uploadText: "Send Invite(s)", uploading: false });
                        }
                      })
                    }
                    else {
                      this.setState({ uploadText: "Send Invite(s)", uploading: false });
                    }
                  });
                }}
              >
                {this.state.uploadText}
              </Button>

            </div>

          </div>
        )}
      </Popup>

    );
  };

}

// eslint-disable-next-line
const emailRegexString = process.env.REACT_APP_EMAIL_REGEX;
const emailRegex= RegExp(process.env.REACT_APP_EMAIL_REGEX);

//const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

const style = {
  className: "top",
};

export default InviteForm;