import React, { Component } from "react";
import { Button } from '@progress/kendo-react-buttons';
import {Grid, GridColumn, GridToolbar} from '@progress/kendo-react-grid';
import axios from 'axios';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import GridLoading from '../../Loaders/GridLoading';


class StagedActionRequestsStatus extends Component {

  constructor(props) {
    super(props);
    this.state = {
        
        requests:[],
        result:[],
        //dataState:{skip:0, take:25, sort:[{field:'requestedDate', dir:'desc'}]},
        dataState:{skip:0, take:25},
        loading:false
        
        
    }

    this.getStagedRequests=this.getStagedRequests.bind(this);
    this.clearState=this.clearState.bind(this);
    
  }

  componentDidMount(){
    this.getStagedRequests();
  }

  clearState(){
    this.setState({
        requests:[],
    });
  };


  onAdd = (e)=>{
      this.setState({requests:e.newState});
  }
  onRemove = (e)=>{
    this.setState({requests:e.newState});
  }








  
  getStagedRequests(){
    const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

    this.setState({loading:true, requests:[], result:[]});
    var payload ={
        company:this.props.user.company
    }
    axios.post('api/stagedDocumentRequestsGet' , payload , {headers}).then(response=>{

        var result = process(response.data.requests, this.state.dataState);
        this.setState({
            requests:response.data.requests
            ,result:result
            ,loading:false
        });

    }).catch(err=>{
        console.log(err);
        this.setState({requests:[], loading:false});
    });


  }

  
  

  _export;
  export = () =>{
    this._export.save();
  }

  
  changeDataState = (e)=>{
    var newData = process(this.state.requests, e.dataState);
    this.setState({dataState:e.dataState, result:newData});
  }

  
//check if an object is empty
isEmpty = (obj) => {
    for(var key in obj) {
        if(obj.hasOwnProperty(key))
            return false;
    }
    return true;
};


  

  render() {
    return(
        <div>
          <ExcelExport data={this.state.requests} fileName={'BulkStagingRequests_Export.xlsx'} ref={(exporter)=>{this._export = exporter;}}>
            <ExcelExportColumn field='bulkKey' title='Bulk Key'/>          
            <ExcelExportColumn field='action' title='Action'/>
            <ExcelExportColumn field='mappingMode' title='Mapping Mode'/>
            <ExcelExportColumn field='folder' title='Vault Folder'/>
            <ExcelExportColumn field='fileDescription' title='File Description'/>
            <ExcelExportColumn field='requestedBy' title='User'/>
            <ExcelExportColumn field='requestedDate' title='Request Date'/>
            <ExcelExportColumn field='currentStatus' title='Current Status'/>
            <ExcelExportColumn field='completedDate' title='Completed Date'/>
          </ExcelExport>

          
            
            <div className='stagedDocRequests'>
                
                <Grid data={this.state.result}
                 sortable={true}
                 filterable={true}
                 pageable={true}
                 onDataStateChange={this.changeDataState}
                   {...this.state.dataState}
                   >
                
                <GridToolbar>
              <div>
              <Button icon='refresh' title='Refresh' onClick={(e)=>this.getStagedRequests()}>Refresh</Button>
              <label className='bgText'> </label>
              <Button icon="excel" title='Export to Excel' onClick={this.export}>Export</Button>
              </div>
              

            </GridToolbar>
            
                    <GridColumn field='bulkKey' title='Bulk Key'/>
                    <GridColumn field='action' title='Action'/>
                    <GridColumn field='requestedBy' title='User'/>
                    <GridColumn field='requestedDate' title='Date'/>
                    <GridColumn field='currentStatus' title='Status'/>
                    

                </Grid>
                {this.state.loading && <GridLoading />}
            </div>
        </div>
    );
  }

}

export default StagedActionRequestsStatus;