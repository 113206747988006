import React, { Component } from "react";
import { Button } from "@progress/kendo-react-buttons";
// import { DropDownList } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';
import { format } from 'date-fns'
import { Document, Page, pdfjs } from 'react-pdf';
import { Grid, Row, Col } from "react-flexbox-grid";
import { LoopCircleLoading } from 'react-loadingg';
import axios from 'axios';
import InputTextField from './components/InputTextField';
import InputDate from './components/InputDate';
import InputBoolean from './components/InputBoolean';
import InputDropdown from './components/InputDropdown';
import InputPortfolioDropdown from './components/InputPortfolioDropdown';
import { parseDate } from '@telerik/kendo-intl';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


class Ssrs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            runReportButtonFlag: false,

            layout: null,
            reports: [],
            report: { name: "Select Report", key: null, adjustableLayout: null },
            entities: [],
            loading: false,
            account: { account: "", displayName: "" },
            numPages: null,
            pageNumber: 1,
            pdfFile: null,
            pdfFlag: false,
            pdfScale: 1.25,
            status: "",
            loaderSpinnerFlag: false,
            checkPdfBlobTimerId: null,//used in componentWillUnmount cleanup
            generateExcelButtonFlag: true,
            generateCsvButtonFlag: true,
            reportParameters: [],
            reportParametersFlag: false,
            parameterList: [],
            defaultList: [],
            singleDateGenericDateToggle: false,
            fromDateGenericDateToggle: false,
            toDateGenericDateToggle: false,
        }

        //this.sendRequest = this.sendRequest.bind(this);
        this.getReportList = this.getReportList.bind(this);
        this.getGenericDates = this.getGenericDates.bind(this);
        this.getAccountList = this.getAccountList.bind(this);
        this.getReportParameters = this.getReportParameters.bind(this);
        this.getReportsDefaults = this.getReportDefaults.bind(this);
        this.getThisDefaultValue = this.getThisDefaultValue.bind(this);
        this.getBlob = this.getBlob.bind(this);
        this.checkBlob = this.checkBlob.bind(this);
        this.deleteBlob = this.deleteBlob.bind(this);
        this.paramTrackingCount = 0
    }

    consolidateGroups = () => {
        var newListWithConsolidated = []
        var unconsolidatedGroupCount = 0
        var consolidatedGroupCount = 0

        if (this.state.SSRSParam_PortfolioTest) {
            this.state.SSRSParam_PortfolioTest.forEach(entity => {
                if (entity.value.charAt(0) === '@') {
                    newListWithConsolidated = newListWithConsolidated.concat({ value: '+' + entity.value, label: entity.label })
                    unconsolidatedGroupCount++

                }
                else if (entity.value.includes('+@')) {
                    newListWithConsolidated = newListWithConsolidated.concat(entity)
                    consolidatedGroupCount++
                }
                else {
                    newListWithConsolidated = newListWithConsolidated.concat(entity)
                }

            })
            this.setState({ SSRSParam_PortfolioTest: newListWithConsolidated }, () => {
                if (consolidatedGroupCount > 0 && unconsolidatedGroupCount === 0) {
                    newListWithConsolidated = []
                    this.state.SSRSParam_PortfolioTest.forEach(entity => {
                        if (entity.value.charAt(0) === '+') {
                            newListWithConsolidated = newListWithConsolidated.concat({ value: entity.value.replace('+', ''), label: entity.label })
                        }
                        else {
                            newListWithConsolidated = newListWithConsolidated.concat(entity)
                        }
                    })
                    this.setState({ SSRSParam_PortfolioTest: newListWithConsolidated })
                }
            })


        }

        if (this.state.SSRSParam_PortfolioList) {
            this.state.SSRSParam_PortfolioList.forEach(entity => {
                if (entity.value.charAt(0) === '@') {
                    newListWithConsolidated = newListWithConsolidated.concat({ value: '+' + entity.value, label: entity.label })
                    unconsolidatedGroupCount++
                }
                else if (entity.value.includes('+@')) {
                    newListWithConsolidated = newListWithConsolidated.concat(entity)
                    consolidatedGroupCount++
                }
                else {
                    newListWithConsolidated = newListWithConsolidated.concat(entity)
                }
            })
            this.setState({ SSRSParam_PortfolioList: newListWithConsolidated }, () => {
                if (consolidatedGroupCount > 0 && unconsolidatedGroupCount === 0) {
                    newListWithConsolidated = []

                    this.state.SSRSParam_PortfolioList.forEach(entity => {
                        if (entity.value.charAt(0) === '+') {
                            newListWithConsolidated = newListWithConsolidated.concat({ value: entity.value.replace('+', ''), label: entity.label })
                        }
                        else {
                            newListWithConsolidated = newListWithConsolidated.concat(entity)
                        }
                    })
                    this.setState({ SSRSParam_PortfolioList: newListWithConsolidated })
                }
            })
        }

    }




    submitForm = (outputFormat) => {
        var entityList = ''


        if (this.state.SSRSParam_PortfolioList) {


            this.state.SSRSParam_PortfolioList.forEach(entity => {
                entityList = entityList.concat(entity.value + '`')
            })

            entityList = entityList.slice(0, -1);//trims last character which is a comma
        }
        console.log(entityList)



        if (outputFormat === 'xlsx') {
            this.setState({ generateExcelButtonFlag: false })
        }
        else if (outputFormat === 'csv') {
            this.setState({ generateCsvButtonFlag: false })
        }
        else {
            this.setState({ runReportButtonFlag: !this.state.runReportButtonFlag, pdfFlag: false, pdfFile: null, pageNumber: 1 })
        }

        const { ...inputFields } = this.state
        console.log(inputFields)
        Object.entries(inputFields).forEach(([key, value]) => {
            if (key.includes("SSRSParam_") && key.includes("_Validate")) {
                this.setState({ [key]: true })
            }
        })

        // if (/*this.validate()*/ 1===1) {
            var splitKey;
            // var paramString = "";
            var paramObject = {};

            // var count = 0;
            Object.entries(inputFields).forEach(([key, value]) => {
                if (key.includes("SSRSParam_") && value !== null && !key.includes("_Data") && !key.includes("_DataOriginal") && !key.includes("_Validate")) {
                    splitKey = key.split('_')
                    console.log(key)

                    if (value === undefined) {
                        value = {}
                    }
                    console.log(value);

                    // if (count > 0) {
                    //     paramString += "|"
                    // }

                    //const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

                    if (splitKey[1] === "PortfolioTest" || splitKey[1] === "PortfolioList") {
                        //paramString += splitKey[1] + "," + value[0].value
                        // paramString += splitKey[1] + "`" + entityList
                        paramObject[splitKey[1]] = entityList
                        //paramString = paramString.concat({paramName: splitKey[1], paramValue: entityList})
                    }
                    else if (splitKey[1] === "singleDate" && this.state.singleDateGenericDateToggle) {
                        this.state.genericDates.forEach(genericDate => {
                            if (genericDate.label === value.value) {
                                // paramString += splitKey[1] + "`" + format(this.getDate(genericDate.value, true), "MM-dd-yyyy")
                                paramObject[splitKey[1]] = format(this.getDate(genericDate.value, true), "MM-dd-yyyy")
                                //paramString = paramString.concat({paramName: splitKey[1], paramValue: format(this.getDate(genericDate.value, true), "MM-dd-yyyy")})

                            }
                        })
                    }
                    else if (splitKey[1] === "FromDate" && this.state.fromDateGenericDateToggle) {
                        this.state.genericDates.forEach(genericDate => {
                            if (genericDate.label === value.value) {
                                // paramString += splitKey[1] + "`" + format(this.getDate(genericDate.value, true), "MM-dd-yyyy")
                                paramObject[splitKey[1]] = format(this.getDate(genericDate.value, true), "MM-dd-yyyy")
                                //paramString = paramString.concat({paramName: splitKey[1], paramValue: format(this.getDate(genericDate.value, true), "MM-dd-yyyy")})
                            }
                        })
                    }
                    else if (splitKey[1] === "ToDate" && this.state.toDateGenericDateToggle) {
                        this.state.genericDates.forEach(genericDate => {
                            if (genericDate.label === value.value) {
                                // paramString += splitKey[1] + "`" + format(this.getDate(genericDate.value, true), "MM-dd-yyyy")
                                paramObject[splitKey[1]] = format(this.getDate(genericDate.value, true), "MM-dd-yyyy")
                                //paramString = paramString.concat({paramName: splitKey[1], paramValue: format(this.getDate(genericDate.value, true), "MM-dd-yyyy")})
                            }
                        })
                    }
                    else if (value.label) {//checks if this value is from a dropdown list
                        // paramString += splitKey[1] + "`" + value.value
                        paramObject[splitKey[1]] = value.value
                        //paramString = paramString.concat({paramName: splitKey[1], paramValue: value.value})
                    }
                    else if (value.constructor.name === "Date") {
                        // paramString += splitKey[1] + "`" + format(value, "MM-dd-yyyy")
                        paramObject[splitKey[1]] = format(value, "MM-dd-yyyy")
                        //paramString = paramString.concat({paramName: splitKey[1], paramValue: format(value, "MM-dd-yyyy")})
                    }
                    else {
                        // paramString += splitKey[1] + "`" + value
                        paramObject[splitKey[1]] = value
                        //paramString = paramString.concat({paramName: splitKey[1], paramValue: value})
                    }
                    // count++;
                }
            })

            const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

            // var layout = null
            // if (this.state.SSRSParam_Layout !== null && this.state.SSRSParam_Layout) {
            //     layout = this.state.SSRSParam_Layout.value
            // }

            //f (this.state.layout !== null)
            // layout = this.state.layout.key


            // var today = new Date();
            // var date = '' + (today.getMonth() + 1) + '' + today.getDate() + '' + today.getFullYear();
            // var time = '' + today.getHours() + '' + today.getMinutes() + '' + today.getSeconds();
            // var currentDateTime = '' + date + '' + time;
            // var id = '' + this.props.user.company + "_" + this.props.user.user + "_" + currentDateTime

            // var fileName = this.props.user.company + "_" + this.state.report.name + "_" + currentDateTime + "." + outputFormat

            var payload = {
                company: this.props.user.company,
                ExportID: this.props.user.exportID,
                RequestingUserEmail: this.props.user.email,
                ExportParameterValues: paramObject,
                Status: 'Pending',
            }

            // var payloadForBlobMethod = {
            //     id: id,
            //     fileName: fileName,
            //     reportName: this.state.report.name,
            //     databaseName: this.props.user.company,
            //     commandDate: currentDateTime
            // }

            console.log(payload)
            this.setState({ runReportButtonFlag: true })


            axios
                .post("api/exportRequest", payload, { headers })
                .then((response) => {
                    if (response.data.code === 200) {
                        this.setState({ runReportButtonFlag: true })
                        this.props.goToDataExports(1)
                    }
                    else {
                        alert('An error has occured while sending your request.');
                    }
                })
                .catch((e) => {
                    console.error(e);
                    this.setState({ runReportButtonFlag: !this.state.runReportButtonFlag })
                });

            // //e.preventDefault();
        // }
        // else {
        //     this.setState({ runReportButtonFlag: true })
        // }


    }

    checkParamTracking() {
        var checkTimer = setInterval(
            function () {
                //test = this.checkBlob(fileNameIn)
                if (this.paramTrackingCount === 0) {
                    this.getParameterDefaults()
                    clearInterval(checkTimer)
                }

            }
                .bind(this),
            2000
        )
    }


    _handleChange = e => {
        const t = "SSRSParam_".concat([e.target.props.name])
        this.validate(t)

        this.setState({
            [t]: e.target.value
        })
    }

    _handleChangePortfolio = e => {
        const t = "SSRSParam_".concat([e.target.props.name])
        //this.validate(t)
        var alreadyInListAsConsolidated = false //will be true if you are adding a group and the group is already in the list as consolidated.
        if (e.target.value.length > this.state[t].length && this.state[t].length > 0) {//checks if adding an entity, rather than deleting
            var entityToAdd = e.target.value[e.target.value.length - 1]
            if (entityToAdd.value.includes('@')) {
                this.state[t].forEach(entity => {
                    if (entityToAdd.value === entity.value.replace('+', '')) {
                        alreadyInListAsConsolidated = true;
                    }
                })
            }
        }
        if (!alreadyInListAsConsolidated) {
            this.setState({
                [t]: e.target.value
            })
        }
    }

    _itemRender = (li, itemProps) => {
        const itemChildren = <span>{itemProps.dataItem.label}</span>;

        return React.cloneElement(li, li.props, itemChildren);
    }

    _filterChange = (event) => {
        console.log(event)
        const dataStateToBeFiltered = "SSRSParam_" + event.target.name + "_Data"
        const dataStateContainingOriginalValues = this.state["SSRSParam_" + event.target.name + "_DataOriginal"]
        if (event.target.name === "PortfolioList") {
            event.filter.field = "label"
        }

        this.setState({
            [dataStateToBeFiltered]: this.filterData(event.filter, dataStateContainingOriginalValues)
        });
    }

    _filterChangeGenericDate = (event) => {
        console.log(event)
        event.filter.field = "value"
        const dataStateToBeFiltered = "genericDates_" + event.target.name + "_Data"
        const dataStateContainingOriginalValues = this.state["genericDates_" + event.target.name + "_DataOriginal"]

        this.setState({
            [dataStateToBeFiltered]: this.filterData(event.filter, dataStateContainingOriginalValues)
        });
    }

    filterData(filter, originalData) {
        //const data = allData.slice();
        return filterBy(originalData, filter);
    }

    componentDidMount() {
        this.getReportList();
        this.getReportDefaults();
        this.getAccountList(() => {
            this.getGenericDates(() => {
                this.onReportChangeHandler2();
            });
        });
    }

    componentWillUnmount() {
        clearTimeout(this.state.checkPdfBlobTimerId)
    }

    onChangeHandler = (e, type) => {

        this.setState({ [type]: e.target.value });
    }

    onGenericToggleChangeHandler = (value, type) => {
        console.log(value)
        this.setState({ [type]: value })

        if (type === 'singleDateGenericDateToggle') {
            if (value === true) {
                this.setState({ SSRSParam_singleDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
            }
            else {
                this.setState({ SSRSParam_singleDate: this.state.singleDate_OriginalDefault })
                console.log(this.state.singleDate_OriginalDefault)
            }
        }
        else if (type === 'fromDateGenericDateToggle') {
            if (value === true) {
                this.setState({ SSRSParam_FromDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
            }
            else {
                this.setState({ SSRSParam_FromDate: this.state.FromDate_OriginalDefault })
                console.log(this.state.FromDate_OriginalDefault)
            }
        }
        else if (type === 'toDateGenericDateToggle') {
            if (value === true) {
                this.setState({ SSRSParam_ToDate: { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' } })
            }
            else {
                this.setState({ SSRSParam_ToDate: this.state.ToDate_OriginalDefault })
                console.log(this.state.ToDate_OriginalDefault)
            }
        }
    }

    changePdfPage = (pageNumber, direction) => {
        var newPage = pageNumber;
        if (direction === 'prev') {
            if (pageNumber === 1) {//if currently viewing the first page...
                newPage = this.state.numPages//...show the last page
            }
            else {
                newPage = pageNumber - 1
            }
        }
        if (direction === 'next') {
            if (pageNumber === this.state.numPages) {//if current page is equal to the amount of pages...
                newPage = 1//...wrap around to first page
            }
            else {
                newPage = pageNumber + 1
            }

        }
        this.setState({ pageNumber: newPage });
    }

    formatDates = (date) => {

        var formattedDate = null

        return formattedDate
    }

    onReportChangeHandler = (e) => {

        const { ...stateObject } = this.state
        this.setState({ runReportButtonFlag: false })

        Object.entries(stateObject).forEach(([key, value]) => {
            if (key.includes("SSRSParam_")) {
                this.setState({ [key]: null })
            }
        })

        this.setState({
            parameterList: [],
            layout: null, layoutFlag: false,
            pdfFile: null, pdfFlag: false, pdfScale: 1.25,
            singleDateGenericDateToggle: false,
            fromDateGenericDateToggle: false,
            toDateGenericDateToggle: false,
        })
        //this.setState({ fromDateFlag: false })
        //this.setState({ toDateFlag: false })
        //this.setState({ singleDateFlag: false })
        //this.setState({ pdfFile: "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf"})

        //wait for setState to finish before calling getReportParameters
        this.setState({ report: e.target.value }, () => {
            this.getReportParameters()
        });
    }

    onReportChangeHandler2() {

        const { ...stateObject } = this.state
        this.setState({ runReportButtonFlag: false })

        Object.entries(stateObject).forEach(([key, value]) => {
            if (key.includes("SSRSParam_")) {
                this.setState({ [key]: null })
            }
        })

        this.setState({
            parameterList: [],
            layout: null, layoutFlag: false,
            pdfFile: null, pdfFlag: false, pdfScale: 1.25,
            singleDateGenericDateToggle: false,
            fromDateGenericDateToggle: false,
            toDateGenericDateToggle: false,
        })
        //this.setState({ fromDateFlag: false })
        //this.setState({ toDateFlag: false })
        //this.setState({ singleDateFlag: false })
        //this.setState({ pdfFile: "https://cors-anywhere.herokuapp.com/http://www.pdf995.com/samples/pdf.pdf"})

        //wait for setState to finish before calling getReportParameters
        this.setState({ report: { name: this.props.user.exportID, key: this.props.user.exportID, adjustableLayout:true, folder: 'ExampleSSRSProject' } }, () => {
        // this.setState({ report: { name: "Assets Under Management", key: "Assets Under Management", adjustableLayout: true, folder: 'ExampleSSRSProject' } }, () => {
            this.getReportParameters()
        });
    }

    getReportList() {
        var reports = [];
        var reportNames = [];
        var splitName;
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var folder;
        if (this.props.showCustom) {
            folder = this.props.user.company
        }
        else {
            folder = 'ExampleSSRSProject'
        }

        //axios
        //.post('api/ssrsGetReports', payload, { headers })
        axios(`api/ssrsGetReports`, {
            method: 'GET',
            params: { folder: folder, company: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                var folder
                response.data.data.forEach(reportInResponse => {
                    folder = reportInResponse.path.split('/')[1]

                    if (reportInResponse.name.includes("_Landscape") || reportInResponse.name.includes("_Portrait")) {
                        splitName = reportInResponse.name.split('_')
                        if (!reportNames.includes(splitName[0])) {
                            reportNames.push(splitName[0]);
                            reports.push({ name: splitName[0], key: reportInResponse.key, adjustableLayout: true, folder: folder })
                        }
                    }
                    else {
                        reports.push({ name: reportInResponse.name, key: reportInResponse.key, folder: folder })
                    }


                })
                this.setState({ reports: reports })

            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

    }

    getGenericDates(cb) {
        var dates = []

        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetAllGenericDates`, {
            method: 'GET',
            params: { database: this.props.user.company },
            headers: headers,
        })
            .then(response => {
                response.data.data.forEach(dateInResponse => {
                    dates.push({ label: dateInResponse.label, value: dateInResponse.value })
                })

                const genericDates = [
                    { value: 'bblm', label: 'bblm - Beginning Business Day Last Month' },
                    { value: 'bblq', label: 'bblq - Beginning Business Day Last Quarter' },
                    { value: 'bbly', label: 'bbly - Beginning Business Day Last Year' },
                    { value: 'bbpm', label: 'bbpm - Beginning Business Day Previous Month' },
                    { value: 'bbpq', label: 'bbpq - Beginning Business Day Previous Quarter' },
                    { value: 'bbpy', label: 'bbpy - Beginning Business Day Previous Year' },
                    { value: 'bbtm', label: 'bbtm - Beginning Business Day This Month' },
                    { value: 'bbtq', label: 'bbtq - Beginning Business Day This Quarter' },
                    { value: 'bbty', label: 'bbty - Beginning Business Day This Year' },
                    { value: 'bbnm', label: 'bbnm - Beginning Business Day Next Month' },
                    { value: 'bbnq', label: 'bbnq - Beginning Business Day Next Quarter' },
                    { value: 'bbny', label: 'bbny - Beginning Business Day Next Year' },
                    { value: 'bdlm', label: 'bdlm - Beginning Day Last Month' },
                    { value: 'bdlq', label: 'bdlq - Beginning Day Last Quarter' },
                    { value: 'bdly', label: 'bdly - Beginning Day Last Year' },
                    { value: 'bdpm', label: 'bdpm - Beginning Day Previous Month' },
                    { value: 'bdpq', label: 'bdpq - Beginning Day Previous Quarter' },
                    { value: 'bdpy', label: 'bdpy - Beginning Day Previous Year' },
                    { value: 'bdtm', label: 'bdtm - Beginning Day This Month' },
                    { value: 'bdtq', label: 'bdtq - Beginning Day This Quarter' },
                    { value: 'bdty', label: 'bdty - Beginning Day This Year' },
                    { value: 'bdnm', label: 'bdnm - Beginning Day Next Month' },
                    { value: 'bdnq', label: 'bdnq - Beginning Day Next Quarter' },
                    { value: 'bdny', label: 'bdny - Beginning Day Next Year' },
                    { value: 'eblm', label: 'eblm - Ending Business Day Last Month' },
                    { value: 'eblq', label: 'eblq - Ending Business Day Last Quarter' },
                    { value: 'ebly', label: 'ebly - Ending Business Day Last Year' },
                    { value: 'ebpm', label: 'ebpm - Ending Business Day Previous Month' },
                    { value: 'ebpq', label: 'ebpq - Ending Business Day Previous Quarter' },
                    { value: 'ebpy', label: 'ebpy - Ending Business Day Previous Year' },
                    { value: 'ebtm', label: 'ebtm - Ending Business Day This Month' },
                    { value: 'ebtq', label: 'ebtq - Ending Business Day This Quarter' },
                    { value: 'ebty', label: 'ebty - Ending Business Day This Year' },
                    { value: 'ebnm', label: 'ebnm - Ending Business Day Next Month' },
                    { value: 'ebnq', label: 'ebnq - Ending Business Day Next Quarter' },
                    { value: 'ebny', label: 'ebny - Ending Business Day Next Year' },
                    { value: 'edlm', label: 'edlm - Ending Day Last Month' },
                    { value: 'edlq', label: 'edlq - Ending Day Last Quarter' },
                    { value: 'edly', label: 'edly - Ending Day Last Year' },
                    { value: 'edpm', label: 'edpm - Ending Day Previous Month' },
                    { value: 'edpq', label: 'edpq - Ending Day Previous Quarter' },
                    { value: 'edpy', label: 'edpy - Ending Day Previous Year' },
                    { value: 'edtm', label: 'edtm - Ending Day This Month' },
                    { value: 'edtq', label: 'edtq - Ending Day This Quarter' },
                    { value: 'edty', label: 'edty - Ending Day This Year' },
                    { value: 'ednm', label: 'ednm - Ending Day Next Month' },
                    { value: 'ednq', label: 'ednq - Ending Day Next Quarter' },
                    { value: 'edny', label: 'edny - Ending Day Next Year' },
                    { value: 'yest', label: 'yest - Yesterday' },
                    { value: 'last', label: 'last - Last Business Day' },
                    { value: 'prev', label: 'prev - Next To Last Business Day' }
                ]
                this.setState({ genericDates: dates, genericDates_singleDate_Data: genericDates,
                    genericDates_FromDate_Data: genericDates,
                    genericDates_ToDate_Data: genericDates,
                    genericDates_singleDate_DataOriginal: genericDates,
                    genericDates_FromDate_DataOriginal: genericDates,
                    genericDates_ToDate_DataOriginal: genericDates, }, () => cb())
            })
            .catch(err => {
                console.error(err);
            })

        


    }

    getAccountList(cb) {
        var entities = [];
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        this.setState({ loading: true })

        axios(`api/ssrsGetEntities`, {
            method: 'GET',
            params: { database: this.props.user.company },
            headers: headers
        })
            .then(response => {
                entities = response.data.data
                console.log(response.data.data)
                var splitLine
                var code
                var name
                var type
                var formattedLine
                var newEntities = []
                entities.forEach(line => {
                    splitLine = line.label.split('|')
                    code = this.getSpacedLine(splitLine[0], 12)
                    name = this.getSpacedLine(splitLine[1], 32)
                    type = this.getSpacedLine(splitLine[2], 8)

                    formattedLine = code + " | " + name + " | " + type
                    newEntities.push({ value: line.value, label: formattedLine })
                })
                entities = newEntities
                console.log(entities)
                this.setState({ entities: entities, loading: false }, () => {cb()})
            })
            .catch(err => {
                console.error(err);
                this.setState({ loading: false })
            })
        //return reports;

    }

    getReportDefaults() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetReportDefaults`, {
            method: 'GET',
            params: { company: this.props.user.company },
            headers: headers
        })
            .then(response => {
                console.log(response.data.data)
                this.setState({ defaultList: response.data.data })
            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

    }

    getBlob = (payload, outputFormat) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var responseType = null
        if (outputFormat === 'pdf') {
            responseType = 'application/pdf'
        }
        if (outputFormat === 'xlsx') {
            responseType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }
        if (outputFormat === 'csv') {
            responseType = 'application/csv'
        }



        axios(`api/ssrsGetPdfBlob`, {
            method: 'GET',
            responseType: 'blob', //Force to receive data in a Blob Format
            params: { id: payload.id, databaseName: payload.databaseName },
            headers: headers
        })
            .then(response => {
                console.log(response.data)
                const file = new Blob(
                    [response.data],
                    { type: responseType });
                const fileURL = URL.createObjectURL(file);//Build a URL from the file
                if (outputFormat === 'pdf') {
                    this.setState({
                        pdfFile: fileURL, pdfFlag: true, pdfScale: 1.25
                    });
                }
                if (outputFormat === 'xlsx') {
                    //window.open(fileURL + ".xlsx")
                    this.saveExcelFile(file, payload.fileName)
                    this.setState({ generateExcelButtonFlag: true })
                    //window.location.assign(fileURL)
                }
                if (outputFormat === 'csv') {
                    //window.open(fileURL + ".xlsx")
                    this.saveCsvFile(file, payload.fileName)
                    this.setState({ generateCsvButtonFlag: true })
                    //window.location.assign(fileURL)
                }

                this.deleteBlob(payload)
            })
            .catch(error => {
                console.error(error);
            });

        this.setState({ runReportButtonFlag: true, status: "", loaderSpinnerFlag: false })
    }

    deleteBlob = (params) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        var payload = {
            filename: params.fileName,
            reportName: params.reportName,
            databaseName: params.databaseName,
            id: params.id
        }

        axios
            .post("api/ssrsDeletePdfBlob", payload, { headers })
            .then((response) => {

            })
            .catch(error => {
                console.error(error);
            });


    }

    saveExcelFile = (blob, filename) => {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0)
        }
    }

    saveCsvFile = (blob, filename) => {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, filename);
        } else {
            const a = document.createElement('a');
            document.body.appendChild(a);
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            setTimeout(() => {
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
            }, 0)
        }
    }


    checkBlob = (payload, callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetPdfBlob`, {
            method: 'GET',
            params: { id: payload.id, databaseName: payload.databaseName },
            headers: headers
        })
            .then(response => {

                if (response.data === "no data") {
                    callback(false)
                }
                else if (response.data.errorMessage) {
                    callback(response.data)
                }
                else {
                    callback(true)
                }
            })
            .catch(error => {
                console.error(error);
            });

    }

    getValidValues = (report, parameter, callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetParameterValidValuesQuery`, {
            method: 'GET',
            params: { report: report, parameter: parameter, company: this.props.user.company },
            //responseType: 'blob', //Force to receive data in a Blob Format
            headers: headers
        })
            .then(response => {

                if (response.data === "no data") {
                    callback(false)
                }
                else {
                    callback(response.data)
                }
            })
            .catch(error => {
                console.error(error);
            });

    }

    getDefaultValue = (report, parameter, callback) => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };

        axios(`api/ssrsGetParameterDefaultValueQuery`, {
            method: 'GET',
            params: { report: report, parameter: parameter, company: this.props.user.company },
            //responseType: 'blob', //Force to receive data in a Blob Format
            headers: headers
        })
            .then(response => {

                if (response.data === "no data") {
                    callback(false)
                }
                else {
                    callback(response.data)
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    getDate(sqlDate, isUTC) {
        var d;
        if (parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX")) {
            d = parseDate(sqlDate, "yyyy-MM-ddTHH:mm:ss.SSSXXX")
        }
        else {
            d = parseDate(sqlDate, "MM/dd/yyyy HH:mm:ss")
        }

        if (isUTC) {
            var utc = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate());
            return utc;
        }

        else
            return d;
    }

    getThisDefaultValue(defaultParameters, paramName) {
        var tempDefaultValue = null

        for (let param of defaultParameters) {
            if (param.paramName === paramName) {
                tempDefaultValue = param.value
                break
            }
        }

        return tempDefaultValue
    }

    getButtonStatus() {
        if (!this.state.runReportButtonFlag || this.paramTrackingCount !== 0) {
            return true
        }
        else {
            return false
        }

    }

    validate(input) {
        var flag = true;
        var isBlank = false;
        var isIncorrectDate = false;

        const { parameterList } = this.state
        if (input === undefined) {//check every parameter, e.g. for when user clicks Run Report
            parameterList.forEach(form => {
                if (form.allowBlank.toLowerCase() === "false" && (this.state["SSRSParam_" + form.name] === "" || this.state["SSRSParam_" + form.name] === null || this.state["SSRSParam_" + form.name].length === 0)) {

                    this.setState({ ["SSRSParam_" + form.name + "_Validate"]: false })
                    flag = false
                    isBlank = true

                }
                if (form.name.toLowerCase() === "fromdate" && (!this.state.singleDateGenericDateToggle && !this.state.fromDateGenericDateToggle && !this.state.toDateGenericDateToggle)) {
                    if (this.getDate(this.state.SSRSParam_FromDate, false).getTime() > this.getDate(this.state.SSRSParam_ToDate, false).getTime()) {
                        this.setState({ SSRSParam_FromDate_Validate: false, SSRSParam_ToDate_Validate: false })
                        flag = false
                        isIncorrectDate = true
                    }


                }
            })

        }
        else {
            if (this.state[input + "_Validate"] === false) {
                this.setState({ [input + "_Validate"]: true })

                if (input.toLowerCase().includes("date")) {
                    const { ...inputFields } = this.state
                    Object.entries(inputFields).forEach(([key, value]) => {
                        if (key.toLowerCase().includes("date_") && key.includes("_Validate") && value !== null) {
                            this.setState({ [key]: true })
                        }
                    })
                }

            }
        }

        if (flag === false) {
            var errorString = ''
            if (isBlank) {
                errorString = errorString.concat('Highlighted fields must not be blank.\r\n')
            }
            if (isIncorrectDate) {
                errorString = errorString.concat('Begin Date must precede End Date.\r\n')
            }
            alert(errorString);
        }

        return flag;

    }




    getReportParameters() {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var dataExport = this.state.report.name;
        var parameters = [];
        // var defaultParameters = [];
        // this.checkParamTracking();
        //var adjustedDefaultValue = null


        axios(`api/exportParameters?company=${this.props.user.company}&export=${dataExport}`, {
            method: 'GET',
            headers: headers
        })
            .then(response => {

                this.setState({ reportParameters: response.data.data })
                //begin loop through response
                //response.data.data.map((individualParameter) => {
                response.data.data.forEach(individualParameter => {
                    if (individualParameter.ParamName !== "database") {
                        const t = "SSRSParam_".concat([individualParameter.ParameterID])

                        // var adjustedDefaultValue = null //this.getThisDefaultValue(defaultParameters, individualParameter.ParamName)
                        console.log(individualParameter)

                        if (individualParameter.ParameterID === "PortfolioList") {
                            this.paramTrackingCount++
                            parameters.push({ type: "Dropdown", name: individualParameter.ParameterID, label: individualParameter.ParameterDescription, defaultValue: this.state.entities[0], values: this.state.entities, sortOrder: individualParameter.ParameterSortOrder, allowBlank: !individualParameter.ParameterRequired })
                            parameters.sort(this.compareParamOrder)
                            this.setState({
                                [t]: [], [t.concat("_Data")]: this.state.entities, [t.concat("_DataOriginal")]: this.state.entities, [t.concat("_Validate")]: true
                            })
                            this.paramTrackingCount--
                        }//asdf
                        else if (individualParameter.ParameterDataType.toLowerCase() === "string"){
                            this.paramTrackingCount++
                            parameters.push({ type: "String", name: individualParameter.ParameterID, label: individualParameter.ParameterDescription, defaultValue: individualParameter.ParameterDefault, sortOrder: individualParameter.ParameterSortOrder, allowBlank: !individualParameter.ParameterRequired })
                                parameters.sort(this.compareParamOrder)
                                if (individualParameter.ParameterDefault) {
                                    this.setState({
                                        [t]: individualParameter.ParameterDefault, [t.concat("_Validate")]: true
                                    })
                                }
                                else {
                                    this.setState({
                                        [t]: '', [t.concat("_Validate")]: true
                                    })
                                }
                                this.paramTrackingCount--
                        }
                        else if (individualParameter.ParameterDataType.toLowerCase() === "date") {
                            this.paramTrackingCount++
                            var defaultDate = this.getDate(this.state.genericDates.find(item => item.label === 'edlm').value, true)
                            if (individualParameter.ParameterDefault && individualParameter.ParameterDefault.length === 4) {//if generic date
                                defaultDate = this.getDate(this.state.genericDates.find(item => item.label === individualParameter.ParameterDefault).value, true)
                                // defaultDate = this.state["genericDates_" + individualParameter.ParameterID + "_Data"].find(item => item.value === individualParameter.ParameterID)
                            }
                            else if (individualParameter.ParameterDefault){
                                defaultDate = this.getDate(individualParameter.ParameterDefault, true)
                            }

                            // var defaultDate = this.getDate(lowerDefaultValue[0].value, true)
                            this.setState({ [individualParameter.ParameterID.concat('_OriginalDefault')]: defaultDate })
                            parameters.push({ type: "DateTime", name: individualParameter.ParameterID, label: individualParameter.ParameterDescription, defaultValue: defaultDate, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                            parameters.sort(this.compareParamOrder)
                            this.setState({
                                [t]: defaultDate, [t.concat("_Validate")]: true
                            })
                            this.paramTrackingCount--
                            
                            // this.getDefaultValue(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                            //     if (callback !== false) {
                            //         const lowerDefaultValue = []
                            //         callback.forEach(obj => {
                            //             var key, keys = Object.keys(obj);
                            //             var n = keys.length;
                            //             var newobj = {}
                            //             while (n--) {
                            //                 key = keys[n];
                            //                 newobj[key.toLowerCase()] = obj[key];
                            //             }
                            //             lowerDefaultValue.push(newobj)
                            //         })
                            //         var defaultDate = this.getDate(lowerDefaultValue[0].value, true)
                            //         this.setState({ [individualParameter.ParamName.concat('_OriginalDefault')]: defaultDate })
                            //         parameters.push({ type: "DateTime", name: individualParameter.ParameterID, label: individualParameter.ParameterDescription, defaultValue: defaultDate, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                            //         parameters.sort(this.compareParamOrder)
                            //         this.setState({
                            //             [t]: defaultDate, [t.concat("_Validate")]: true
                            //         })


                            //     }
                            //     this.setState({ paramTrackingCount: this.state.paramTrackingCount - 1 })
                            // })
                        }
                        else if (individualParameter.ParameterDataType.toLowerCase() === "boolean"){
                            this.paramTrackingCount++
                            parameters.push({ type: "Boolean", name: individualParameter.ParameterID, label: individualParameter.ParameterDescription, defaultValue: individualParameter.ParameterDefault === '1' ? 'true': 'false', sortOrder: individualParameter.ParameterSortOrder, allowBlank: !individualParameter.ParameterRequired })
                                parameters.sort(this.compareParamOrder)
                                if (individualParameter.ParameterDefault) {
                                    this.setState({
                                        [t]: individualParameter.ParameterDefault === '1' ? 'true': 'false', [t.concat("_Validate")]: true
                                    })
                                }
                                else {
                                    this.setState({
                                        [t]: 'false', [t.concat("_Validate")]: true
                                    })
                                }
                                this.paramTrackingCount--
                        }
                        else if (individualParameter.ParameterDataType.toLowerCase() === "dropdown"){
                            this.paramTrackingCount++
                            var dropdownOptions = JSON.parse(individualParameter.ParameterDropdownOptions)
                            var dropdownDefault = {}
                            if (individualParameter.ParameterDefault){
                                var index = dropdownOptions.findIndex(d => {
                                    return (d.value === individualParameter.ParameterDefault);
                                });
                                dropdownDefault = index >= 0 ? dropdownOptions[index] : dropdownOptions[0]
                            }
                            else { dropdownDefault = dropdownOptions[0] }
                            parameters.push({ type: "Dropdown", name: individualParameter.ParameterID, label: individualParameter.ParameterDescription, defaultValue: dropdownDefault, values: dropdownOptions, sortOrder: individualParameter.ParameterSortOrder, allowBlank: !individualParameter.ParameterRequired })
                                parameters.sort(this.compareParamOrder)
                                
                                if (individualParameter.ParameterDefault) {
                                    this.setState({
                                        [t]: individualParameter.ParameterDefault, [t.concat("_Validate")]: true
                                    })
                                }
                                else {
                                    this.setState({
                                        [t]: dropdownOptions[0], [t.concat("_Validate")]: true, [t.concat("_Data")]: dropdownOptions
                                    })
                                }
                                this.paramTrackingCount--
                        }
                        // else {
                        //     if (individualParameter.ParamDataType === "String" && individualParameter.ValidValues !== "") {
                        //         var values = []
                        //         var XMLParser = require('react-xml-parser');
                        //         var xml = new XMLParser().parseFromString(individualParameter.ValidValues);
                        //         xml.getElementsByTagName('ValidValue').forEach(value => {
                        //             values.push({ value: value.children[0].value, label: value.children[1].value })
                        //         })

                        //         if (individualParameter.DefaultValues !== null) {
                        //             const defaultValue = values.find(item => item.value === individualParameter.DefaultValues);
                        //             adjustedDefaultValue = values.find(item => item.value === adjustedDefaultValue);
                        //             console.log(individualParameter.DefaultValues)
                        //             console.log(adjustedDefaultValue)
                        //             console.log(defaultValue)

                        //             //if (defaultValue){adjustedDefaultValue = {value: adjustedDefaultValue, label: defaultValue.label}}

                        //             parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: values, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                        //             parameters.sort(this.compareParamOrder)
                        //             this.setState({
                        //                 [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: values, [t.concat("_DataOriginal")]: values, [t.concat("_Validate")]: true
                        //             })
                        //         }
                        //         else {
                        //             parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || values[0], values: values, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                        //             parameters.sort(this.compareParamOrder)
                        //             this.setState({
                        //                 [t]: adjustedDefaultValue || values[0], [t.concat("_Data")]: values, [t.concat("_DataOriginal")]: values, [t.concat("_Validate")]: true
                        //             })
                        //         }
                        //     }
                        //     else if (individualParameter.DynamicValidValues === "True") {
                        //         this.setState({ paramTrackingCount: this.state.paramTrackingCount + 1 })
                        //         this.getValidValues(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                        //             if (callback !== false) {
                        //                 var lowerValues = []
                        //                 callback.forEach(obj => {
                        //                     var key, keys = Object.keys(obj);
                        //                     var n = keys.length;
                        //                     var newobj = {}
                        //                     while (n--) {
                        //                         key = keys[n];
                        //                         newobj[key.toLowerCase()] = obj[key];
                        //                     }
                        //                     lowerValues.push(newobj)
                        //                 })

                        //                 if (individualParameter.ParamName === "PortfolioTest" || individualParameter.ParamName === "PortfolioList") {
                        //                     var splitLine
                        //                     var code
                        //                     var name
                        //                     var type
                        //                     var formattedLine
                        //                     var newLowerValues = []
                        //                     lowerValues.forEach(line => {
                        //                         splitLine = line.label.split('|')
                        //                         code = this.getSpacedLine(splitLine[0], 12)
                        //                         name = this.getSpacedLine(splitLine[1], 32)
                        //                         type = this.getSpacedLine(splitLine[2], 8)

                        //                         formattedLine = code + " | " + name + " | " + type
                        //                         newLowerValues.push({ value: line.value, label: formattedLine })
                        //                     })
                        //                     lowerValues = newLowerValues

                        //                 }


                        //                 if (individualParameter.DefaultValues !== null || individualParameter.ParamName === "equityAssetClass") {
                        //                     const defaultValue = lowerValues.find(item => item.value === individualParameter.DefaultValues);
                        //                     adjustedDefaultValue = lowerValues.find(item => item.value === adjustedDefaultValue);
                        //                     parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                        //                     parameters.sort(this.compareParamOrder)
                        //                     this.setState({
                        //                         [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                        //                     })
                        //                 }
                        //                 else if (individualParameter.DefaultValues === null && individualParameter.DynamicDefaultValue === "True") {
                        //                     console.log(individualParameter.ParamName)
                        //                     this.setState({ paramTrackingCount: this.state.paramTrackingCount + 1 })
                        //                     this.getDefaultValue(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                        //                         if (callback !== "false") {
                        //                             const lowerDefaultValue = []
                        //                             callback.forEach(obj => {
                        //                                 var key, keys = Object.keys(obj);
                        //                                 var n = keys.length;
                        //                                 var newobj = {}
                        //                                 while (n--) {
                        //                                     key = keys[n];
                        //                                     newobj[key.toLowerCase()] = obj[key];
                        //                                 }
                        //                                 lowerDefaultValue.push(newobj)
                        //                             })
                        //                             console.log(lowerValues)
                        //                             const defaultValue = lowerValues.find(item => item.value === lowerDefaultValue[0].value);
                        //                             console.log(adjustedDefaultValue)
                        //                             adjustedDefaultValue = null //this.getThisDefaultValue(defaultParameters, individualParameter.ParamName)
                        //                             adjustedDefaultValue = lowerValues.find(item => item.label === adjustedDefaultValue);
                        //                             console.log(adjustedDefaultValue)

                        //                             if (individualParameter.ParamName === 'Disclaimer') {
                        //                                 lowerValues.forEach(v => {
                        //                                     v.value = v.label
                        //                                 })
                        //                                 if (adjustedDefaultValue)
                        //                                     adjustedDefaultValue.value = adjustedDefaultValue.label
                        //                             }
                        //                             parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || defaultValue, values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                        //                             parameters.sort(this.compareParamOrder)
                        //                             this.setState({
                        //                                 [t]: adjustedDefaultValue || defaultValue, [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                        //                             })
                        //                         }
                        //                         this.setState({ paramTrackingCount: this.state.paramTrackingCount - 1 })
                        //                     })

                        //                 }
                        //                 else {
                        //                     parameters.push({ type: "Dropdown", name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: lowerValues[0], values: lowerValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                        //                     parameters.sort(this.compareParamOrder)
                        //                     if (individualParameter.ParamName === "PortfolioTest" || individualParameter.ParamName === "PortfolioList") {
                        //                         this.setState({
                        //                             [t]: [], [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                        //                         })
                        //                     }
                        //                     else {
                        //                         this.setState({
                        //                             [t]: '', [t.concat("_Data")]: lowerValues, [t.concat("_DataOriginal")]: lowerValues, [t.concat("_Validate")]: true
                        //                         })
                        //                     }
                        //                 }

                        //             }
                        //             this.setState({ paramTrackingCount: this.state.paramTrackingCount - 1 })
                        //         })

                        //         //parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: individualParameter.DefaultValues })
                        //         //this.setState({
                        //         //  [t]: individualParameter.DefaultValues
                        //         //})

                        //     }
                            // else if (individualParameter.DynamicDefaultValue === "True" && individualParameter.ParamDataType === "DateTime") {
                            //     this.setState({ paramTrackingCount: this.state.paramTrackingCount + 1 })
                            //     this.getDefaultValue(individualParameter.ReportName, individualParameter.ParamName, (callback) => {
                            //         if (callback !== false) {
                            //             const lowerDefaultValue = []
                            //             callback.forEach(obj => {
                            //                 var key, keys = Object.keys(obj);
                            //                 var n = keys.length;
                            //                 var newobj = {}
                            //                 while (n--) {
                            //                     key = keys[n];
                            //                     newobj[key.toLowerCase()] = obj[key];
                            //                 }
                            //                 lowerDefaultValue.push(newobj)
                            //             })
                            //             var defaultDate = this.getDate(lowerDefaultValue[0].value, true)
                            //             this.setState({ [individualParameter.ParamName.concat('_OriginalDefault')]: defaultDate })
                            //             parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: defaultDate, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                            //             parameters.sort(this.compareParamOrder)
                            //             this.setState({
                            //                 [t]: defaultDate, [t.concat("_Validate")]: true
                            //             })


                            //         }
                            //         this.setState({ paramTrackingCount: this.state.paramTrackingCount - 1 })
                            //     })

                            //     parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: individualParameter.DefaultValues })
                            //     this.setState({
                            //      [t]: individualParameter.DefaultValues
                            //     })
                            // }
                        //     else {
                        //         parameters.push({ type: individualParameter.ParamDataType, name: individualParameter.ParamName, label: individualParameter.Prompt, defaultValue: adjustedDefaultValue || individualParameter.DefaultValues, sortOrder: individualParameter.SortOrder, allowBlank: individualParameter.AllowBlank })
                        //         parameters.sort(this.compareParamOrder)
                        //         if (individualParameter.DefaultValues !== null) {
                        //             this.setState({
                        //                 [t]: adjustedDefaultValue || individualParameter.DefaultValues, [t.concat("_Validate")]: true
                        //             })
                        //         }
                        //         else {
                        //             this.setState({
                        //                 [t]: '', [t.concat("_Validate")]: true
                        //             })
                        //         }
                        //     }
                        // }

                    }

                })
                //end loop through response

            })
            .catch(err => {
                console.error(err);
            })
        //return reports;

        this.setState({ parameterList: parameters, reportParametersFlag: true, runReportButtonFlag: true }, () => {
            //this.getParameterDefaults();
            //this.setState({ reportParametersFlag: true, runReportButtonFlag: true })
        });




    }

    parseDropdownOptions(dropdownOptionString){
        return JSON.parse(dropdownOptionString)
    }

    getParameterDefaults = () => {
        const headers = { 'authorization': 'Bearer '.concat(this.props.idToken) };
        var report = this.state.report.name;
        var parameterList = []
        parameterList = parameterList.concat(this.state.parameterList)
        axios(`api/ssrsGetParameterDefaults`, {
            method: 'GET',
            params: { report: report, includeGlobals: true, company: this.props.user.company },
            headers: headers
        })
            .then(response => {

                if (response.data.code === 200) {
                    parameterList.forEach(param => {
                        response.data.data.forEach(defaultParam => {
                            if (param.name === defaultParam.paramName) {
                                if (param.type === "Dropdown") {
                                    const newDefault = param.values.find(item => item.value === defaultParam.value)
                                    param.defaultValue = newDefault
                                    this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault })
                                }
                                else if (param.type === "DateTime") {
                                    if (defaultParam.value.length === 4) {//if generic date
                                        const newDefault = this.state["genericDates_" + defaultParam.paramName + "_Data"].find(item => item.value === defaultParam.value)
                                        param.defaultValue = newDefault
                                        this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault, [defaultParam.paramName + "GenericDateToggle"]: true })
                                    }
                                    else {
                                        const newDefault = this.getDate(defaultParam.value, true)
                                        param.defaultValue = newDefault
                                        this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: newDefault })
                                    }
                                }
                                else {
                                    param.defaultValue = defaultParam.value
                                    this.setState({ ["SSRSParam_".concat([defaultParam.paramName])]: defaultParam.value })
                                }
                                param.defaultSet = true
                            }
                        })
                    })
                    console.log(parameterList)

                    //testing begin
                    var paramTest = []
                    paramTest.push(parameterList[0])
                    paramTest.push(parameterList[1])
                    paramTest.push({
                        allowBlank: "False",
                        defaultValue: "False",
                        label: "Show Headers",
                        name: "showHeaders",
                        sortOrder: "4",
                        type: "Boolean"
                    })
                    //testing end
                    this.setState({ parameterList: paramTest, reportParametersFlag: true, runReportButtonFlag: true })
                }
                else {
                    alert("Error getting report defaults")
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    getSpacedLine(line, maxLength) {
        var newLine = line
        if (line.length > maxLength) {
            newLine = line.substring(0, maxLength - 3) + '...'
        }
        else if (line.length < maxLength) {
            for (var i = line.length; i < maxLength; i++) {
                newLine = newLine + " "
            }
        }
        return newLine
    }

    compareParamOrder(a, b) {
        if (parseInt(a.sortOrder) < parseInt(b.sortOrder)) {
            return -1;
        }
        if (parseInt(a.sortOrder) > parseInt(b.sortOrder)) {
            return 1;
        }
        return 0;
    }


    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }


    render() {
        const { pageNumber, numPages, parameterList } = this.state;
        console.log(parameterList)
        var textFieldWidth = '200px'

        return (
            <div>


                <div id="reportSelect" style={{ backgroundColor: "white", borderRadius: '3px' }}>
                    <br />
                    <Button onClick={() => this.props.goToDataExports(0)}>Back to Create Export</Button>
                    <br />
                    <div style={{ backgroundColor: "white", borderRadius: '3px' }}>
                        {this.state.reportParametersFlag && this.paramTrackingCount === 0 &&
                            <Grid fluid>
                                <Row>
                                    {parameterList.map(form => {
                                        const t = "SSRSParam_".concat([form.name])
                                        if (form.type === "String") {
                                            if (form.name === "ReportTitle") {
                                                textFieldWidth = '220px'
                                            }
                                            else {
                                                textFieldWidth = '200px'
                                            }
                                            return (
                                                <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                                                    <br></br>
                                                    <InputTextField
                                                        name={form.name}
                                                        input_type={form.type}
                                                        label={form.label}
                                                        key={form.label}
                                                        value={this.state[t]}
                                                        defaultValue={form.defaultValue}
                                                        textFieldWidth={textFieldWidth}
                                                        valid={this.state[t.concat("_Validate")]}
                                                        _handleChange={this._handleChange}
                                                    />
                                                </Col>
                                            )
                                        }
                                        if (form.type === "DateTime" && form.name === 'singleDate') {
                                            return (

                                                <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                                                    <br></br>
                                                    {!this.state.singleDateGenericDateToggle &&
                                                        <div>
                                                            <InputDate
                                                                name={form.name}
                                                                label={form.label}
                                                                key={form.label}
                                                                value={this.state[t]}
                                                                defaultValue={form.defaultValue}
                                                                valid={this.state[t.concat("_Validate")]}
                                                                _handleChange={this._handleChange}
                                                            />
                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'singleDateGenericDateToggle')} icon="list-unordered"></Button>
                                                        </div>
                                                    }
                                                    {this.state.singleDateGenericDateToggle &&
                                                        <div>
                                                            <InputDropdown
                                                                name={form.name}
                                                                label={form.label}
                                                                data={this.state.genericDates_singleDate_Data}
                                                                value={this.state[t]}
                                                                key={form.label}
                                                                valid={this.state[t.concat("_Validate")]}
                                                                _filterChange={this._filterChangeGenericDate}
                                                                _handleChange={this._handleChange}
                                                            />
                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'singleDateGenericDateToggle')} icon="calendar"></Button>
                                                        </div>
                                                    }
                                                    <br></br>
                                                </Col>

                                            )
                                        }
                                        if (form.type === "DateTime" && form.name === 'FromDate') {
                                            return (

                                                <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                                                    <br></br>
                                                    {!this.state.fromDateGenericDateToggle &&
                                                        <div>
                                                            <InputDate
                                                                name={form.name}
                                                                label={form.label}
                                                                key={form.label}
                                                                value={this.state[t]}
                                                                defaultValue={form.defaultValue}
                                                                valid={this.state[t.concat("_Validate")]}
                                                                _handleChange={this._handleChange}
                                                            />
                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'fromDateGenericDateToggle')} icon="list-unordered"></Button>
                                                        </div>
                                                    }
                                                    {this.state.fromDateGenericDateToggle &&
                                                        <div>
                                                            <InputDropdown
                                                                name={form.name}
                                                                label={form.label}
                                                                data={this.state.genericDates_FromDate_Data}
                                                                value={this.state[t]}
                                                                key={form.label}
                                                                valid={this.state[t.concat("_Validate")]}
                                                                _filterChange={this._filterChangeGenericDate}
                                                                _handleChange={this._handleChange}
                                                            />
                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'fromDateGenericDateToggle')} icon="calendar"></Button>
                                                        </div>
                                                    }
                                                    <br></br>
                                                </Col>

                                            )
                                        }
                                        if (form.type === "DateTime" && form.name === 'ToDate') {
                                            return (

                                                <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                                                    <br></br>
                                                    {!this.state.toDateGenericDateToggle &&
                                                        <div>
                                                            <InputDate
                                                                name={form.name}
                                                                label={form.label}
                                                                key={form.label}
                                                                value={this.state[t]}
                                                                defaultValue={form.defaultValue}
                                                                valid={this.state[t.concat("_Validate")]}
                                                                _handleChange={this._handleChange}
                                                            />
                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(true, 'toDateGenericDateToggle')} icon="list-unordered"></Button>
                                                        </div>
                                                    }
                                                    {this.state.toDateGenericDateToggle &&
                                                        <div>
                                                            <InputDropdown
                                                                name={form.name}
                                                                label={form.label}
                                                                data={this.state.genericDates_ToDate_Data}
                                                                value={this.state[t]}
                                                                key={form.label}
                                                                valid={this.state[t.concat("_Validate")]}
                                                                _filterChange={this._filterChangeGenericDate}
                                                                _handleChange={this._handleChange}
                                                            />
                                                            <Button onClick={(e) => this.onGenericToggleChangeHandler(false, 'toDateGenericDateToggle')} icon="calendar"></Button>
                                                        </div>
                                                    }
                                                    <br></br>
                                                </Col>

                                            )
                                        }
                                        if (form.type === "Boolean") {
                                            var defaultChecked = ''
                                            if (form.defaultValue.toLowerCase() === 'true') {
                                                defaultChecked = true
                                            }
                                            else if (form.defaultValue.toLowerCase() === 'false') {
                                                defaultChecked = false
                                            }
                                            return (

                                                <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                                                    <br></br>
                                                    <InputBoolean
                                                        name={form.name}
                                                        label={form.label}
                                                        key={form.label}
                                                        value={this.state[t]}
                                                        defaultChecked={defaultChecked}
                                                        valid={this.state[t.concat("_Validate")]}
                                                        _handleChange={this._handleChange}
                                                    />
                                                </Col>

                                            )
                                        }
                                        if (form.type === "Dropdown" && (form.name === "PortfolioTest" || form.name === "PortfolioList")) {
                                            return (

                                                <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                                                    <br></br>
                                                    <InputPortfolioDropdown
                                                        name={form.name}
                                                        label={form.label}
                                                        data={this.state[t.concat("_Data")]}
                                                        value={this.state[t]}
                                                        key={form.label}
                                                        valid={this.state[t.concat("_Validate")]}
                                                        _filterChange={this._filterChange}
                                                        _handleChangePortfolio={this._handleChangePortfolio}
                                                        _itemRender={this._itemRender}
                                                    />
                                                    <Button onClick={(e) => this.consolidateGroups()} >Consolidate</Button>
                                                </Col>

                                            )
                                        }
                                        else if (form.type === "Dropdown") {
                                            return (

                                                <Col xs={5} sm={4} md={3} lg={2} xl={2} >
                                                    <br></br>
                                                    <InputDropdown
                                                        name={form.name}
                                                        label={form.label}
                                                        data={this.state[t.concat("_Data")]}
                                                        value={this.state[t]}
                                                        key={form.label}
                                                        valid={this.state[t.concat("_Validate")]}
                                                        _filterChange={this._filterChange}
                                                        _handleChange={this._handleChange}
                                                    />
                                                </Col>

                                            )
                                        }
                                        return (<></>)
                                    })}
                                </Row>
                            </Grid>
                        }
                    </div>
                    <br></br>


                    <Button onClick={(e) => this.submitForm()} disabled={this.getButtonStatus()}>Run Export</Button>
                    <br></br><br></br>
                </div>


                {
                    this.state.loaderSpinnerFlag && <>
                        <LoopCircleLoading color={'#bababa'} />
                    </>
                }

                <p style={{ maxWidth: '600px' }}>{this.state.status}</p>

                {
                    this.state.pdfFlag && <div id="document" style={{ alignIitems: 'center', justifyContent: 'center' }}>
                        <Button onClick={() => this.changePdfPage(pageNumber, 'prev')}> Prev </Button>
                        | {pageNumber} of {numPages} |
                        <Button onClick={() => this.changePdfPage(pageNumber, 'next')}> Next </Button>
                        {' '}
                        <Button onClick={() => { if (this.state.pdfScale < 2.0) { this.setState({ pdfScale: this.state.pdfScale + 0.25 }) } }}> Zoom in + </Button>
                        <Button onClick={() => { if (this.state.pdfScale > 0.75) { this.setState({ pdfScale: this.state.pdfScale - 0.25 }) } }}> Zoom out - </Button>
                        {'   '}
                        <Button onClick={() => window.open(this.state.pdfFile)}>Download PDF</Button>
                        <Button onClick={(e) => this.submitForm('xlsx')} disabled={!this.state.generateExcelButtonFlag}>Download as Excel</Button>
                        <Button onClick={(e) => this.submitForm('csv')} disabled={!this.state.generateCsvButtonFlag}>Download as CSV</Button>
                        <div style={{ display: 'flex', alignIitems: 'center', justifyContent: 'center', borderStyle: 'solid', backgroundColor: "grey" }}><Document file={this.state.pdfFile} onLoadSuccess={this.onDocumentLoadSuccess}>
                            <Page pageNumber={this.state.pageNumber} scale={this.state.pdfScale} />
                        </Document>
                        </div>

                    </div>
                }
            </div >
        );
    }
}

export default Ssrs;