import React from "react";
import { DatePicker } from '@progress/kendo-react-dateinputs';

const InputDate = ({ name, label, defaultValue, valid, _handleChange }) =>

    (
        <div style={{paddingRight: '20px'}}>
            <label>{label}: </label>
            <br></br>
            <DatePicker
                defaultValue={new Date(defaultValue)}
                name={name}
                valid={valid}
                format={"MM/dd/yyyy"}
                onChange={_handleChange}
            />
            <br></br>
        </div>
    );
export default InputDate;